import { useContext, useEffect, useState } from "react";
import AppContext from "../contexts/AppContext";
import { xfdfApi, XfdfModel } from "../utils/api/xfdf";

type SaveAnnotationModel = Omit<
  XfdfModel,
  "uploadId" | "createdByUserId" | "id"
>;

export type AnnotationApi = {
  authorName: string;
  data: XfdfModel[];
  loading: boolean;
  index(): Promise<XfdfModel[]>;
  save(model: SaveAnnotationModel): Promise<void>;
};

/**
 * Provides api and manages state for XFDF annotations
 */
export function useAnnotationApi(uploadId: number, projectId?: number): AnnotationApi {
  const authorName = useAnnotationAuthorName();
  const [data, setData] = useState<XfdfModel[] | null>(null);
  const [loading, setLoading] = useState(true);
  const index = async () => {
    const nextData = await xfdfApi.index(uploadId, projectId);

    setData(nextData);

    return nextData;
  };

  const save = async (model: SaveAnnotationModel) => {
    await xfdfApi.save({ ...model, uploadId });
  };

  const api: AnnotationApi = {
    authorName,
    data: data || [],
    loading,
    index,
    save,
  };

  useEffect(() => {
    index();
    xfdfApi.initSocket((err: any) => {
      throw err;
    });

    return () => {
      xfdfApi.stopSocket();
    };
  }, []);

  useEffect(() => {
    if (loading && data) {
      setLoading(false);
    }
  }, [loading, data]);

  return api;
}

export function useAnnotationAuthorName() {
  const appStore = useContext(AppContext);

  const user = appStore.get("user");

  if (user && user.email) {
    return user.email;
  }

  return "Guest";
}
