import React, { useContext, Fragment } from "react";
import PropTypes, { string, arrayOf, shape, number, oneOf, bool } from "prop-types";
import classnames from "classnames";

import WeatherContext from "../../contexts/WeatherContext";
import { LoadingIcon } from "@sw-sw/lib-ui";
import { Tooltip } from "@mui/material";
import { ProjectContext } from "../../contexts/ProjectContext";

function WeatherIcon(props) {

  function getIconClass(shortForecast) {
    switch (true) {
      case shortForecast.includes("Windy") || shortForecast.includes("T-storms"):
        return "wi-strong-wind";

      case shortForecast.includes("Light Rain") && props.precipProbability !== null:
        return "wi-day-rain"

      case shortForecast.includes("Rain") && props.precipProbability !== null:
        return "wi-rain"

      case shortForecast.includes("Light Snow") && props.precipProbability !== null:
        return "wi-day-snow"

      case shortForecast.includes("Snow"):
        return "wi-snow"

      case shortForecast.includes("Sleet"):
        return "wi-sleet"

      case shortForecast.includes("Fog"):
        return "wi-fog";

      case shortForecast.includes("Cloudy"):
        return "wi-cloudy";

      case shortForecast.includes("Sunny"):
      default:
        return "wi-day-sunny";
    }
  }

  return (
    <Tooltip title={props.detailedForecast}>
      <i className={classnames("wi", getIconClass(props.shortForecast), "pad-right")} />
    </Tooltip>
  );
}

function DeniedWidget(props) {

  return (
    <section className={classnames("weather weather-denied", props.className)}>
      <WeatherIcon shortForecast={"Cloudy"} />
      <div>
        <Fragment>
          <p className="bold">No weather available</p>
          <p>There was an error fetching weather data</p>
        </Fragment>
      </div>
    </section>
  );
}

function WeatherForecastWidget(props) {
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

  return (
    <section className={classnames("weather", props.className)}>
      {!props.weather.length ? (
        <div className="loading-container">
          <LoadingIcon />
        </div>
      ) : (
        <Fragment>
          <section className="weather-forecast">
            {props.weather.map(weather => (
              <div key={weather.time} className="weather-day">
                <WeatherIcon shortForecast={weather.shortForecast} detailedForecast={weather.detailedForecast} precipProbability={weather.precipProbability} />
                <div>
                  <p className="date">
                    {`${weekday[new Date(weather.time).getDay()]} ${new Date(weather.time).getDate()}/${new Date(weather.time).getMonth() + 1}`}
                  </p>
                  <div className="weather-details-wrapper">
                    <div className="weather-details">
                      <p className="high">
                        {Math.round(weather.temperature)}&deg;  {weather.temperatureUnit}
                      </p>
                      <p className="label"></p>
                    </div>
                    <div className="weather-details">
                      <p>{Math.round(weather.precipProbability)}%</p>
                      <p className="label">Precip.</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </Fragment>
      )}
    </section>
  );
}

function WeatherWidget(props) {
  const weatherStore = useContext(props.isProject ? ProjectContext : WeatherContext);

  return weatherStore.location.denied || weatherStore.error || !weatherStore.weather.length ? (
    <DeniedWidget {...props} error={weatherStore.error} />
  ) : (
    <WeatherForecastWidget {...props} weather={weatherStore.weather} />
  );
}

const icons = [
  "clear-day",
  "rain",
  "snow",
  "sleet",
  "wind",
  "fog",
  "cloudy",
  "partly-cloudy-day",
];

WeatherIcon.propTypes = {
  icon: oneOf(icons),
};

const weatherProps = {
  className: string,
};

const weatherShape = shape({
  time: PropTypes.any.isRequired,
  temperature: number.isRequired,
  temperatureUnit: string.isRequired,
  precipProbability: number,
  shortForecast: string.isRequired,
  detailedForecast: string.isRequired,
});

WeatherForecastWidget.propTypes = {
  ...weatherProps,
  weather: arrayOf(weatherShape).isRequired,
};
DeniedWidget.propTypes = {
  ...weatherProps,
  error: bool.isRequired,
};
WeatherWidget.propTypes = {
  ...weatherProps,
};

export default WeatherWidget;
