import React, { Component } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import { forOwn, toNumber, times, debounce, camelCase } from "lodash";

import FeatureableEditorContext, { Context } from "../Context";
import Canvas from "../Canvas";
import Color from "../Fields/Color";
import Range from "../Fields/Range";
import StrokeDashArray from "./Fields/StrokeDashArray";
import ArrowType, { arrowTypes } from "./Fields/ArrowType";
import FeatureableEditorOptions from "../Options";
import StrokeType from "./Fields/StrokeType";
import StrokeStyle from "./Fields/StrokeStyle";
import StrokeWidth from "./Fields/StrokeWidth";
import { shapeUtil } from "@sw-sw/common";

const EDITOR_WIDTH = 300; // total width, including margins
const EDITOR_HEIGHT = EDITOR_WIDTH / 3;
const EDITOR_PADDING = 25;

const editorContentWidth = EDITOR_WIDTH - EDITOR_PADDING * 2;

/**
 *  Map of line object attribute to form field component
 */
export const lineOptions = {
  stroke: props => <Color label="Line Color" {...props} />,
  tickStroke: ({ form, ...props }) =>
    form.strokeStyle === "tick" ? (
      <Color label="Tick Color" {...props} />
    ) : null,
  opacity: props => (
    <Range
      label="Opacity"
      min="0"
      max="1"
      step="0.01"
      {...props}
      cast={toNumber}
    />
  ),
  strokeWidth: props => <StrokeWidth label="Thickness" {...props} />,
};

const options = {
  a: {
    ...lineOptions,
  },
  b: {
    strokeType: props => <StrokeType label="Line Type" {...props} />,
    strokeSeparation: ({ form, ...props }) =>
      form.strokeType > 1 ? (
        <Range
          label="Line Separation"
          step="1"
          min={form.strokeWidth}
          max={form.strokeWidth * 5}
          {...props}
          cast={toNumber}
        />
      ) : null,
    strokeStyle: props => <StrokeStyle label="Line Style" {...props} />,
    strokeDashArray: ({ form, ...props }) => {
      if (form.strokeStyle === "solid") {
        return null;
      }

      return (
        <StrokeDashArray
          strokeStyle={form.strokeStyle}
          strokeWidth={form.strokeWidth}
          {...props}
        />
      );
    },
    tickStrokeWidth: ({ form, ...props }) =>
      form.strokeStyle === "tick" ? (
        <Range
          label="Tick Height"
          step="2"
          min={form.strokeWidth * 2}
          max={form.strokeWidth * 10}
          {...props}
          cast={toNumber}
        />
      ) : null,
    arrowType: props => <ArrowType {...props} />,
    arrowScale: ({ form, ...props }) =>
      form.arrowType > 0 ? (
        <Range
          label="Arrow Scale"
          initialValue={1}
          step="0.05"
          min="1"
          max="1.75"
          {...props}
          cast={toNumber}
        />
      ) : null,
  },
};

const optionDefaults = {
  stroke: "#000000",
  strokeWidth: 5,
  strokeDashArray: [8, 10],
  strokeType: 1,
  strokeSeparation: 5,
  strokeStyle: "solid",
  tickStroke: "#F44E3B",
  tickStrokeWidth: 20,
  opacity: 1,
};

/**
 * Manage the preview canvas
 */
class Preview extends Component {
  static contextType = Context;

  htmlCanvas = React.createRef();
  /** @type {fabric.Canvas} */
  canvas = null;
  /** @type {fabric.Group} */
  group = null;
  /** @type {fabric.Group} */
  tickLines = null;
  /** @type {fabric.Path} */
  arrow = null;

  componentWillUnmount() {
    this.canvas.off("featureable-form:updated", this.updateLine);
  }

  init = () => {
    const form = this.context.form;

    this.canvas = this.context.initCanvas(this.htmlCanvas.current, [
      EDITOR_WIDTH,
      EDITOR_HEIGHT,
    ]);

    this.initGroup(form.strokeType);

    this.canvas.on("featureable-form:updated", this.updateLine);

    forOwn(form, (value, field) => {
      this.updateLine({ value, field });
    });

    this.initTickLines();
    this.canvasRender();
  };

  initGroup = numLines => {
    const form = this.context.form;
    const group = new fabric.Group(
      this.getLines(numLines, form.strokeWidth + form.strokeSeparation),
      {
        selectable: false,
        evented: false,
      },
    );

    this.canvas.getObjects().map(obj => this.canvas.remove(obj));

    this.canvas.add(group);

    group.center();

    this.group = group;

    return group;
  };

  // don't sync these line props with the arrow shape
  arrowExcludeProps = ["strokeWidth"];

  updateLine = ({ field, value }) => {

    switch (field) {
      case "arrowType":
        // always remove existing
        this.canvas.remove(this.arrow);
        this.arrow = null;

        if (arrowTypes[value] && arrowTypes[value].path) {
          // attach svg arrow
          /** @todo use cached method: {shapeUtil.getArrowStaticCanvas} */
          this.arrow = shapeUtil.getArrowShape(value, {
            originX: "center",
            stroke: this.group.getObjects()[0].stroke,
            fill: this.group.getObjects()[0].stroke,
            left:
              editorContentWidth +
              EDITOR_PADDING +
              (arrowTypes[value].offset || 0),
          });

          this.canvas.add(this.arrow);
          this.arrow.centerV();
        }

        break;
      case "arrowScale":
        if (this.arrow) {
          this.arrow.set("scaleY", value).set("scaleX", value).centerV();
        }

        break;
      case "strokeType":
        let groupSize = this.group.size();

        this.deInitTickLines();

        if (groupSize > value) {
          this.group
            .getObjects()
            .filter((_, $l) => $l >= value)
            .forEach(line => this.group.removeWithUpdate(line));

          this.group.center();
        } else {
          this.initGroup(value);

          forOwn(this.context.form, (formValue, formField) => {
            if ([field, "strokeSeparation"].indexOf(formField) === -1) {
              this.updateLine({
                value: formValue,
                field: formField,
              });
            }
          });
        }

        this.initTickLines();

        break;
      case "strokeSeparation":
        if (this.group.size() > 1) {
          this.updateLine({
            value: this.context.form.strokeType,
            field: "strokeType",
          });
        }

        break;
      case "strokeStyle":
        this.updateLine({
          field: "strokeLineCap",
          value: "butt",
        });

        if (value !== "tick") {
          this.deInitTickLines();
        }

        switch (value) {
          case "dash":
            this.updateLine({
              field: "strokeDashArray",
              value: this.context.form.strokeDashArray,
            });

            break;
          case "dot":
            this.updateLine({
              field: "strokeLineCap",
              value: "round",
            });

            break;
          case "tick":
            this.initTickLines();

            break;
          default:
            this.updateLine({
              field: "strokeDashArray",
              value: [0, 0],
            });
            break;
        }

        break;
      case "tickStroke":
      case "tickStrokeWidth":
        if (this.tickLines && this.context.form.strokeStyle === "tick") {
          const key = camelCase(field.replace("tick", ""));

          this.tickLines.getObjects().forEach(line => {
            line.set(key, value);
          });
        }

        break;
      default:
        if (this.tickLines) {
          /** @todo when "gap size" changes, use strokeDashOffset to center the ticks */

          if (field === "strokeDashArray") {
            this.tickLines.getObjects().forEach(line => {
              line.set(field, value);
            });

            return this.canvasRender();
          }
        }

        this.group.getObjects().forEach(line => line.set(field, value));

        if (this.arrow && !this.arrowExcludeProps.includes(field)) {
          this.arrow.set(field, value);

          if (field === "stroke") {
            this.arrow.set("fill", value);
          }
        }

        break;
    }

    this.canvasRender();
  };

  canvasRender = debounce(() => {
    this.canvas.renderAll();
  }, 80);

  getLine(yoffset = 0) {
    return new fabric.Line([0, 0, editorContentWidth, 0], {
      left: 0,
      top: yoffset,
      selectable: false,
      hoverCursor: "auto",
      originX: "center",
      originY: "center",
    });
  }

  getLines(num = 1, sep = 1) {
    return times(num, n => this.getLine(n * sep));
  }

  /**
   * Initialize "vertical tick" lines.
   *
   * @note Depends on {this.group} being set and styled
   */
  initTickLines() {
    if (!this.tickLines && this.context.form.strokeStyle === "tick") {
      this.group.clone((/** @type {fabric.Group} */ group) => {
        this.tickLines = group;

        this.tickLines.setOptions({
          selectable: false,
          evented: false,
        });

        group.getObjects().forEach(o => {
          o.set("stroke", this.context.form.tickStroke);
          o.set("strokeWidth", this.context.form.strokeWidth * 2.5);
          o.set("strokeDashArray", this.context.form.strokeDashArray);
        });

        this.canvas.add(group);

        // remove stroke dash from base line
        this.group
          .getObjects()
          .forEach(line => line.set("strokeDashArray", [0, 0]));

        this.canvasRender();
      });
    }
  }

  deInitTickLines() {
    if (this.tickLines) {
      this.canvas.remove(this.tickLines);
      this.tickLines = null;

      this.canvasRender();
    }
  }

  render() {
    return (
      <div className="line-editor-preview">
        <Canvas ref={this.htmlCanvas} onMounted={() => this.init()} />
      </div>
    );
  }
}

/**
 * Line Featureable Editor
 */
class LineEditor extends Component {
  static propTypes = {
    initialData: PropTypes.object,
  };

  render() {
    const formInitialData = {
      ...optionDefaults,
      ...(this.props.initialData || {}),
    };

    return (
      <FeatureableEditorContext
        editorName="line"
        formInitialData={formInitialData}
        dataUrlOpts={{
          left: EDITOR_WIDTH - EDITOR_HEIGHT,
          width: EDITOR_HEIGHT,
        }}
      >
        <Preview />
        <div className="pure-g pure-g-options">
          <div className="pure-u pure-u-1-2">
            <FeatureableEditorOptions options={options.a} />
          </div>
          <div className="pure-u pure-u-1-2">
            <FeatureableEditorOptions options={options.b} />
          </div>
        </div>
      </FeatureableEditorContext>
    );
  }
}

export default LineEditor;
