export function goToDirections(latitude, longitude) {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const startLat = position.coords.latitude;
      const startLong = position.coords.longitude;

      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLong}&destination=${latitude},${longitude}`;
      const popup = window.open(googleMapsUrl, '_blank');

      if (!popup) {
        alert('Pop-up blocked! Redirecting to Google Maps in the same tab.');
        window.location.href = googleMapsUrl; // Fallback to same tab if pop-up is blocked
      }
    },
    (err) => {
      alert('Please enable location services to use this feature');
    },
  );
}

export function showLocationOnMap(latitude, longitude) {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  const popup = window.open(googleMapsUrl, '_blank');

  if (!popup) {
    alert('Pop-up blocked! Redirecting to Google Maps in the same tab.');
    window.location.href = googleMapsUrl; // Fallback to same tab if pop-up is blocked
  }
}

export function dmsToDecimal([degrees, minutes, seconds], gpsRef) {
  if (gpsRef === 'N' || gpsRef === 'E') {
    return degrees + minutes / 60 + seconds / 3600;
  } else {
    return -(degrees + minutes / 60 + seconds / 3600);
  }
}
