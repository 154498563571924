import classnames from 'classnames';
import { capitalize } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ProjectContext,
  ProjectContextValue,
} from '../../../contexts/ProjectContext';
import { useHistory } from 'react-router-dom';
// import { isFindingsComplete, } from "../../../contexts/InspectionContext"
import { getInspectionTypeLabel } from '../../../utils';
import DataPlaceholder from '../../Shared/Loading/DataPlaceholder';
import CheckBox from './CheckBox';
import FindingCount from './FindingCount';
import SignatureDate from './SignatureDate';
import { findingsNotRequiredForCompliance } from '../../Projects/Details/Inspections';
import Pagination from '../../Shared/Pagination/Pagination';
import Loading from '../../Shared/ResourceIndex/Loading';

export interface IInspectionListingProps {
  checkedInspections: Array<number>;
  handleInspectionCheck: (id: number) => void;
  inspections: ProjectContextValue['inspections'];
  userCanSign: boolean;
  isPublic?: boolean;
  pathname?: string;
  setSelectedInspections: Function;
  setHasSelectedAllInspections: Function;
  pagination?: any;
  data?: any;
  setPageval?: Function;
  pageVal?: number;
}

const InspectionListing: React.FC<IInspectionListingProps> = ({
  checkedInspections,
  handleInspectionCheck,
  inspections,
  userCanSign,
  setSelectedInspections,
  setHasSelectedAllInspections,
  pagination,
  data,
  setPageval,
  pageVal,
  // pathname = null
}) => {
  const projectStore = useContext(ProjectContext);
  const { page, setPage, paginationState } = pagination;

  const history = useHistory();

  const isPublic = window.location.pathname.startsWith('/public');

  const submittedBy = (inspection: any) => {
    if (inspection.signatures && inspection.signatures.length) {
      const inspectionCert = inspection.signatures.filter((ele: any) => {
        if (ele && ele.type) {
          return ele.type.includes('inspection');
        }

        return false;
      });

      if (!inspectionCert || !inspectionCert.length) {
        return ``;
      }

      return `Submitted by: ${inspectionCert[0].signee_name}`;
    }

    return ``;
  };

  useEffect(() => {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', String(page));
      setHasSelectedAllInspections(false);
      setSelectedInspections([]);
      history.push({ search: params.toString() });
    } else {
      params.delete('page');
    }
  }, [page, history]);

  useEffect(() => {
    projectStore.setInspections(data);
  }, [data, projectStore]);

  const options = ['10', '25', '50'];
  const onChange = async (event: any) => {
    const newVal = event.target.value;

    if (setPageval) {
      setPageval(newVal);
    }
  };

  return (
    <>
      <div className='inspection-listing flex-row'>
        {projectStore.inspectionsQuery.isFetched && inspections.length >= 0 ? (
          inspections.map((inspection) => {
            const {
              status,
              id,
              certification_date,
              compliance_date,
              findingCount,
              inspection_template,
              acknowledgement_date,
              findings,
              findingDetails
            } = inspection;

            return (
              <div className='inspection flex-item' key={id}>
                {userCanSign &&
                  projectStore.project &&
                  projectStore.project.inspection_compliance_required === true &&
                  certification_date &&
                  inspection_template &&
                  inspection_template.has_compliance_cert &&
                  !compliance_date &&
                  findingsNotRequiredForCompliance(inspection_template, findings, findingDetails) &&
                  status === 'complete' ? (
                  <CheckBox
                    handleClick={() => handleInspectionCheck(id)}
                    isChecked={checkedInspections.includes(id)}
                  />
                ) : null}

                <Link
                  className='inspection-details'
                  to={
                    isPublic
                      ? `/public/inspection/${id}/questionnaire`
                      : `/inspection/${id}/questionnaire`
                  }
                >
                  <h2>
                    {inspection.type ? (
                      <div className='inspection-details__header'>
                        {`#${inspection.inspectionNumber
                          } | ${getInspectionTypeLabel(inspection)}`}
                        <h4>{`${submittedBy(inspection)}`}</h4>
                      </div>
                    ) : (
                      <DataPlaceholder width={10} />
                    )}
                  </h2>

                  <p className='questions'>
                    Inspection Questions |{' '}
                    <span
                      className={classnames(
                        status === 'complete' ? 'green-light' : 'red-light',
                      )}
                    >
                      {status ? capitalize(status) : <DataPlaceholder />}
                    </span>
                  </p>

                  <SignatureDate date={certification_date}>
                    Certification
                  </SignatureDate>

                  <SignatureDate
                    date={acknowledgement_date}
                    disabled={
                      acknowledgement_date
                        ? false
                        : inspection_template &&
                          inspection_template.has_acknowledgement
                          ? !inspection_template.has_acknowledgement
                          : true
                    }
                  >
                    Acknowledgement
                  </SignatureDate>

                  {inspection &&
                    inspection.inspection_template &&
                    inspection.inspection_template.has_compliance_cert && (
                      <SignatureDate
                        date={compliance_date}
                        disabled={
                          projectStore.project !== null &&
                          !projectStore.project.inspection_compliance_required
                        }
                      >
                        Compliance
                      </SignatureDate>
                    )}

                  <div className='actions'>
                    <FindingCount
                      count={findingCount.total.ca}
                      isComplete={
                        findingCount.total.ca === findingCount.complete.ca
                      }
                      isCA
                    >
                      Corrective Actions
                    </FindingCount>

                    <FindingCount
                      count={findingCount.total.mi}
                      isComplete={
                        findingCount.total.mi === findingCount.complete.mi
                      }
                    >
                      Maintenance Items
                    </FindingCount>
                  </div>
                </Link>
              </div>
            );
          }))
          : <Loading what={'inspections'} />
        }
      </div>
      <div className='inspection-pagesize'>
        <Pagination
          page={page || 1}
          setPage={setPage}
          paginationState={paginationState}
        />
        {data.length ? <select
          value={pageVal}
          onChange={onChange}
          className='pagesize-item'
        >
          {options.map((val, index) => {
            return (
              <option key={index} value={val}>
                {val}
              </option>
            );
          })}
        </select> : null}
      </div>
    </>
  );
};

export default InspectionListing;
