import React, { useContext, useState } from 'react';
import { useProjectLog } from '../../../hooks/projectDetails';
import Loading from '../../Shared/ResourceIndex/Loading';
import ChangeLog from '../Details/ChangeLog';
import PrecipitationLog from './PrecipitationLog/PrecipitationLog';
import Select from 'react-select';
import { FormContextProvider } from '@sw-sw/lib-form';
import DocumentsLog from './DocumentsLog/DocumentsLog';
import { RolesContext } from '../../../contexts/RolesContext';
import TenantStore from '../../../contexts/TenantContext';
import AppContext from '../../../contexts/AppContext';
import FindingsReport from './FindingsReport/FindingsReport';
import CalendarReport from './CalendarReport/CalendarReport';
import { useHistory } from 'react-router-dom';

export interface IProjectLogProps {
  projectId: number;
  isEVP: boolean;
}

const ProjectLog: React.FC<IProjectLogProps> = ({ projectId, isEVP }) => {
  const projectLogQuery = useProjectLog(projectId);
  const [logType, setLogType] = useState('Site Map Log');
  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanAccessDocumentsLog = permCheck('all', 'Documents Log');
  const userCanAccessPrecipitationLog = permCheck('all', 'Precipitation Log');
  const userCanAccessFindingsReport = permCheck('all', 'Findings Report');
  const canTenantEdit = useContext(TenantStore);
  const isEnabledForTenant = canTenantEdit.tenantHasFeature(
    'Project Documents Log',
  );
  const appContext = useContext(AppContext);
  const isSuperAdmin = appContext.get('user').roleName === 'Super Admin';
  const history = useHistory();
  const isPublic = history.location.pathname.startsWith("/public")

  /*
   * "Site Map Log" was formarly known as "Project Log"
   * within code, along with "Site Map Log", also look for the words "Project Log"
   */
  const alwaysAvailableOptions = [
    { value: 1, label: 'Site Map Log' },
    {
      value: 5,
      label: 'Calendar Report',
    },
  ];

  let logTabOptions = [
    ...alwaysAvailableOptions,
    ...(userCanAccessPrecipitationLog || isSuperAdmin
      ? [{ value: 2, label: 'Precipitation Log' }]
      : []),
    ...(userCanAccessFindingsReport || isSuperAdmin
      ? [{ value: 3, label: 'Findings Report' }]
      : []),
    ...((userCanAccessDocumentsLog && isEnabledForTenant) || isSuperAdmin
      ? [{ value: 4, label: 'Documents Log' }]
      : []),
  ];

  if(isPublic && isEVP){
    logTabOptions = logTabOptions.filter(e=> e.label === 'Site Map Log');
  }

  const onChangeFunc = (data: any) => {
    setLogType(data.label);
  };

  if (!projectLogQuery.data) {
    return <Loading what='Site Map Log' />;
  }

  const displayedLogType = () => {
    switch (logType) {
      case 'Site Map Log':
        return (
          <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
        );

      case 'Precipitation Log':
        return (
          <FormContextProvider>
            <PrecipitationLog />
          </FormContextProvider>
        );

      case 'Documents Log':
        return (
          <FormContextProvider>
            <DocumentsLog />
          </FormContextProvider>
        );

      case 'Findings Report':
        return (
          <FormContextProvider>
            <FindingsReport />
          </FormContextProvider>
        );

      case 'Calendar Report':
        return (
          <FormContextProvider>
            <CalendarReport />
          </FormContextProvider>
        );

      default:
        return (
          <ChangeLog changelogs={projectLogQuery.data} projectId={projectId} />
        );
    }
  };

  return (
    <section className='project-log'>
      <div className='project-log__select-wrapper'>
        <Select
          closeMenuOnSelect={true}
          defaultValue={logTabOptions[0]}
          options={logTabOptions}
          onChange={(data) => {
            onChangeFunc(data);
          }}
          isClearable={false}
          className='project-log__select'
          classNamePrefix='project-log'
          isSearchable={false}
        />
      </div>
      <div>{displayedLogType()}</div>
    </section>
  );
};

export default ProjectLog;
