import {
  CertFormSchema,
  CertificationIndexResponse,
  CertSchemaField,
  fieldKeyCodec,
  getCertFormDateData,
  getCertFormDateSchema,
  getCertFormUserData,
  getCertFormUserSchema,
  SignatureGroupType,
} from "@sw-sw/lib-certification";
import {
  FormContext,
  FormContextProvider,
  FormContextValue,
  FormSchemaFields,
} from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { map as bluebirdMap } from "bluebird";
import { find, get } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import AppContext from "../../../contexts/AppContext";
import DashboardContext from "../../../contexts/DashboardContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { getInspectionTypeLabel } from "../../../utils";
import {
  BulkCertifyParam,
  certificationApi,
} from "../../../utils/api/certification";
import ComplianceCertificationInstructions from "../../Inspections/Certification/ComplianceCertificationInstructions";
import InspectionCertificationStatement from "../../Inspections/Certification/InspectionCertificationStatement";
import SignatureCheckbox from "../../Inspections/Certification/SignatureCheckbox";
import FormModal from "../../Shared/form/modal/FormModal";
import LoadingModal from "../../Shared/Loading/LoadingModal";
import { InspectionWithFindingCount } from "../../../hooks/customDashboard";
import findingTypesApi from "../../../utils/api/findingTypes";

/** prepare schema data for api request */
function extractSchemaData(
  formData: Record<string, any>,
  state: { id: number; certState: CertificationIndexResponse }[],
): BulkCertifyParam {
  return state.reduce<BulkCertifyParam>((data, { id, certState }) => {
    certState.signatureGroups[SignatureGroupType.compliance].forEach(
      ({ type: lineType }) => {
        const key = fieldKeyCodec.encode(lineType, CertSchemaField.date, id);
        const date = get(formData, key, null);

        if (date) {
          data.push({ id, date, lineType });
        }
      },
    );

    return data;
  }, []);
}

function getLabelSchema(label: string, isHeading?: boolean) {
  return {
    label,
    controlType: UIControlType.plainText,
    className: isHeading ? "modal-h3" : undefined,
  };
}

/**
 * Certify multiple inspections.
 */
const BulkCertifyModal = ({
  handleSaveAnimation,
  hideModal,
  inspections,
  onSubmit,
  fromWidget
}: {
  handleSaveAnimation: () => void;
  hideModal: () => void;
  inspections: (Omit<InspectionWithFindingCount, "id"> & { id: number })[];
  updateInspections?: () => void;
  onSubmit: () => void;
  fromWidget?: boolean;
}) => {
  const appContext = useContext(AppContext);
  const user = appContext.get("user");
  const projectStore = useContext(ProjectContext);
  const { inspectionsCertDue, fetchStatsFuncForID } = useContext(DashboardContext);
  const [error, setError] = useState(false)
  const [inspectionName, setInspectionName] = useState<any[]>([])
  const inspectionIds = inspections.map(_ => _.id);
  const [selectedLabel, setSelectedLabel] = useState<string>()
  const [allLabels, setAllLabels] = useState<string[]>([])
  const query = useQuery({
    queryKey: ["cert", ...inspectionIds],
    queryFn: () =>
      bluebirdMap(
        inspectionIds,
        async id => {
          return {
            id,
            certState: await certificationApi.index(id),
          };
        },
        { concurrency: 4 },
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const findingTypesWithTemplateQuery: any = useQuery({
    queryKey: ["findingTypesWithTemplate"],
    queryFn: () => findingTypesApi.fetchAll(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const [checked, setChecked] = useState(false);



  const handleSubmit = useCallback(
    async (formData, formContext: FormContextValue) => {
      let updatedData = query.data

      if (!checked) {
        formContext.setError(
          "Please complete the Electronic Authorization Signature",
        );

        return;
      }

      if (selectedLabel && updatedData) {
        updatedData = updatedData.map((element) => {
          element.certState.signatureGroups.compliance = element.certState.signatureGroups.compliance.filter((ele: any) => {
            if (selectedLabel === "ALL Signatures") {
              return true
            }

            if (selectedLabel === "NO Label") {
              return (ele.label === null || ele.label === "")
            }

            return ele.label === selectedLabel
          })

          return element
        })
      }

      return certificationApi
        .bulkCertify(extractSchemaData(formData, updatedData || []))
        .then(() => {
          onSubmit();
          inspectionsCertDue.refetch()
          fetchStatsFuncForID.refetch()
          hideModal();
          handleSaveAnimation();
        });
    },
    [checked, query.data, projectStore],
  );

  const schema = useMemo(() => {
    const sch: CertFormSchema = {};

    if (query.data && query.data.length) {
      const firstGroups = query.data[0].certState.signatureGroups;
      const firstLine =
        firstGroups.compliance.length > 0
          ? firstGroups.compliance[0]
          : firstGroups.inspection.length > 0
            ? firstGroups.inspection[0]
            : undefined;

      if (firstLine !== undefined) {
        // name and position fields (first inspection & signature line only)
        Object.assign(
          sch,
          getCertFormUserSchema({
            encodeKey: (type, field) =>
              fieldKeyCodec.encode(type, field, inspectionIds[0]),
            line: firstLine,
          }),
        );
      }

      // date field, each inspection, each line
      query.data.forEach(({ certState, id }) => {
        const insp = find(inspections, { id });

        if (!certState.groupOptions[
          SignatureGroupType.compliance
        ].enabled) {
          setError(true)
          const abc: any[] = inspectionName

          insp && abc.push(`${insp.created_date} | ${insp.type}`)
          setInspectionName(abc)
        }

        let lines = certState.signatureGroups.compliance.filter(line => {
          return (
            find(certState.userSignatures.compliance, {
              lineType: line.type,
            }) === undefined
          );
        });

        //adding all labels in the state for filtering based on Label
        lines.forEach(ele => {
          let displayLabel = ele.label

          if (ele.label === null || ele.label === "") {
            displayLabel = "NO Label"
          }

          setAllLabels(prevState => {
            if (prevState.includes(`${displayLabel}`)) {
              return [...prevState]
            }
            else {
              return [...prevState, `${displayLabel}`]
            }
          })
        })

        if (selectedLabel) {
          lines = lines.filter(ele => {
            if (selectedLabel === "ALL Signatures") {
              return true
            }

            if (selectedLabel === "NO Label") {
              return (ele.label === null || ele.label === "")
            }

            return ele.label === selectedLabel
          })
        }

        if (insp && certState.groupOptions.compliance.enabled && lines.length) {
          Object.assign(sch, {
            [`${id}-label`]: getLabelSchema(getInspectionTypeLabel(insp, fromWidget), true),
          });

          lines.forEach(line => {
            const dateSchema = getCertFormDateSchema({
              enabled: true,
              line,
              minDate: new Date(certState.minCertificationDate),
              encodeKey: (type, field) => fieldKeyCodec.encode(type, field, id),
              label: line.label ? `${line.label} Date` : "Signature Date",
            });
            const [dateFieldKey] = Object.keys(dateSchema);

            Object.assign(dateSchema[dateFieldKey].style, {
              // flex: "0 1 20rem",
              flex: "0 1 100%",
              // minWidth: "20rem",
              // display: "flex",
              // flexFlow: "row nowrap",
            });

            Object.assign(dateSchema[dateFieldKey], {
              isClearable: true,
            });

            Object.assign(sch, dateSchema);
          });
        }
      });
    }

    return sch;
  }, [query.data, selectedLabel]);

  const isFindingRequired = (certState: any) => {
    if (findingTypesWithTemplateQuery.data && certState.attestations && certState.attestations.compliance.length) {
      const filteredData = findingTypesWithTemplateQuery.data.filter((ele: any) => ele.inspection_template_id === certState.attestations.compliance[0].templateId)
      const isTrue = filteredData.filter((ele: any) => ele.is_required_for_cert === true)

      if (isTrue.length)
        return true

      return false
    }

    return false
  }


  const findingsRequiredForCompliance = (template: any, findings: any, findingDetails: any) => {

    const requiredFindings = findings.filter((ele: any) => {
      if (ele.date_completed) {
        return (
          template &&
          findingDetails &&
          findingDetails.find((details: any) => {
            if (details.finding_type_id === ele.finding_type_id) {
              
              return details.is_required_for_cert === true;
            }

            return false;
          })
        );
      }
    });

    return requiredFindings;
  };

  const latestFindingClosureDate = (id: number) => {
    const inspection: any = inspections.filter(
      (insp: any) => insp.id === id,
    );

    if (!inspection.length) return null;

    const created_date = inspection[0].created_date || new Date();

    const doi = new Date(created_date);
    const doiWithTz = new Date(doi.getTime() + doi.getTimezoneOffset() * 60000);

    // when there is no findings, "return NULL" to skip the finding closure date logic and jump straight to compliance Default logic.
    // which comes from template, which returns either DOI or Current date, whichever is chosen from the dropdown.
    if (!inspection[0].findings.length) return null;

    const requiredFindings = findingsRequiredForCompliance(
      inspections[0].inspection_template,
      inspection[0].findings,
      inspection[0].findingDetails
    );

    const dates = requiredFindings.map((ele: any) => new Date(ele.date_completed))
      .map((ele: any) => (new Date(ele.getUTCFullYear(), ele.getUTCMonth(), ele.getUTCDate(), ele.getUTCHours(), ele.getUTCMinutes(), ele.getUTCSeconds())))

    // the above no findings logic is also applicable for the case, when you have no completed findings.
    if (!dates.length) return null;

    if (dates.length === 1) {
      return dates[0] > doiWithTz ? dates[0] : doiWithTz;
    }

    if (dates.length >= 2) {
      let maxdate = dates.sort(function (d1: any, d2: any) {
        return d2 - d1;
      })[0];

      return maxdate > doiWithTz ? maxdate : doiWithTz;
    } else return doiWithTz;
  };

  const complianceDefaultDate = (certState: any, id: number) => {
    const doi = new Date(certState.inspectionDOI)
    const doiWithTz = new Date(doi.getTime() + doi.getTimezoneOffset() * 60000)

    if (certState.signatureGroups && certState.signatureGroups.compliance.length) {
      if (certState.signatureGroups.compliance[0].defaultDateLogic === 'doi')
        return (isFindingRequired(certState) && latestFindingClosureDate(id)) ? latestFindingClosureDate(id) : doiWithTz
      else
        return new Date()
    }
    else
      return new Date()
  }

  const initialValue = useMemo(() => {
    const data = {};

    if (query.data) {
      query.data.forEach(({ certState, id }) => {
        // const insp = find(inspections, { id });

        if (
          certState.groupOptions.compliance.enabled &&
          certState.signatureGroups.compliance.length
        ) {
          const lines = certState.signatureGroups.compliance.filter(line => {
            return (
              find(certState.userSignatures.compliance, {
                lineType: line.type,
              }) === undefined
            );
          });

          lines.forEach(line => {
            Object.assign(
              data,
              getCertFormDateData({
                encodeKey: (type: string, field: CertSchemaField) =>
                  fieldKeyCodec.encode(type, field, id),
                lineType: line.type,
                value: complianceDefaultDate(certState, id),
              }),
              getCertFormUserData({
                encodeKey: (type: string, field: CertSchemaField) =>
                  fieldKeyCodec.encode(type, field, id),
                lineType: line.type,
                value: {
                  name: user.name,
                  role: user.position || user.roleName,
                },
              }),
            );
          });
        }
      });
    }

    return data;
  }, [query.data]);

  useEffect(
    () => () => {
      query.remove();
    },
    [],
  );

  if (!query.data || query.isLoading) {
    return <LoadingModal show />;
  }

  return (
    <FormContextProvider initialValue={initialValue}>
      <FormContext.Consumer>
        {formContext => (
          <FormModal
            modalProps={{
              title: "Certify Inspections",
              subTitle: (!fromWidget) ? `Compliance Certification for ${(projectStore as any).projectName
                }` : "",
              submitBtnText: "Certify",
              isExtraWide: true,
            }}
            onCancel={hideModal}
            onSubmit={formData => handleSubmit(formData, formContext)}
          >
            <section className="bulk-cert-modal">
              <div className="bulk-cert-modal__filter-signature">
                <span>Filter Signature :</span>
                <select
                  name="label-type"
                  onChange={(event) => {
                    setSelectedLabel(event.target.value)
                  }}
                >
                  <option value="-10" hidden>Select Label</option>
                  <option value="ALL Signatures">ALL Signatures</option>
                  {
                    allLabels.length ? allLabels.map((ele, index) => {
                      return (
                        <option value={ele} key={index}>{ele}</option>
                      )
                    }) :
                      <option value="-10" disabled>No Labels Found</option>
                  }
                </select>
              </div>

              <div className="bulk-cert-modal__form-schema-fields">
                <FormSchemaFields
                  schema={schema}
                  onChange={formContext.set}
                  formData={formContext.value}
                />
              </div>

              {error ?
                <p className="error-text">
                  {
                    inspectionName.map(insp => (
                      <div>
                        <strong>{insp}</strong>
                      </div>
                    ))
                  }
                  ** The following inspection cannot be certified because it has pending inspection certificate
                </p>
                :
                null
              }
              <ComplianceCertificationInstructions />
              <InspectionCertificationStatement
                attestations={
                  query.data[0].certState.attestations[
                  SignatureGroupType.compliance
                  ]
                }
              />
              <SignatureCheckbox
                enabled={
                  query.data[0].certState.groupOptions[
                    SignatureGroupType.compliance
                  ].enabled
                }
                errors={
                  query.data[0].certState.groupOptions[
                    SignatureGroupType.compliance
                  ].errors
                }
                checked={checked}
                // readOnly={checked}
                // onSubmit={() =>
                //   // formContext.getTransformedData().then((data: any) =>
                //   //   certContext.onSave({
                //   //     group: type,
                //   //     lineType: line.type,
                //   //     formData: data,
                //   //   }),
                //   // )
                // }
                onSubmit={async () => {
                  formContext.setError(null);
                  setChecked(!checked);
                }}
              />
            </section>
          </FormModal>
        )}
      </FormContext.Consumer>
    </FormContextProvider>
  );
};

BulkCertifyModal.propTypes = {
  handleSaveAnimation: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  /** List of selected inspections. Each with a minimum certification date */
  inspections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      minCertificationDate: PropTypes.string,
      // created_date: PropTypes.string.isRequired,
      // and others...
    }),
  ).isRequired,
  updateInspections: PropTypes.func,
};

export default BulkCertifyModal;
