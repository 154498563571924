import React from "react";
import ReactDOM from "react-dom";

// styles
import "../node_modules/purecss/build/pure-min.css";
import "../node_modules/purecss/build/grids-responsive-min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/weather-icons/css/weather-icons.min.css";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-image-lightbox/style.css";
import "./index.scss";
import "ol/ol.css";
import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "react-mde/lib/styles/css/react-mde-editor.css";
import "react-mde/lib/styles/css/react-mde.css";
import "react-mde/lib/styles/css/react-mde-suggestions.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-toastify/dist/ReactToastify.css";
import { env } from "./config";
// import { datadogRum } from '@datadog/browser-rum';
// components
import App from "./App";

// fortawesome icons
import "./icons";

ReactDOM.render(<App />, document.getElementById("root"));
