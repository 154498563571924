import React, { useState, useContext, useEffect } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  startOfMonth,
  addDays,
  getDaysInMonth,
  isEqual
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faEye, faClipboardList, faCloudShowersHeavy } from "@fortawesome/free-solid-svg-icons";
import DashboardContext from "../../../../contexts/DashboardContext";
import { AppDivisionContext } from "../../../../contexts/AppDivisionContext";
import { Link } from "react-router-dom";
import { Popover, popoverClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { customDashboard } from "../../../../utils/api/dashboard";



const MonthViewLayout = (props) => {
  const { getPath } = useContext(AppDivisionContext);
  const { setDisplayStatsForCal, setIsLoadingForCal, setPrecipitationDataForCal, selectedCalProject } = useContext(DashboardContext)
  const [currentMonth, setCurrentMonth] = useState(new Date());

  let monthTest

  useEffect(() => {
    props.setCurrentCalMonth(format(currentMonth, 'yyyy-MM'));
  }, [currentMonth]);

  const callApiOnMonthChange = () => {
    setIsLoadingForCal(true)
    Promise.all([
      customDashboard.fetchWithParams(getPath('/calendar/getCalendarData'), { "type": selectedCalProject }),
      customDashboard.fetchWithParams(getPath('/calendar/fetchPrecipitationForCal'), { "type": selectedCalProject, "month": monthTest })
    ])
    .then(([calendarResponse, PrecipitationResponse]) => {
      setDisplayStatsForCal(calendarResponse.inspections)
      setPrecipitationDataForCal(PrecipitationResponse)
      setIsLoadingForCal(false)
    })
    .catch((error) => console.log(error))
  }

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
      monthTest = format(subMonths(currentMonth, 1), "yyyy-MM")
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
      monthTest = format(addMonths(currentMonth, 1), "yyyy-MM")
    }
  };

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    
    return (
      <div className="header row flex-middle">

        <div className="col col-end flex-gap renderHeader__left">
          <div className="icon" onClick={(event) => {
            changeMonthHandle("prev")
            selectedCalProject && callApiOnMonthChange()
          }}>
            <FontAwesomeIcon icon={ faAngleLeft }/>
          </div>
        </div>

        <div className="col col-center renderHeader__center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>


        <div className="col col-start flex-gap renderHeader__right">
          <div className="icon" onClick={(event) => {
            changeMonthHandle("next")
            selectedCalProject && callApiOnMonthChange()
          }}>
            <FontAwesomeIcon icon={ faAngleRight }/>
          </div>
        </div>

      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];

    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    
    return <div className="days row">{days}</div>;
  };


  const renderCells = () => {
    let firstDayOfCurrentMonth = format(startOfMonth(currentMonth), 'i') //7

    let numberOfDaysInCurrentMonth = getDaysInMonth(currentMonth) //31

    let blankCells = []

    let datesOfCurrentMonth = []

    for(let i=1; i<firstDayOfCurrentMonth; i++){
        blankCells.push('')
    }

    for(let i=1; i<=numberOfDaysInCurrentMonth; i++){
        datesOfCurrentMonth.push(i)
    }


    return (
    <>
      <div className="render-cells">
          {blankCells && blankCells.map((blank, index) => {
            return (
                <span key={index} className="render-cell">
                  <span>{blank}</span>
                </span>
            )
          })}
          {datesOfCurrentMonth.map((date, index) => {
            return (
                <CalendarCell key={index} date={date} currentMonth={currentMonth}/>
            )
          })}
      </div>
    </>
    )
  };


  return (
    <div className="month-view-layout">
      <div className="calendar">
        {renderHeader()}
        <div className="calendar__body">
          {renderDays()}
          {renderCells()}
        </div>
      </div>
    </div>
  );
};






const CalendarCell = (props) => {
  const { displayStatsForCal, precipitationDataForCal, isLoadingForCal } = useContext(DashboardContext)
  const { appDivisionId } = useContext(AppDivisionContext);
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElX, setAnchorElX] = useState(false)

  // Mui Custom CSS for inpection detail popover
  const StyledPopover = styled(Popover)(({ theme }) => ({
    [`&.${popoverClasses.root} .render-cells-popover`]: {
      // width: '260px',
      padding: '8px',
      color: '#6D747E'
    },
    [`&.${popoverClasses.root} li`]: {
      listStyle: 'none',
      padding: '0.25rem 0',
      lineHeight: 1
    },
    [`&.${popoverClasses.root} li:nth-child(1)`]: {
      fontWeight: 900,
      marginBottom: '0.5rem'
    },
    [`&.${popoverClasses.root} li:nth-child(1), li:nth-child(3)`]: {
      whitespace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '25ch',
      '-webkitBoxOrient': 'vertical',
    },
    [`&.${popoverClasses.root} li:nth-child(2) span`]: {
      color: 'rgb(168, 168, 168)'
    },
    [`&.${popoverClasses.root} li p`]: {
      display: 'inline'
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta`]: {
      border: '1px solid #afafaf',
      width: 'fit-content',
      marginTop: '5px',
      padding: '3px',
      borderRadius: '4px',
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta:hover`]: {
      background: 'rgb(228, 228, 228)'
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta a`]: {
      textDecoration: "none"
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta a span`]: {
      borderRadius: '4px',
      padding: '3px',
      color: '#6D747E',
      fontWeight: 900
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseX = () => {
    setAnchorElX(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openX = Boolean(anchorElX);
  const idX = open ? 'simple-popover' : undefined;

  const { date, currentMonth } = props

  const regex = new RegExp(`${format(currentMonth, 'M')}-.*-${format(currentMonth, 'y')}`)

  const inspectionsDueCurrentMonth = displayStatsForCal.filter((ele) => regex.test(ele.inspectionCertDate))

  let cellDate = new Date(`${format(startOfMonth(currentMonth), 'M')}/${date}/${format(startOfMonth(currentMonth), 'yyyy')}`)

  let filteredInspection = inspectionsDueCurrentMonth.filter((ele) => ele.inspectionCertDate && isEqual(new Date(ele.inspectionCertDate.replace(/-/g,'/')), cellDate))

  let nextInspectionDate = displayStatsForCal.length && displayStatsForCal[0].projectNextInspectionDate && new Date(displayStatsForCal[0].projectNextInspectionDate.replace(/-/g,'/'))

  let furtherNextInspectionDate = displayStatsForCal.length && displayStatsForCal[0].projectNextInspectionDate && new Date(new Date(displayStatsForCal[0].projectNextInspectionDate.replace(/-/g,'/')).getTime() + displayStatsForCal[0].projectRoutineInterval * 86400000)

  let filteredPrecipitationData

  if (precipitationDataForCal.hasOwnProperty("weather_accumulation")) {
    if (precipitationDataForCal.weather_accumulation.hasOwnProperty(cellDate.toISOString().split('T')[0])) {
      const isRainAboveThreshold = precipitationDataForCal.weather_accumulation[cellDate.toISOString().split('T')[0]].rain_accumulation >= precipitationDataForCal.precipitation_threshold
      const isSnowAboveThreshold = precipitationDataForCal.weather_accumulation[cellDate.toISOString().split('T')[0]].snow_accumulation >= precipitationDataForCal.snow_threshold

      filteredPrecipitationData = (isRainAboveThreshold || isSnowAboveThreshold) && precipitationDataForCal.weather_accumulation[cellDate.toISOString().split('T')[0]]
    }
  }

  const onInspectionIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const onPrecipitationIconClick = (event) => {
    setAnchorElX(event.currentTarget);
  }

  return (
    <>
      <span className="render-cell" style={{background: (isEqual(cellDate, nextInspectionDate) || isEqual(cellDate , furtherNextInspectionDate)) ? "#efefef" : "white" }} id={date}>
        { isLoadingForCal ? <>...</> :
        (<>
        {filteredInspection.length  ? <FontAwesomeIcon onClick={(event) => onInspectionIconClick(event)} icon={ faClipboardList } className="render-cell__list-icon" style={{ position: "absolute", top: 5, left: 5, color: "#4ADE86" }} /> : <></>}
        <span>{date}</span>
        { filteredPrecipitationData ? <FontAwesomeIcon onClick={(event) => onPrecipitationIconClick(event)} icon={ faCloudShowersHeavy } className="render-cell__cloud-icon" style={{ position: "absolute", top: 5, right: 5, color: "rgb(42, 212, 218)" }} /> : <></> }
        </>)
        }
        
        <div>
          {
            filteredInspection[0] &&
            <StyledPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className="render-cells-popover">
                <li>{filteredInspection[0].projectName}</li>
                <li><span>Date Of Inspection:</span> <p>{filteredInspection[0].inspectionDOI}</p></li>
                <li><span>Inspection Type:</span> <p>{filteredInspection[0].inspectionType}</p></li>
                <li><span>Inspector Name:</span> <p>{filteredInspection[0].inspector}</p></li>
                <section className="render-cells-popover__cta">
                  <Link to={`/divisions/${appDivisionId}/projects/${filteredInspection[0].projectId}/inspections`}>
                    <span><FontAwesomeIcon icon={ faEye }/> View Project</span>
                  </Link>
                </section>
              </div>
            </StyledPopover>
          }
        </div>

        <div>
          { filteredPrecipitationData &&
            <StyledPopover
              id={idX}
              open={openX}
              anchorEl={anchorElX}
              onClose={handleCloseX}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className="render-cells-popover">
                <li><span>Rain:</span> <p>{filteredPrecipitationData ? Number(filteredPrecipitationData.rain_accumulation).toFixed(2) : 0}</p></li>
                <li><span>Snow:</span> <p>{filteredPrecipitationData ? Number(filteredPrecipitationData.snow_accumulation).toFixed(2) : 0}</p></li>
              </div>
            </StyledPopover>
          }
        </div>
      </span>
    </>
  )
}




export default MonthViewLayout;

