import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import validator from '../../../utils/FormValidator';
import AddUsersInput from '../AddUser/AddUsersInput';
import userApi from '../../../utils/api/user';

export const fieldTmpl = {
  controlType: UIControlType.customTagSelect,
  labelKey: 'name',
  valueKey: 'id',
  isMulti: true,
  openOnFocus: true,
};

const manageeRoles = (name) => {
  const roleName = ['Inspector', 'Area / Assistant Manager'];

  return roleName.includes(name);
};

const getSchema = (
  roles,
  divisions,
  isEdit,
  showEditRole,
  selectedRoleName,
  isProfile,
  isMyProfile,
) => {
  roles.sort((a, b) => a.name.localeCompare(b.name));

  const sch = {
    tags: {
      label: 'Email',
      controlType: UIControlType.custom,
      validation: {
        required: true,
      },
      renderControl: AddUsersInput,
    },
    roleId: {
      label: 'Role',
      controlType: UIControlType.select,
      options: roles,
      labelKey: 'name',
      valueKey: 'id',
      validation: {
        required: true,
      },
      parse: (val) => Number.parseInt(val, 10),
      disabled: isProfile,
    },
    divisionIds: {
      ...fieldTmpl,
      label: 'Divisions',
      options: divisions,
      noOptionMessage: 'No Divisions found',
      placeholder: 'Search for Divisions',
      validation: {
        required: true,
      },
    },
  };

  if (isEdit) {
    if (isMyProfile) {
      delete sch.divisionIds;
      delete sch.roleId;
    }
    delete sch.tags;
    sch.first_name = {
      label: 'First Name',
      validation: {
        required: true,
        maxLength: 100,
      },
      autoComplete: 'given-name',
      style: {
        flexBasis: '50%',
      },
    };
    sch.last_name = {
      label: 'Last Name',
      validation: {
        required: true,
        maxLength: 100,
      },
      autoComplete: 'family-name',
      style: {
        flexBasis: '50%',
      },
    };
    sch.email = {
      label: 'Email',
      validation: {
        required: true,
        maxLength: 200,
        format: 'email',
      },
      autoComplete: 'email',
    };
    sch.ccrNumber = {
      label: 'CCR #',
      controlType: UIControlType.text,
      toolTipOptions: {
        message: 'Delaware Certified Construction Reviewer Number',
      },
    };
    sch.peNumber = {
      label: 'PE #',
      controlType: UIControlType.text,
      toolTipOptions: {
        message: 'Professional Engineer Number',
      },
    };
    sch['address.phone'] = {
      label: 'Phone',
      mask: 'phone',
      validation: {
        required: true,
        format: 'phone',
      },
      style: {
        flexBasis: '70%',
      },
    };
    sch['address.phone_ext'] = {
      label: 'Extension',
      validation: {},
      style: {
        flexBasis: '30%',
      },
      parse: validator.parseNumber,
    };
    sch.position = getPositionField();
  }
  if (!showEditRole) {
    delete sch.roleId;
  }

  return sch;
};

const getSchema1 = (isEdit) => {
  const sch = {
    first_name: {
      label: 'First Name',
      validation: {
        required: true,
        maxLength: 100,
      },
      autoComplete: 'given-name',
      style: {
        flexBasis: '50%',
      },
    },
    last_name: {
      label: 'Last Name',
      validation: {
        required: true,
        maxLength: 100,
      },
      autoComplete: 'family-name',
      style: {
        flexBasis: '50%',
      },
    },
    position: getPositionField(),
    email: {
      label: 'Email',
      validation: {
        required: true,
        maxLength: 200,
        format: 'email',
      },
      style: {
        flexBasis: '50%',
      },
      autoComplete: 'email',
    },
    ccrNumber: {
      label: 'CCR #',
      controlType: UIControlType.text,
      toolTipOptions: {
        message: 'Delaware Certified Construction Reviewer Number',
      },
      style: {
        flexBasis: '50%',
      },
    },
    peNumber: {
      label: 'PE #',
      controlType: UIControlType.text,
      toolTipOptions: {
        message: 'Professional Engineer Number',
      },
      style: {
        flexBasis: '50%',
      },
    },
    tags: {
      label: 'Email',
      controlType: UIControlType.custom,
      validation: {
        required: true,
      },
      renderControl: AddUsersInput,
    },
  };

  if (isEdit) {
    delete sch.tags;

    sch['address.phone'] = {
      label: 'Phone',
      mask: 'phone',
      validation: {
        required: true,
        format: 'phone',
      },
      style: {
        flexBasis: '35%',
      },
    };
    sch['address.phone_ext'] = {
      label: 'Extension',
      validation: {},
      style: {
        flexBasis: '15%',
      },
      parse: validator.parseNumber,
    };
  }
  return sch;
};

export function getPositionField(required = false) {
  return {
    label: 'Position',
    toolTipOptions: {
      message:
        'Please include the position you hold at your company. When submitting an inspection signature, this value will be displayed, next to your name.',
    },
    validation: { required },
    style: {
      flexBasis: '50%',
    },
  };
}
const UserStepOne = ({
  formOpts = {roles: [], divisions: []},
  isEdit,
  showEditRole,
  isProfile,
  isMyProfile,
  adminTab,
  className,
}) => {
  const formContext = useContext(FormContext);
  const selectedRole = formOpts.roles.find(
    (v) => v.id === formContext.value.roleId,
  );

  return (
    <FormSchemaFields
      className={className || 'findings-form'}
      schema={
        adminTab
          ? getSchema1(isEdit)
          : getSchema(
              formOpts.roles,
              formOpts.divisions,
              isEdit,
              showEditRole,
              selectedRole
                ? selectedRole.name
                : formContext.initialValue.roleName
                ? formContext.initialValue.roleName
                : null,
              isProfile,
              isMyProfile,
            )
      }
      formData={formContext.value}
      onChange={formContext.replace}
    />
  );
};

const optionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

UserStepOne.propTypes = {
  formOpts: PropTypes.shape({
    roles: PropTypes.arrayOf(optionShape),
    divisions: PropTypes.arrayOf(optionShape),
  }),
  isEdit: PropTypes.bool,
  showEditRole: PropTypes.bool,
  isProfile: PropTypes.bool,
};

UserStepOne.defaultProps = {
  isEdit: false,
  showEditRole: true,
  isProfile: false,
};

export default UserStepOne;
