import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import ResourceIndex from '../Shared/ResourceIndex/ResourceIndex';
import RolesContext from '../../contexts/RolesContext';
import InspectionTemplateAdd from './InspectionTemplateAdd';
import { Modal } from '@sw-sw/lib-ui';
import AppContext from '../../contexts/AppContext';
import InspectionTemplateCopy from '../LegendItems/Form/InspectionTemplateCopy';

const InspectionTemplates: React.FC = () => {
  const rolesContext = useContext(RolesContext);
  const appStore = useContext(AppContext);

  const user = appStore.get('user');

  const getDiviId = window.location.pathname.split('/');

  return (
    <React.Fragment>
      <ResourceIndex
        resourceName='inspectionTemplates'
        resourceTableHeaders={[['Name', 'name']]}
        resourceSearchProps={['name']}
        resourceQueryProps={{ divisionId: getDiviId[2] }}
        enableRead={rolesContext.userHasPermission(
          'read',
          'Inspection Templates',
        )}
        enableClick={rolesContext.userHasPermission(
          'update',
          'Inspection Templates',
        )}
        enablePagination={false}
        enableCallToAction={rolesContext.userHasPermission(
          'create',
          'Inspection Templates',
        )}
        enableSelectAll={
          user.roleName === 'Super Admin' || user.roleName === 'Admin'
        }
        callToActionContent={'Inspection Template'}
        renderReader={(inspectionTemplate, handleClose) => {
          if (!inspectionTemplate.isUserTemplate) {
            return (
              <Modal
                show={true}
                handleSubmit={handleClose}
                handleClose={handleClose}
                title='Error'
                subTitle='System Templates can not be edited at this time.'
              />
            );
          } else {
            return (
              <Redirect
                push
                to={`/divisions/${getDiviId[2]}/inspection-templates/${inspectionTemplate.id}`}
              />
            );
          }
        }}
        renderCallToAction={(
          handleClose,
          pushData,
          _refetch,
          setSelectedItems,
          selectedItems,
        ) => {

          if (selectedItems.length > 0) {
            return (
              <InspectionTemplateCopy
                onSubmit={(data: any) => {
                  pushData(data);
                  setSelectedItems([]);
                  handleClose();
                }}
                onCancel={handleClose}
                selectedItems={selectedItems}
                role={user.roleName}
              />
            );
          } else {
            return <InspectionTemplateAdd handleClose={handleClose} />;
          }
        }}
        hideCallToActionInMobile={false}
      />
    </React.Fragment>
  );
};

export default InspectionTemplates;
