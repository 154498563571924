import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { Switch, Route, useHistory } from 'react-router-dom';
import {
  faPenSquare, faTrash, faArrowLeft, faArrowCircleUp, faArrowCircleDown, faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";
import moment from 'moment';
import {
  FormSchemaFields,
  FormContext,
  FormContextProvider,
} from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { Button, Modal } from '@sw-sw/lib-ui';
import Lightbox from "react-image-lightbox";


import MaterialPopover from '../../../Shared/MaterialPopover';
import AppDivisionContext from "../../../../contexts/AppDivisionContext";
import { ProjectContext } from '../../../../contexts/ProjectContext';
import projectSitePhotos from '../../../../utils/api/projectSitePhotos';
import AppContext from '../../../../contexts/AppContext';
import { getDownloadUrl } from '../../../../utils/api/upload';
import ConfirmationModal from '../../../Shared/ConfirmationModal/ConfirmationModal';
import Loading from '../../../Shared/ResourceIndex/Loading';

import '../ProjectSitePhotos/_index.scss';
import EXIF from 'exif-js';
import { dmsToDecimal } from '../../../../utils/googlemaps';

type FolderProps = {
  folderData: ProjectFolderFormDataType;
  deleteFolderFn: Function;
  updateFolderFn: Function;
}

type CreateFormModalPropsType = {
  show: boolean;
  handleClose: (formContext: any) => void;
  handleCreateFolderSubmit: (formContext: any) => void;
  updateFlag: boolean;
}

export type ImageFormData = {
  img: any;
  title: string;
  observation: string;
  gpsLat?: number;
  gpsLon?: number;
  timestamp?: string;
}

type ImageUploadModalPropsType = {
  show: boolean;
  handleClose: () => void;
  imgFormData: Array<ImageFormData>;
  setImgFormData: Function;
  handleUploadFiles: () => void;
  busy: boolean;
}

type ImgBeforeUploadContainerType = {
  hideUp: boolean;
  hideDown: boolean;
  index: number;
  reOrderImgData: Function;
  handleInputChange: Function;
  handleRemove: Function;
} & ImageFormData

export type ProjectFolderFormDataType = {
  id?: number;
  title: string;
  date: string;
  general_comments: string;
}

type SiteFilesUploadObj = {
  GUID: string;
  id: number;
  mime_type: string;
  name: string;
}

type SiteFilesData = {
  title: string;
  observation: string;
  id?: number;
  folder_id?: number;
  upload_id?: number;
  createdAt?: string;
  upload: SiteFilesUploadObj;
}

const ImgBeforeUploadContainer = (props: ImgBeforeUploadContainerType) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <div className='file-img-container'>
          <img src={URL.createObjectURL(props.img)} alt="Image To Upload" className='file-img' />
          <input type="hidden" name={`folder-img[]`} style={{ display: 'none' }} />
        </div>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2} className='img-input-container'>
          <Grid item xs={10}>
            <div className='img-text-input-wrapper'>
              <input type="text" placeholder='Add Title' name={`folder-img-title[]`} autoComplete="off" value={props.title} onChange={(e) => { props.handleInputChange('title', props.index, e.target.value) }} className='form-control form-control--text' required />
              <textarea name={`folder-img-observation[]`} placeholder='Add Observation' autoComplete="off" value={props.observation} onChange={(e) => { props.handleInputChange('observation', props.index, e.target.value) }} rows={2} className='observation-input form-control form-control--text' required></textarea>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className='arrow-holder'>
              {!props.hideUp && <FontAwesomeIcon icon={faArrowCircleUp} size={'lg'} onClick={() => props.reOrderImgData(props.index, props.index - 1)} />}
              {!props.hideDown && <FontAwesomeIcon icon={faArrowCircleDown} size={'lg'} onClick={() => props.reOrderImgData(props.index, props.index + 1)} />}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} className='remove-btn-holder' sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
        <span className='cursor-pointer rm-btn' onClick={() => props.handleRemove(props.index)} >Remove</span>
      </Grid>
      <Grid item xs={1} className='remove-btn-holder' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
        <FontAwesomeIcon className='cursor-pointer rm-btn' onClick={() => props.handleRemove(props.index)} icon={faTrash} size={'sm'} />
      </Grid>
    </Grid>
  );
}

const ImageUploadModal = (props: ImageUploadModalPropsType) => {

  const imgAddonInputRef = useRef<HTMLInputElement | null>(null);

  const reOrderImgData = (index1: number, index2: number) => {
    const arrayCopy: Array<ImageFormData> = [...props.imgFormData];
    const temp = arrayCopy[index1];
    arrayCopy[index1] = arrayCopy[index2];
    arrayCopy[index2] = temp;
    props.setImgFormData([...arrayCopy]);
  }

  const handleInputChange = (input: string, index: number, value: string) => {
    let tempImageDataObj: any = [...props.imgFormData];
    tempImageDataObj[index][input] = value;
    props.setImgFormData([...tempImageDataObj]);
  }

  const handleRemove = (index: number) => {
    let tempImageDataObj: any = [...props.imgFormData];
    tempImageDataObj.splice(index, 1);
    props.setImgFormData([...tempImageDataObj]);
  }

  const processSelectedAddonImages = (files: any) => {
    let tempImageDataObj: Array<ImageFormData> = [...props.imgFormData];

    Object.values(files).forEach((file: any) => {
      EXIF.getData(file, function (this: any) {
        const gpsLat = EXIF.getTag(this, 'GPSLatitude');
        const gpsLon = EXIF.getTag(this, 'GPSLongitude');
        const gpsLatRef = EXIF.getTag(this, 'GPSLatitudeRef');
        const gpsLonRef = EXIF.getTag(this, 'GPSLongitudeRef');
        const timestamp = EXIF.getTag(this, 'DateTime');

        const tempImgDataObj: ImageFormData = {
          img: file,
          title: '',
          observation: '',
          gpsLat: gpsLat ? dmsToDecimal(gpsLat, gpsLatRef) : null,
          gpsLon: gpsLon ? dmsToDecimal(gpsLon, gpsLonRef) : null,
          timestamp: timestamp ? timestamp : null,
        };

        tempImageDataObj.push(tempImgDataObj);

        // If all files have been processed, set the state
        if (
          tempImageDataObj.length ===
          props.imgFormData.length + Object.values(files).length
        ) {
          props.setImgFormData(tempImageDataObj);
        }
      });
    });
  }

  return (
    <Modal
      title="Upload Image"
      show={props.show}
      hideButtons
      handleClose={props.handleClose}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <input type='file' id='img-files' name='img-files' multiple={true} accept="image/*" ref={imgAddonInputRef} style={{ display: 'none' }} onChange={(e) => processSelectedAddonImages(e.target.files)} />
            <Button onClick={() => imgAddonInputRef.current!.click()} primary >Add Photos <FontAwesomeIcon icon={faPlus} size={'sm'} /></Button>
          </Grid>

          {props.imgFormData && Object.values(props.imgFormData).map((item: ImageFormData, index) => {
            return (
              <Grid item xs={12} key={index}>
                <ImgBeforeUploadContainer
                  index={index}
                  hideUp={(index === 0)}
                  hideDown={(index === (Object.values(props.imgFormData).length - 1))}
                  img={item.img}
                  title={item.title}
                  observation={item.observation}
                  reOrderImgData={reOrderImgData}
                  handleInputChange={handleInputChange}
                  handleRemove={handleRemove}
                />
              </Grid>
            )
          })}
          <Grid item xs={12}>
            <Button onClick={() => props.handleClose()}>Cancel</Button>
            <Button primary onClick={() => props.handleUploadFiles()} disabled={props.busy} style={{ marginLeft: '5px' }}>Upload</Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const CreateFolderFormModal = (props: CreateFormModalPropsType) => {

  const formContext = useContext(FormContext);
  const formData = formContext.value;
  const formSectionProps = {
    formData,
    onChange: formContext.set,
  };

  return (
    <Modal
      title={`${props.updateFlag ? 'Update' : 'Create'} Folder`}
      show={props.show}
      hideButtons
      handleClose={() => props.handleClose(formContext)}
    >
      <FormSchemaFields
        {...formSectionProps}
        schema={{
          title: {
            label: "Title",
            validation: { required: true, maxLength: 255 },
            autoComplete: "none",
          },
          date: {
            label: "Date",
            controlType: UIControlType.date,
            validation: { required: true },
          },
          general_comments: {
            label: "General Comments",
            controlType: UIControlType.textarea,
            validation: { required: true },
          }
        }}
      />
      <Button onClick={() => props.handleClose(formContext)}>Cancel</Button>
      <Button primary onClick={() => props.handleCreateFolderSubmit(formContext)} style={{ marginLeft: '5px' }} >Ok</Button>
    </Modal>
  );
}

const Folder = (props: FolderProps) => {

  const history = useHistory();
  const appDivisionContext = useContext(AppDivisionContext);
  const projectStore = useContext(ProjectContext);
  const formContext = useContext(FormContext);

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const redirectToFolderDetails = (folderId: number) => {
    return history.push(`/divisions/${appDivisionContext.appDivisionId}/projects/${projectStore.project!.id}/site-photos/${folderId}/files`);
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <div className='folder-container'>
        <Grid container spacing={2}>
          <Grid item xs={10} onClick={() => redirectToFolderDetails(props.folderData.id!)} className='cursor-pointer'>
            <Typography className='heading'>{props.folderData.title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <MaterialPopover options={[
              {
                icon: faPenSquare,
                label: 'Edit',
                onClickHandler: () => { props.updateFolderFn(props.folderData, formContext) }
              },
              {
                icon: faTrash,
                label: 'Delete',
                onClickHandler: () => { setShowConfirmationModal(true) }
              }
            ]} />
          </Grid>
          <Grid item xs={12} onClick={() => redirectToFolderDetails(props.folderData.id!)} className='cursor-pointer'>
            <Typography>
              <span className='font-bold'>General Comments :</span> {props.folderData.general_comments}
            </Typography>
          </Grid>
          <Grid item xs={12} onClick={() => redirectToFolderDetails(props.folderData.id!)} className='cursor-pointer'>
            <Typography>
              Date : <span className='font-bold'>{moment(props.folderData.date.slice(0,-1)).format("MM-DD-YYYY")}</span>
            </Typography>
          </Grid>
        </Grid>
      </div>
      {
        showConfirmationModal &&
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleConfirm={() => {
            setShowConfirmationModal(false);
            props.deleteFolderFn(props.folderData.id);
          }}
          title="Are you sure you want to delete this folder?"
          subTitle="This can not be undone."
          buttonText="Delete Folder"
        />
      }

    </Grid>
  );
}

const EditFilesModal = (props: {
  show: boolean;
  handleClose: Function;
  handleFileUpdateSubmit: Function;
  data: SiteFilesData;
}) => {

  const [placeHolderImg, setPlaceHolderImg] = useState<any>();
  const [formImg, setFormImg] = useState<string>('');
  const [formTitle, setFormTitle] = useState<string>('');
  const [formObservation, setFormObservation] = useState<string>('');
  const [showLightBox, setShowLightBox] = useState<boolean>(false);
  const imgChangeInputRef = useRef<HTMLInputElement | null>(null);

  const processSelectedImage = (files: any) => {
    setPlaceHolderImg(URL.createObjectURL(files[0]));
    setFormImg(files[0]);
  }

  const submitUpdateFile = () => {

    const formData = new FormData();

    if (typeof formImg !== 'string') {
      formData.append('img', formImg);
    }

    if (props.data.title !== formTitle) {
      formData.append('title', formTitle);
    }

    if (props.data.observation !== formObservation) {
      formData.append('observation', formObservation);
    }

    props.handleFileUpdateSubmit(props.data.id, formData);

  }

  useEffect(() => {
    setPlaceHolderImg(getDownloadUrl(props.data.upload.GUID, null, false, Date.now(), null, 'sitePhotos'));
    setFormImg(getDownloadUrl(props.data.upload.GUID, null, false, Date.now(), null, 'sitePhotos'));
    setFormTitle(props.data.title);
    setFormObservation(props.data.observation);
  }, [props.data]);

  return (
    <Modal
      title={`Update File`}
      show={props.show}
      hideButtons
      handleClose={() => props.handleClose()}
    >
      <Grid container spacing={2}>

        <Grid item xs={5}>
          <div className='file-img-container'>
            <img src={placeHolderImg} onClick={() => setShowLightBox(true)} alt="site photo image" className='file-img cursor-pointer' />
            {showLightBox &&
              <Lightbox
                mainSrc={placeHolderImg}
                onCloseRequest={() => setShowLightBox(false)}
              />
            }
          </div>
        </Grid>

        <Grid item xs={7}>
          <input type="file" id='change-img-files' name='change-img-files' accept="image/*" ref={imgChangeInputRef} style={{ display: 'none' }} onChange={(e) => processSelectedImage(e.target.files)} />
          <Button onClick={() => imgChangeInputRef.current!.click()} >Change Image</Button>
        </Grid>

        <Grid item xs={12}>
          <div className='img-text-input-wrapper'>
            <label htmlFor={"folder-img-title"}> <b> Title </b> </label>
            <input type="text" placeholder='Add Title' name={`folder-img-title`} value={formTitle} onChange={(e) => { setFormTitle(e.target.value) }} className='form-control form-control--text' required />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='img-text-input-wrapper'>
            <label htmlFor={"folder-img-observation"}> <b> Observation </b> </label>
            <textarea name={`folder-img-observation`} placeholder='Add Observation' value={formObservation} onChange={(e) => { setFormObservation(e.target.value) }} rows={2} className='observation-input form-control form-control--text' required></textarea>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={() => props.handleClose()}>Cancel</Button>
          <Button primary style={{ marginLeft: '5px' }} onClick={() => submitUpdateFile()}>Ok</Button>
        </Grid>

      </Grid>

    </Modal>
  );
}

const FileItem = (props: SiteFilesData & { getSiteFiles: Function; }) => {

  const appDivisionContext = useContext(AppDivisionContext);
  const projectStore = useContext(ProjectContext);

  const [showLightBox, setShowLightBox] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const destroyImgFile = async (fileId: number) => {

    const toastId = toast("Deleting File!");
    const urlArray = window.location.pathname.split("/");
    const folderId = Number(urlArray[6]);

    const deleteFolderStatus = await projectSitePhotos.deleteFile({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, folderId, fileId });
    if (deleteFolderStatus.success === true) {
      props.getSiteFiles();
      toast.update(toastId, {
        render: "File Deleted Successfully!",
        type: "success",
      });
    }

  }

  const handleFileUpdateSubmit = async (fileId: number, formData: any) => {

    const toastId = toast("Updating File!");
    const urlArray = window.location.pathname.split("/");
    const folderId = Number(urlArray[6]);

    const updateFolderStatus = await projectSitePhotos.updateFile({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, folderId, fileId, formData });
    setShowEditModal(false);
    if (updateFolderStatus.success === true) {
      toast.update(toastId, {
        render: "File Updated Successfully!",
        type: "success",
      });
    }
    props.getSiteFiles();

  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <div className='folder-container file-container' >
        <Grid container spacing={2}>

          <Grid item xs={3}>
            <div className='file-img-container'>
              <img src={getDownloadUrl(props.upload.GUID, null, false, Date.now(), null, 'sitePhotos')} onClick={() => setShowLightBox(true)} alt="site photo image" className='file-img cursor-pointer' />
              {showLightBox &&
                <Lightbox
                  mainSrc={getDownloadUrl(props.upload.GUID, null, false, Date.now(), null, 'sitePhotos')}
                  onCloseRequest={() => setShowLightBox(false)}
                />
              }
            </div>
          </Grid>

          <Grid item xs={9}>

            <Grid container>
              <Grid item xs={12}>

                <Grid container>

                  <Grid item xs={8}>
                    <Typography className='heading'>{props.title}</Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <MaterialPopover options={[
                      {
                        icon: faPenSquare,
                        label: 'Edit',
                        onClickHandler: () => { setShowEditModal(true); }
                      },
                      {
                        icon: faTrash,
                        label: 'Delete',
                        onClickHandler: () => { setShowConfirmationModal(true) }
                      }
                    ]} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      <span className='font-bold'>Observation :</span> {props.observation}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      Date : <span className='font-bold'>{moment(props.createdAt).format("MM-DD-YYYY")}</span>
                    </Typography>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>

          </Grid>

          {
            showEditModal &&
            <EditFilesModal
              show={showEditModal}
              handleClose={() => setShowEditModal(false)}
              handleFileUpdateSubmit={handleFileUpdateSubmit}
              data={{ id: props.id, title: props.title, observation: props.observation, upload: props.upload }}
            />
          }

        </Grid>
      </div>
      {
        showConfirmationModal &&
        <ConfirmationModal
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleConfirm={() => {
            setShowConfirmationModal(false);
            destroyImgFile(props.id!);
          }}
          title="Are you sure you want to delete this file?"
          subTitle="This can not be undone."
          buttonText="Delete File"
        />
      }
    </Grid>
  );
}

const FolderDetails = (props: { getSiteFiles: Function; siteFiles: Array<SiteFilesData>; loading: boolean; }) => {

  useEffect(() => {
    props.getSiteFiles();
  }, []);

  return (
    <Grid container spacing={2}>
      {
        (props.loading) ? <Grid item xs={12}> <div style={{ textAlign: 'center' }}> <Loading /> </div> </Grid> :
          (props.siteFiles.length) ? props.siteFiles.map((file: SiteFilesData) => <FileItem id={file.id} title={file.title} observation={file.observation} createdAt={file.createdAt} upload={file.upload} getSiteFiles={props.getSiteFiles} />)
            :
            <Grid item xs={12}>
              <p style={{ textAlign: 'center' }}>No files to display</p>
            </Grid>
      }
    </Grid>
  )
}

const ProjectSitePhotos = () => {

  const history = useHistory();
  const appDivisionContext = useContext(AppDivisionContext);
  const projectStore = useContext(ProjectContext);
  const appStore = useContext(AppContext);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [imgFormData, setImgFormData] = useState<Array<ImageFormData>>([]);
  const [folders, setFolders] = useState<[]>([]);
  const [siteFiles, setSiteFiles] = useState<Array<SiteFilesData>>([]);
  const [updateFlag, setUpdateFlag] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const imgInputRef = useRef<HTMLInputElement | null>(null);

  const backToFolders = () => {
    return history.push(`/divisions/${appDivisionContext.appDivisionId}/projects/${projectStore.project!.id}/site-photos/`);
  }

  const processSelectedImages = (files: any) => {
    setShowImageUploadModal(true);

    const tempImgDataObj: Array<ImageFormData> = [];

    Object.values(files).forEach((file: any) => {
      EXIF.getData(file, function (this: any) {
        const gpsLat = EXIF.getTag(this, 'GPSLatitude');
        const gpsLon = EXIF.getTag(this, 'GPSLongitude');
        const gpsLatRef = EXIF.getTag(this, 'GPSLatitudeRef');
        const gpsLonRef = EXIF.getTag(this, 'GPSLongitudeRef');
        const timestamp = EXIF.getTag(this, 'DateTime');

        tempImgDataObj.push({
          img: file,
          title: '',
          observation: '',
          gpsLat: gpsLat ? dmsToDecimal(gpsLat, gpsLatRef) : null,
          gpsLon: gpsLon ? dmsToDecimal(gpsLon, gpsLonRef) : null,
          timestamp: timestamp ? timestamp : null,
        });

        // If all files have been processed, set the state
        if (tempImgDataObj.length === Object.values(files).length) {
          setImgFormData(tempImgDataObj);
        }
      });
    });
  }

  const getSiteFiles = async () => {

    setLoading(true);
    const urlArray = window.location.pathname.split("/");
    const folderId = Number(urlArray[6]);

    const uploadingStatus = await projectSitePhotos.getFiles({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, folderId });

    if (uploadingStatus && uploadingStatus.success === true) {
      setSiteFiles(uploadingStatus.data);
      setLoading(false);
    } else {
      setSiteFiles([]);
      setLoading(false);
    }

  }

  const handleUploadFiles = async () => {

    const urlArray = window.location.pathname.split("/");
    const folderId = Number(urlArray[6]);

    const toastId = toast("Uploading Files!");
    setBusy(true);

    if (imgFormData) {

      const formData: any = new FormData();
      formData.append('folderId', folderId);

      imgFormData.map((elm, index) => {
        formData.append(`imgData[${index}][img]`, elm.img);
        formData.append(`imgData[${index}][title]`, elm.title);
        formData.append(`imgData[${index}][observation]`, elm.observation);
        if (elm.gpsLat) formData.append(`imgData[${index}][gpslat]`, elm.gpsLat);
        if (elm.gpsLon) formData.append(`imgData[${index}][gpslon]`, elm.gpsLon);
        if (elm.timestamp) formData.append(`imgData[${index}][timestamp]`, elm.timestamp);
      });

      const uploadingStatus = await projectSitePhotos.uploadFiles({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, formData });
      setBusy(false);
      if (uploadingStatus.success === true) {
        // getSitePhotosFiles();
        toast.update(toastId, {
          render: "Files Uploaded Successfully!",
          type: "success",
        });
      } else {
        toast.update(toastId, {
          render: "Something went wrong, files not uploaded!",
          type: "error",
        });
      }
    } else {
      setBusy(false);
      toast.update(toastId, {
        render: "Something went wrong! Images not found, Please try again!",
        type: "error",
      });
    }

    closeAndResetFilesModal();

  }

  const closeAndResetFilesModal = () => {
    getSiteFiles();
    setShowImageUploadModal(false);
    imgInputRef.current!.value = "";
  }

  const getSitePhotosFolder = async () => {
    setLoading(true);
    const folderList = await projectSitePhotos.indexFolder({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId! });
    if (folderList && folderList.data) { setFolders(folderList.data); setLoading(false); } else { setFolders([]); setLoading(false); }
  }

  const updateFolder = async (folderItem: ProjectFolderFormDataType, formContext: any) => {
    setUpdateFlag(true);
    formContext.set("id", folderItem.id);
    formContext.replace("title", folderItem.title);
    formContext.replace("date", folderItem.date);
    formContext.replace("general_comments", folderItem.general_comments);
    setShowCreateForm(true);
  }

  const deleteFolder = async (folderId: number) => {
    const toastId = toast("Deleting Folder!");
    const deleteFolderStatus = await projectSitePhotos.deleteFolder({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, folderId });
    if (deleteFolderStatus.success === true) {
      getSitePhotosFolder();
      toast.update(toastId, {
        render: "Folder Deleted Successfully!",
        type: "success",
      });
    }
  }

  const resetCreateFolderForm = (formContext: any) => {
    formContext.replace("id", "");
    formContext.replace("title", "");
    formContext.replace("date", "");
    formContext.replace("general_comments", "");
    setUpdateFlag(false);
  }

  useEffect(() => {
    getSitePhotosFolder();
  }, []);

  return (
    <Switch>

      <Route path={'/divisions/:divisionId/projects/:projectId/site-photos/:folderId/files'}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5} md={9}>
                  <span onClick={() => backToFolders()} className='cursor-pointer'><FontAwesomeIcon icon={faArrowLeft} size={'sm'} /> All Folders</span>
                </Grid>
                <Grid item xs={7} md={3} className='action-buttons-container'>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={6}><Button >Print Report</Button></Grid> */}
                    <Grid item xs={4} md={6}></Grid>
                    <Grid item xs={8} md={6}>
                      <input type='file' id='img-files' name='img-files' multiple={true} accept="image/*" ref={imgInputRef} style={{ display: 'none' }} onChange={(e) => processSelectedImages(e.target.files)} />
                      <Button primary onClick={() => imgInputRef.current!.click()}>Add Photos</Button>
                      <ImageUploadModal
                        show={showImageUploadModal}
                        imgFormData={imgFormData}
                        setImgFormData={setImgFormData}
                        handleClose={closeAndResetFilesModal}
                        handleUploadFiles={handleUploadFiles}
                        busy={busy}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>

              <FolderDetails getSiteFiles={getSiteFiles} siteFiles={siteFiles} loading={loading} />

            </Grid>
          </Grid>
        </Box>
      </Route>

      <Route path={'/'}>
        <Box sx={{ flexGrow: 1 }} className='create-folder-btn-wrapper'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button primary className='create-folder' onClick={() => setShowCreateForm(true)}>Create Folder</Button>
            </Grid>
          </Grid>
        </Box>
        <FormContextProvider>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {(folders.length) ? folders.map((item, index) => <Folder key={index} folderData={item} deleteFolderFn={deleteFolder} updateFolderFn={updateFolder} />) :
                (loading) ? <Grid item xs={12}> <div style={{ textAlign: 'center' }}><Loading /></div> </Grid> :
                  <Grid item xs={12}>
                    <p style={{ textAlign: 'center' }}>No folders to display</p>
                  </Grid>
              }
            </Grid>
          </Box>
          <CreateFolderFormModal show={showCreateForm} updateFlag={updateFlag}
            handleClose={async (formContext) => {
              setShowCreateForm(false);
              resetCreateFolderForm(formContext);
            }}
            handleCreateFolderSubmit={async (formContext) => {

              const toastId = toast(`${updateFlag ? 'Updating' : 'Creating'} Folder!`);
              const formData: ProjectFolderFormDataType = formContext.value;
              const userId = appStore.get("user.id");

              const formValidate = await formContext.validate();

              if (!formContext.isBusy && formValidate && userId) {

                formContext.setBusy(true);

                let queryResultStatus: { success: boolean; message?: string; } = { success: false };

                if (updateFlag == true) {
                  queryResultStatus = await projectSitePhotos.updateFolder({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, formData, userId });
                } else {
                  queryResultStatus = await projectSitePhotos.createFolder({ projectId: projectStore.project!.id, divisionId: appDivisionContext.appDivisionId!, formData, userId });
                }


                if (queryResultStatus && queryResultStatus.success == true) {
                  toast.update(toastId, {
                    render: `Folder ${updateFlag ? 'Updated' : 'Created'} Successfully!`,
                    type: "success",
                  });
                  formContext.setBusy(false);
                  setShowCreateForm(false);
                } else {
                  toast.update(toastId, {
                    render: (queryResultStatus.message && queryResultStatus.message == 'Validation error' )? "Title is unique field! Try again with another title." : "Something went wrong while creating folder!",
                    type: "error",
                  });
                  formContext.setBusy(false);
                  setShowCreateForm(false);
                }
                
                resetCreateFolderForm(formContext);
                getSitePhotosFolder();
                setUpdateFlag(false);

              } else {
                toast.update(toastId, {
                  render: "Please fill all required details!",
                  type: "error",
                });
              }


            }}
          />
        </FormContextProvider>
      </Route>

    </Switch>
  )
}

export default ProjectSitePhotos