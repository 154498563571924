import {
  FormContextProvider,
  FormContext,
  FormSchemaFields,
} from '@sw-sw/lib-form';
import { string, number, shape, arrayOf, array, func } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import iContextProviderProps from '../../../contexts/ClientContext';
import TenantFeatureContext from '../../../contexts/TenantContext';
import { env } from '../../../config';
import { useStates } from '../../../hooks/address';
import { useCountries } from '../../../hooks/address';
import validator from '../../../utils/FormValidator';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { defaultFindingObservationTemplates } from '../../../utils/clients';
import Loading from '../../Shared/ResourceIndex/Loading';
import NewClientDocTypeFolder from '../Forms/NewClientDocTypeFolder';
import clientApi from '../../../utils/api/client';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { Modal, TooltipContextProvider } from '@sw-sw/lib-ui';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { submitHandler } from '../../Shared/form';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { IconButton } from '@mui/material';
import ToggleSmall from '../../Shared/Toggle/ToggleSmall';
import { ActionButtonsWithScrollFix } from '../../Shared/ActionButtons';
import ReactTooltip from 'react-tooltip';

const clientShape = shape({
  name: string,
  id: number,
  address: shape({
    phone: string,
    street_1: string,
    street_2: string,
    city: string,
    zip: string,
    state: shape({ id: number }),
  }),
});

const deleteCustomDocType = async (
  docTypeId,
  setDeleteDocTypeError,
  setShowDeleteModal,
  docTypesRefetch,
) => {
  try {
    let clientId = window.location.pathname.split('/')[4];

    const deleteResponse = await clientApi.deleteCustomClientDocType.index(
      clientId,
      docTypeId,
    );

    if (deleteResponse.hasOwnProperty('Error')) {
      setDeleteDocTypeError(deleteResponse.Error);
    } else {
      setShowDeleteModal(false);
      docTypesRefetch.refetch();
    }
  } catch (error) {
    console.log(error);
  }
};

const editCustomDocType = async (
  docTypeId,
  setDeleteDocTypeError,
  setShowEditModal,
  docTypesRefetch,
  selectedDocTypeName,
) => {
  try {
    let clientId = window.location.pathname.split('/')[4];

    await clientApi.editCustomClientDocType.index(
      clientId,
      docTypeId,
      selectedDocTypeName,
    );
    docTypesRefetch.refetch();
  } catch (error) {
    console.log(error);
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

function getSchemaClientInformation(states) {
  return {
    name: {
      label: 'Client Name',
      autoComplete: 'organization',
      validation: {
        required: true,
      },
      style: {
        flexBasis: '50%',
      },
    },
    phone: {
      label: 'Phone',
      autoComplete: 'tel',
      mask: env.REACT_APP_REGION === 'UK' ? 'phoneUK' : 'phone',
      validation: {
        required: env.REACT_APP_REGION === 'UK' ? false : true,
        format: env.REACT_APP_REGION === 'UK' ? 'phoneUK' : 'phone',
      },
      style: {
        flexBasis: '50%',
      },
    },
    street1: {
      label: 'Street 1',
      placeholder: 'Street',
      autoComplete: 'address-line1',
      'aria-label': 'Street address line 1 of 2',
      validation: {
        required: true,
      },
      style: {
        flexBasis: '50%',
      },
    },
    street2: {
      label: 'Street 2',
      placeholder: 'Street Line 2',
      'aria-label': 'Street address line 2 of 2',
      autoComplete: 'address-line2',
      style: {
        flexBasis: '50%',
      },
    },
    city: {
      placeholder: env.REACT_APP_REGION === 'UK' ? 'Town/City' : 'City',
      'aria-label': 'City',
      autoComplete: 'address-level2',
      validation: {
        required: true,
      },
      style: {
        flexBasis: '50%',
      },
    },
    state: {
      controlType: UIControlType.select,
      placeholder: env.REACT_APP_REGION === 'UK' ? 'Country' : 'State',
      'aria-label': 'State',
      autoComplete: 'address-level1',
      options: states,
      labelKey: 'name',
      valueKey: 'id',
      style: { flex: '1 1 25%' },
      validation: {
        required: true,
      },
    },
    zip: {
      placeholder: env.REACT_APP_REGION === 'UK' ? 'Postcode' : 'Zip',
      'aria-label': 'zip code',
      className: 'zip',
      autoComplete: 'postal-code',
      maxLength: 5,
      style: { flex: '1 1 25%' },
      validation: {
        required: true,
      },
      inputMode: 'numeric',
      parse: validator.parseNumber,
    },
    code: {
      label: 'Code',
      autoComplete: 'code',
      style: {
        flexBasis: '50%',
        maxWidth: '50%',
      },
    },
  };
}

function getSchemaFindings(
  featureEnableEnhancedFinding,
  enablePhotoUploads,
  enableDissolvingPhotos,
) {
  const oneThirdWidthStyle = { flex: '1 1 25%', alignSelf: 'flex-end' };

  ReactTooltip.rebuild();

  return {
    default_finding_observation: {
      label: 'Default Finding Observation',
      controlType: UIControlType.textareaTmpl,
      templates: defaultFindingObservationTemplates,
    },
    disable_images: {
      label: 'Disable Images',
      controlType: UIControlType.checkbox,
      style: {
        display: enablePhotoUploads ? 'block' : 'none',
        ...oneThirdWidthStyle,
      },
    },
    disable_carry_overs: {
      label: 'Disable Carry Overs',
      controlType: UIControlType.checkbox,
      style: { justifyItems: 'flex-start', ...oneThirdWidthStyle },
    },
    finding_metric_tracking: {
      controlType: UIControlType.checkbox,
      label: 'Enable Enhanced Finding',
      toolTipOptions: {
        message:
          'When enabled, findings may be tied to specific legend items available for use within site map editor. This data will then be used for reporting.',
      },
      style: {
        justifyItems: 'center',
        ...oneThirdWidthStyle,
        display: featureEnableEnhancedFinding ? 'block' : 'none',
      },
    },
    dissolve_finding_images: {
      controlType: UIControlType.checkbox,
      label: 'Dissolving Photos',
      toolTipOptions: {
        message:
          '"When enabled, finding photos will be permanently deleted from the system once a date completed has been entered/the finding has been closed” These photos will not be able to be retrieved once they are deleted.',
      },
      style: {
        justifyItems: 'center',
        ...oneThirdWidthStyle,
        display:
          enableDissolvingPhotos && enablePhotoUploads ? 'block' : 'none',
      },
    },
  };
}

function getSchemaProjectDoc(
  docTypes,
  projectTabs,
  document_date,
  setShowDeleteModal,
  setSelectedDocTypeId,
  setShowEditModal,
  setSelectedDocTypeName,
  setShowReorderModal,
  tempReorderDocTypes,
) {
  const handleDeleteIconClick = async (event, docTypeId) => {
    event.preventDefault();
    setShowDeleteModal(true);
    setSelectedDocTypeId(docTypeId);
  };

  const handleEditCustomDocType = async (event, docTypeId) => {
    const docType = docTypes.filter((ele) => ele.id === docTypeId);

    event.preventDefault();
    setShowEditModal(true);
    setSelectedDocTypeId(docTypeId);

    if (docType.length && docType[0].client_dt && docType[0].client_dt.name) {
      setSelectedDocTypeName(docType[0].client_dt.name);
    }
  };

  const handleOnReorderButtonClick = () => {
    setShowReorderModal(true);
  };

  const filteredDocTypes = docTypes.filter(
    (e) =>
      e.name !== 'Fixed Details' &&
      e.name !== 'Fixed Details Project' &&
      e.name !== 'Active Site Maps',
  );
  const finalDocTypes = tempReorderDocTypes.length
    ? tempReorderDocTypes.filter(
        (e) => e.name !== undefined && e.name !== 'Active Site Maps',
      )
    : filteredDocTypes;

  return {
    projectTabs: {
      label: 'Project Tabs',
      controlType: UIControlType.checkboxGroup,
      options: projectTabs,
      valueKey: 'id',
      labelKey: 'name',
      style: { flexBasis: '50%' },
    },
    docTypes: {
      label: 'Document Types',
      controlType: UIControlType.checkboxGroup,
      options: finalDocTypes,
      valueKey: 'id',
      labelKey: 'name',
      deleteCustomDocType: handleDeleteIconClick,
      editCustomDocType: handleEditCustomDocType,
      reorderEnabled: true,
      onReorderButtonClick: handleOnReorderButtonClick,
      style: { flexBasis: '50%' },
    },
    document_date: {
      label: 'Document Date',
      controlType: UIControlType.checkboxGroup,
      options: [document_date],
      valueKey: 'id',
      labelKey: 'name',
    },
  };
}

function getSchemeCertificationBackdated(enableMetadataReuirement) {
  return {
    backdated: {
      label: 'Disable Missed Inspection Backdating',
      controlType: UIControlType.checkbox,
      className: 'backdated-checkbox',
      toolTipOptions: {
        message:
          'When this feature is checked, inspections submitted after the date of inspection will be considered as missed, even if the inspection certification is backdated to the date of inspection. When this feature is not checked inspections submitted after the date of inspection will not be considered as missed if the inspection certification is backdated to the date of inspection.',
      },
    },
    forecast_weather: {
      controlType: UIControlType.checkbox,
      label: 'NOAA Weather Table',
    },
    enable_photo_metadata_requirement: {
      controlType: UIControlType.checkbox,
      label: 'Enable Photo Metadata Requirement',
      style: {
        display: enableMetadataReuirement ? 'block' : 'none',
      },
    },
  };
}

function getSchemePrecipitation(tenantPrecipitationTracking) {
  return {
    precipitation_client_project_flag: {
      controlType: UIControlType.toggleButton,
      toggleSize: 'sm',
      style: {
        marginTop: '10px',
        marginLeft: '40px',
      },
      disabled: !tenantPrecipitationTracking ? true : false,
    },
  };
}

function getInitialData(client) {
  const { address = {} } = client;

  return {
    name: client.name,
    code: client.code,
    street1: address.street_1,
    street2: address.street_2,
    city: address.city,
    state:
      env.REACT_APP_REGION === 'UK'
        ? address.countryObj && address.countryObj.id
        : address.state && address.state.id,
    zip: address.zip,
    phone: address.phone,
    default_finding_observation: client.default_finding_observation,
    disable_images: client.disable_images,
    precipitation_client_project_flag: client.precipitation_client_project_flag,
    disable_carry_overs: client.disable_carry_overs,
    finding_metric_tracking: client.finding_metric_tracking,
    dissolve_finding_images: client.dissolve_finding_images,
    enable_photo_metadata_requirement: client.enable_photo_metadata_requirement,
    forecast_weather: client.forecast_weather,
    backdated: client.backdated_certification,
  };
}

function getHeaders(header, width) {
  return (
    <div style={{ width: width }} className='client-settings-list-item'>
      <div>{header}</div>
    </div>
  );
}

function CustomList({ data }) {
  const [listData, setListData] = useState(data);

  const handleChange = (key, value, id) => {
    const updatedList = listData.map((item) => {
      if (item.id === id) {
        clientApi.projects.updateProjectPrecipitationDetails(id, {
          ...item,
          [key]: value,
        });

        return { ...item, [key]: value };
      }

      return item;
    });

    setListData(updatedList);
  };

  if (!listData.length) {
    return <>There is no data to track</>;
  }

  return (
    <div className='list-container'>
      <div className='client-settings-list-header'>
        <div
          style={{ width: '15%', justifyContent: 'flex-start' }}
          className='list-item'
        >
          <div>Project Name</div>
        </div>
        {getHeaders('', '10%')}
        {getHeaders('Rain Threshold (inches)', '30%')}
        {getHeaders('Snow Threshold (inches)', '30%')}
        {getHeaders('Feature Lock', '15%')}
      </div>
      <div className='list-body'>
        {listData.map((project) => (
          <div key={project.id} className='client-settings-list-row'>
            <div
              style={{ width: '15%', justifyContent: 'flex-start' }}
              className='list-item'
            >
              <div>{project.name}</div>
            </div>

            {getHeaders(
              <ToggleSmall
                isChecked={project.precipitation_flag}
                handleChange={(e) => {
                  handleChange(
                    'precipitation_flag',
                    e.target.checked,
                    project.id,
                  );
                }}
                disabled={project.precipitation_edit_lock}
              />,
              '10%',
            )}
            {getHeaders(
              <input
                type='number'
                value={project.precipitation_threshold}
                onChange={(e) => {
                  handleChange(
                    'precipitation_threshold',
                    Number(e.target.value || 0),
                    project.id,
                  );
                }}
                disabled={project.precipitation_edit_lock}
                style={{
                  border: '1px solid #c5cfda',
                  padding: '0.5rem 0.75rem',
                  color: '#385980',
                }}
              />,
              '30%',
            )}
            {getHeaders(
              <input
                type='number'
                value={project.snow_threshold}
                onChange={(e) => {
                  handleChange(
                    'snow_threshold',
                    Number(e.target.value || 0),
                    project.id,
                  );
                }}
                disabled={project.precipitation_edit_lock}
                style={{
                  border: '1px solid #c5cfda',
                  padding: '0.5rem 0.75rem',
                  color: '#385980',
                }}
              />,
              '30%',
            )}
            {getHeaders(
              <IconButton
                size='small'
                onClick={() => {
                  handleChange(
                    'precipitation_edit_lock',
                    !project.precipitation_edit_lock,
                    project.id,
                  );
                }}
              >
                {project.precipitation_edit_lock ? (
                  <LockIcon color='primary' opacity={0.7} />
                ) : (
                  <LockOpenIcon color='primary' opacity={1} />
                )}
              </IconButton>,
              '15%',
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function ClientSettingsTabUI({
  states,
  docTypes,
  projectTabs,
  document_date,
  docTypesRefetch,
  toSubmitOrderData,
  setToSubmitOrderData,
  ...props
}) {
  const formContext = useContext(FormContext);
  const context = useContext(iContextProviderProps);
  const [allClientProjects] = useState(context.clientProjects);
  const { tenantHasFeature } = useContext(TenantFeatureContext);

  const [clientInformationCollapse, setClientInformationCollapse] =
    useState(false);
  const [findingsCollapse, setFindingsCollapse] = useState(false);
  const [inspectionCollapse, setInspectionCollapse] = useState(false);
  const [projectDocCollapse, setProjectDocCollapse] = useState(false);
  const [precipitationTrackingCollapse, setPrecipitationTrackingCollapse] =
    useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDocTypeId, setSelectedDocTypeId] = useState(false);
  const [deleteDocTypeError, setDeleteDocTypeError] = useState('');
  const [selectedDocTypeName, setSelectedDocTypeName] = useState('');
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [tempReorderDocTypes, setTempReorderDocTypes] = useState([]);
  const [tempToSubmitOrderData, setTempToSubmitOrderData] = useState([]);

  let clientId = window.location.pathname.split('/')[4];

  useEffect(() => {
    let filteredDT =
      // these two doc types exists in db, but needs to be displayed in very few places, in the frontend. So, remove manually where not required.
      docTypes &&
      docTypes.filter(
        (e) => e.name !== 'Fixed Details' && e.name !== 'Fixed Details Project',
      );

    setTempReorderDocTypes([
      ...(filteredDT || ''),
      ...(props.newDocTypes || ''),
    ]);
  }, [docTypes, props.newDocTypes]);

  const handleOnDragEnd = (result) => {
    let deleteRows = [],
      addRows = [],
      remainingDocTypes = [];

    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reordered = reorder(
      tempReorderDocTypes,
      result.source.index,
      result.destination.index,
    );

    const newDataWithUpdatedOrder = reordered.map((ele, index) => {
      ele.client_doc_type_id && deleteRows.push(ele.client_doc_type_id);

      ele.id &&
        addRows.push({
          client_id: clientId,
          doc_type_id: ele.id,
          is_checked: ele.is_checked,
          order: index,
        });

      !ele.id &&
        remainingDocTypes.push({
          name: ele,
          order: index,
        });

      return ele.name ? { ...ele, order: index } : { name: ele, order: index };
    });

    setTempReorderDocTypes(newDataWithUpdatedOrder);
    setTempToSubmitOrderData({
      deleteRows: deleteRows,
      addRows: addRows,
      remainingDocTypes: remainingDocTypes,
    });
  };

  const handleSubmitReorderDocType = () => {
    try {
      setTempToSubmitOrderData(() => {
        tempToSubmitOrderData.addRows =
          tempToSubmitOrderData &&
          tempToSubmitOrderData.addRows &&
          tempToSubmitOrderData.addRows.map((ele) => {
            if (
              !formContext.value.docTypes.hasOwnProperty(`${ele.doc_type_id}`)
            ) {
              return {
                ...ele,
              };
            }

            return {
              ...ele,
              is_checked: formContext.value.docTypes[ele.doc_type_id],
            };
          });

        return tempToSubmitOrderData;
      });
      clientApi.reorderClientDocType.index(
        'editClient',
        props.client.id,
        tempToSubmitOrderData,
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='client-settings-tab'>
      <div className='client-information-section'>
        <div className='client-settings-tab-save'>
          <section>
            <label>Update Client</label>
          </section>
          <ActionButtonsWithScrollFix
            primary='Save'
            spacer={false}
            onClick={(e) => submitHandler(formContext, e, props.handleSubmit)}
          />
        </div>
        <div
          className='client-information-section-header'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() =>
            setClientInformationCollapse(!clientInformationCollapse)
          }
        >
          <h3> Client Information </h3>
          <i
            className={
              clientInformationCollapse
                ? 'fa fa-chevron-right'
                : 'fa fa-chevron-down'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        <br />
        <div
          className={
            clientInformationCollapse
              ? 'client-settings-section-hidden'
              : 'client-settings-section-visible'
          }
        >
          <FormSchemaFields
            schema={getSchemaClientInformation(states)}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={getInitialData(props.client)}
          />
        </div>
      </div>
      <div className='findings-section'>
        <div
          className='findings-section-header'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => setInspectionCollapse(!inspectionCollapse)}
        >
          <h3> Inspections </h3>
          <i
            className={
              inspectionCollapse ? 'fa fa-chevron-right' : 'fa fa-chevron-down'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        <br />
        <div
          className={
            inspectionCollapse
              ? 'client-settings-section-hidden'
              : 'client-settings-section-visible'
          }
        >
          <FormSchemaFields
            schema={getSchemeCertificationBackdated(
              tenantHasFeature('Enable Photo Metadata Requirement'),
            )}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={getInitialData(props.client)}
          />
        </div>
      </div>
      <div className='findings-section'>
        <div
          className='findings-section-header'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => setFindingsCollapse(!findingsCollapse)}
        >
          <h3> Findings / Action Items </h3>
          <i
            className={
              findingsCollapse ? 'fa fa-chevron-right' : 'fa fa-chevron-down'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        <br />
        <div
          className={
            findingsCollapse
              ? 'client-settings-section-hidden'
              : 'client-settings-section-visible'
          }
        >
          <FormSchemaFields
            schema={getSchemaFindings(
              tenantHasFeature('Enable Enhanced Findings'),
              tenantHasFeature('Enable Photo Uploads'),
              tenantHasFeature('Enable Dissolving Finding Photos'),
            )}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={getInitialData(props.client)}
          />
        </div>
      </div>
      <div className='project-doc-section'>
        <div
          className='project-doc-section-header'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() => setProjectDocCollapse(!projectDocCollapse)}
        >
          <h3> Project Document Folders </h3>{' '}
          <i
            className={
              projectDocCollapse ? 'fa fa-chevron-right' : 'fa fa-chevron-down'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        <br />
        <div
          className={
            projectDocCollapse
              ? 'client-settings-section-hidden'
              : 'client-settings-section-visible'
          }
        >
          <FormSchemaFields
            schema={getSchemaProjectDoc(
              docTypes,
              projectTabs,
              document_date,
              setShowDeleteModal,
              setSelectedDocTypeId,
              setShowEditModal,
              setSelectedDocTypeName,
              setShowReorderModal,
              tempReorderDocTypes,
            )}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={getInitialData(
              props.client,
              docTypes,
              projectTabs,
            )}
          />
          <NewClientDocTypeFolder {...props} />
        </div>
      </div>
      <div className='precipitation-tracking-section'>
        <div
          className='precipitation-tracking-section-header'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={() =>
            setPrecipitationTrackingCollapse(!precipitationTrackingCollapse)
          }
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h3>Precipitation Tracking</h3>
            <FormSchemaFields
              schema={getSchemePrecipitation(
                tenantHasFeature('Precipitation Tracking'),
              )}
              onChange={formContext.set}
              formData={formContext.value}
              initialFormData={getInitialData(props.client)}
            />
          </div>
          {tenantHasFeature('Precipitation Tracking') && (
            <i
              className={
                precipitationTrackingCollapse
                  ? 'fa fa-chevron-right'
                  : 'fa fa-chevron-down'
              }
              aria-hidden='true'
            />
          )}
        </div>
        {!tenantHasFeature('Precipitation Tracking') ? (
          <section style={{ color: 'red', fontSize: '14px' }}>
            <p>
              “Precipitation Tracking is not included in this accounts license
              agreement.To turn on please contact your company's administrator.”
            </p>
          </section>
        ) : (
          <>
            <hr></hr>
            <br />
            <div
              className={
                precipitationTrackingCollapse
                  ? 'client-settings-section-hidden'
                  : 'client-settings-section-visible'
              }
            >
              {formContext.value.precipitation_client_project_flag ? (
                <CustomList data={allClientProjects} />
              ) : (
                <>There is no data to track</>
              )}
            </div>
          </>
        )}
      </div>
      {showReorderModal && (
        <Modal
          title='Organize Document Types'
          show={true}
          handleClose={() => {
            setShowReorderModal(false);
          }}
          handleSubmit={async () => {
            handleSubmitReorderDocType();
            setShowReorderModal(false);
          }}
          submitBtnText='Save'
          className='client-docTypes-reorder-modal'
        >
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='clientDocTypes'>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {tempReorderDocTypes.length &&
                    tempReorderDocTypes.map((ele, index) => {
                      return (
                        <Draggable
                          key={`${index}`}
                          draggableId={`${index}`}
                          index={index}
                        >
                          {(provide) => (
                            <div
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                              style={{
                                ...provide.draggableProps.style,
                                left: 'auto !important',
                                top: 'auto !important',
                                marginBottom: '5px',
                              }}
                            >
                              <span className='client-docTypes-reorder-modal__draggable-element'>
                                <FontAwesomeIcon
                                  icon='grip-lines'
                                  size='sm'
                                  color='#385980'
                                />
                                <span className='client-docTypes-reorder-modal__draggable-name'>
                                  {ele.name ? ele.name : ele}
                                </span>
                              </span>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Modal>
      )}

      {showEditModal && (
        <Modal
          title='Edit Name'
          show={true}
          handleClose={() => {
            setShowEditModal(false);
          }}
          handleSubmit={() => {
            editCustomDocType(
              selectedDocTypeId,
              setDeleteDocTypeError,
              setShowDeleteModal,
              docTypesRefetch,
              selectedDocTypeName,
            );
            setShowEditModal(false);
          }}
          submitBtnText='Edit'
        >
          <input
            type='text'
            value={selectedDocTypeName}
            onChange={(event) => setSelectedDocTypeName(event.target.value)}
          />
        </Modal>
      )}

      {showDeleteModal && (
        <ConfirmationModal
          title='Delete Folder'
          subTitle={
            deleteDocTypeError
              ? `${deleteDocTypeError}`
              : `The selected Folder will be deleted and removed from all Projects associated with this client. Are you sure you want to proceed?`
          }
          show={true}
          handleClose={() => {
            setShowDeleteModal(false);
            setDeleteDocTypeError('');
          }}
          handleConfirm={() =>
            deleteCustomDocType(
              selectedDocTypeId,
              setDeleteDocTypeError,
              setShowDeleteModal,
              docTypesRefetch,
            )
          }
          buttonText='Delete'
        />
      )}
    </div>
  );
}

function ClientSettingsTab(props) {
  const statesQuery =
    env.REACT_APP_REGION === 'UK' ? useCountries() : useStates();

  const defaultChecked = {};

  props.docTypes &&
    props.docTypes.length &&
    props.docTypes
      .filter((doc) => {
        if (doc.hasOwnProperty('is_checked')) {
          return doc.is_checked;
        }

        return true;
      })
      .forEach((doc) => {
        defaultChecked[doc.id] = true;
      });

  if (statesQuery.isLoading) return <Loading />;

  const filterTabData = () => {
    let projectTabData = {};

    props.projectTabs &&
      props.projectTabs.forEach((tab) => {
        projectTabData[tab.id] = tab.isChecked;
      });

    return projectTabData;
  };

  const getDocumentDate = () => {
    const data = {
      name: 'Disable Document Created/Updated date',
      id: '1',
      1: !props.document_date,
    };
    const defaultdata = {
      name: 'Disable Document Created/Updated date',
      id: '1',
      1: false,
    };

    return props.document_date === undefined ? defaultdata : data;
  };

  return (
    <FormContextProvider
      initialValue={{
        docTypes: defaultChecked,
        projectTabs: filterTabData(),
        document_date: getDocumentDate(),
      }}
    >
      <TooltipContextProvider>
        <ClientSettingsTabUI
          states={statesQuery.data}
          {...props}
          document_date={getDocumentDate()}
          docTypes={props.docTypes}
          projectTablis={props.projectTabs}
          docTypesRefetch={props.docTypesRefetch}
        />
      </TooltipContextProvider>
    </FormContextProvider>
  );
}

ClientSettingsTab.propTypes = {
  client: clientShape,
  handleSubmit: func.isRequired,
  docTypes: arrayOf(shape({ id: number, name: string })),
  projectTabs: arrayOf(shape({ id: number, name: string })),
  newDocTypes: array,
  setNewDocTypes: func,
  toSubmitOrderData: Array,
  setToSubmitOrderData: func,
};

export default ClientSettingsTab;
