import React, { useContext, useEffect, useState } from 'react';
import userApi from '../../../utils/api/user';
import { FormContext, SchemaFieldsWithContextUI } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import ToggleSmall from '../../Shared/Toggle/ToggleSmall';

const roleNameToManagerTitle: Record<string, string> = {
  Inspector: 'Managers',
  'Area / Assistant Manager': 'Regional Manager',
};

const tenantUserFormSchema = (
  roles: any[],
  divisions: any[],
  managers: any[],
  clients: any[],
) => {
  const sch: Record<string, any> = {};

  const formContext = useContext(FormContext);

  const selectedRole = roles?.find((v) => v.id === formContext.value.roleId);
  const selectedRoleName =
    selectedRole?.name ?? formContext.initialValue.roleName ?? null;

  sch.roleId = {
    label: 'Role',
    controlType: UIControlType.select,
    options: roles,
    labelKey: 'name',
    valueKey: 'id',
    validation: {
      required: true,
    },
    style: {
      flexBasis: '50%',
    },
    parse: (val: string) => Number.parseInt(val, 10),
  };

  sch.divisionIds = {
    label: 'Divisions',
    controlType: UIControlType.customTagSelect,
    labelKey: 'name',
    valueKey: 'id',
    isMulti: true,
    openOnFocus: true,
    options: divisions,
    noOptionMessage: 'No Divisions found',
    placeholder: 'Search for Divisions',
    validation: {
      required: true,
    },
    style: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  };

  if (selectedRoleName && roleNameToManagerTitle[selectedRoleName]) {
    sch.managerUserIds = {
      label: `Assign to ${roleNameToManagerTitle[selectedRoleName]}`,
      controlType: UIControlType.customTagSelect,
      options: managers,
      isMulti: true,
      openOnFocus: true,
      labelKey: 'name',
      valueKey: 'id',
      style: {
        flexBasis: '50%',
        maxWidth: '50%',
      },
    };

    sch.emptySpace = {
      controlType: UIControlType.plainText,
      style: {
        flexBasis: '50%',
        maxWidth: '50%',
      },
    };
  }

  sch.searchClient = {
    label: 'Search Client',
    controlType: UIControlType.text,
    style: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  };

  sch.clientIds = {
    label: `Add Clients`,
    controlType: UIControlType.customTagSelect,
    options: clients,
    isMulti: true,
    openOnFocus: true,
    labelKey: 'name',
    valueKey: 'id',
    style: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  };

  return sch;
};

const TenantUserFormUi: React.FC<any> = ({
  formOpts,
  multiTenantData,
  setMultiTenantData,
  tenantId,
}) => {
  const formContext = useContext(FormContext);

  useEffect(() => {
    setMultiTenantData((prev: any) => {
      if (prev[tenantId]) {
        prev[tenantId] = {
          ...prev[tenantId],
          divisionIds: formContext.value.divisionIds,
          roleId: formContext.value.roleId,
          managerUserIds: formContext.value.managerUserIds,
        };
      }

      return prev;
    });
  }, [formContext.value]);

  useEffect(() => {
    if (formContext.value.roleId && formOpts.roles) {
      const roleIdArr: number[] = [];

      formOpts.roles.forEach((ele: any) => {
        if (['Area / Assistant Manager', 'Inspector'].includes(ele.name)) {
          roleIdArr.push(ele.id);
        }
      });

      if (!roleIdArr.includes(formContext.value.roleId)) {
        formContext.set('managerUserIds', null);
      }
    }
  }, [formContext.value.roleId]);

  return (
    <SchemaFieldsWithContextUI
      schema={tenantUserFormSchema(
        formOpts.roles,
        formOpts.divisions,
        formOpts.managers || [],
        formOpts.clients,
      )}
      initialFormData={multiTenantData[tenantId] || {}}
    />
  );
};

const TenantUserTab: React.FC<any> = ({
  tenantId,
  userId,
  multiTenantData,
  setMultiTenantData,
}) => {
  const formContext = useContext(FormContext);

  const [formOpts, setFormOpts] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [deletedClientList, setDeletedClientList] = useState<any[]>([]);
  const [addedClientList, setAddedClientList] = useState<any>([]);
  const [maxPageLimit, setMaxPageLimit] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    userApi
      .getUserByTenant(userId, tenantId)
      .then(async (res) => {
        setFormOpts({
          ...formOpts,
          divisions: res.divisionsOpts,
          roles: res.rolesOpts,
        });

        setMultiTenantData((prev: any) => {
          if (!prev[tenantId]) {
            prev[tenantId] = {
              divisionIds: res.user.divisions,
              roleId: res.user.roleId,
              managerUserIds: res.user.managers,
              clientIds: res.user.clients,
            };

            formContext.set('divisionIds', res.user.divisions);
            formContext.set('roleId', res.user.roleId);
            formContext.set('clientIds', res.user.clients);
            if (res.user.managers.length)
              formContext.set('managerUserIds', res.user.managers);
          }

          return prev;
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // clean up function
    return () => {
      setFormOpts(null);
    };
  }, [tenantId]);

  useEffect(() => {
    if (
      multiTenantData[tenantId] &&
      formContext.value.clientIds &&
      formContext.value.clientIds.length - currentClientLength() > 0
    ) {
      const clientId = getNewClientsId(formContext.value.clientIds);
      setAddedClientList([...addedClientList, clientId]);
      let tenantData = multiTenantData[tenantId];
      if (!tenantData.clientIds.find((item: any) => item.id === clientId)) {
        tenantData.clientIds.push({
          id: clientId,
          isRemoved: false,
          auto_access_to_projects: false,
        });
      } else {
        tenantData.clientIds.forEach((client: any) => {
          if (client.id === clientId) {
            client.isRemoved = false;
            client.auto_access_to_projects =
              client.auto_access_to_projects ?? false;
          }
        });
      }

      setMultiTenantData((prev: any) => {
        if (prev[tenantId]) {
          prev[tenantId] = tenantData;
        }
        return prev;
      });
    }
  }, [formContext.value.clientIds]);

  useEffect(() => {
    if (formContext.value.divisionIds && formContext.value.roleId) {
      const divisionIds = formContext.value.divisionIds
        ? formContext.value.divisionIds.map((_: any) => _.id)
        : [];

      userApi
        .getCreateOpts({
          divisionIds: divisionIds,
          roleId: formContext.value.roleId,
          tenantId: tenantId,
        })
        .then((data) => {
          setFormOpts({
            ...formOpts,
            managers: data.managers,
            clients: data.clients,
          });

          formContext.setBusy(false);
        });
    }
  }, [formContext.value.divisionIds, formContext.value.roleId]);

  useEffect(() => {
    fetchUserClientProjects(formContext.value.searchClient);
  }, [
    formContext.value.searchClient,
    page,
    deletedClientList,
    addedClientList,
    formContext.value.clientIds
  ]);

  const fetchUserClientProjects = async (search = null) => {
    userApi
      .fetchUserClientProjects(
        userId,
        tenantId,
        page,
        search,
        addedClientList,
        deletedClientList,
      )
      .then((res: any) => {
        setTableData(res.clients);
        setMaxPageLimit(res.count);
      });
  };

  const currentClientLength = () => {
    return (
      multiTenantData[tenantId].clientIds.length -
      deletedClientList.length +
      addedClientList.length
    );
  };

  const getNewClientsId = (clientList: any) => {
    const deletedId = clientList.filter((client: any) =>
      deletedClientList.includes(client.id),
    );
    if (deletedId.length > 0) {
      const deletedListTemp = deletedClientList.filter(
        (item: any) => item !== deletedId[0].id,
      );
      setDeletedClientList(deletedListTemp);
      return deletedId[0].id;
    } else {
      const clientIdsSet = new Set(
        multiTenantData[tenantId]?.clientIds.map((client: any) => client.id),
      );
      return clientList
        .map((client: any) => client.id)
        .filter((id: any) => !clientIdsSet.has(id))[0];
    }
  };

  const ClientsView: React.FC<any> = () => {
    const [expandedView, setExpandedView] = useState<boolean[]>([]);

    const handleRowClick = (index: any) => {
      if (expandedView.includes(index)) {
        const filterState = expandedView.filter((item) => item !== index);

        setExpandedView(filterState);
      } else {
        setExpandedView([...expandedView, index]);
      }
    };

    const deleteClient = (clientObj: any) => {
      setDeletedClientList([...deletedClientList, clientObj.id]);
      let clientIdsForm = formContext.value.clientIds;
      formContext.set(
        'clientIds',
        clientIdsForm.filter((item: any) => item.id !== clientObj.id),
      );

      let tenantData = multiTenantData[tenantId];
      tenantData.clientIds.forEach((client: any) => {
        if (client.id === clientObj.id) {
          client.isRemoved = true;
        }
      });

      setMultiTenantData((prev: any) => {
        if (prev[tenantId]) {
          prev[tenantId] = tenantData;
        }
        return prev;
      });

      clientObj.projects.map((project: any) => {
        if (project.archived_at === null) {
          handleProjectAccess(project.id, { target: { checked: false } });
        }
      });
    };

    const handleClientAutoAccess = (clientObj: any, event: any) => {
      let tenantData = multiTenantData[tenantId];
      tenantData.clientIds.forEach((client: any) => {
        if (client.id === clientObj.id) {
          client.auto_access_to_projects = event.target.checked;
        }
      });

      setMultiTenantData((prev: any) => {
        if (prev[tenantId]) {
          prev[tenantId] = tenantData;
        }
        return prev;
      });

      if (event.target.checked)
        clientObj.projects.map((project: any) => {
          if (project.archived_at === null) {
            handleProjectAccess(project.id, { target: { checked: true } });
          }
        });
    };

    const handleProjectAccess = (id: any, event: any) => {
      let tenantData = multiTenantData[tenantId];
      if (!tenantData.projectIds) {
        tenantData.projectIds = [];
      }
      if (!tenantData.projectIds.find((item: any) => item.id === id)) {
        tenantData.projectIds.push({
          id: id,
          hasAccess: event.target.checked,
        });
      } else {
        tenantData.projectIds.forEach((item: any) => {
          if (item.id === id) {
            item.hasAccess = event.target.checked;
          }
        });
      }

      setMultiTenantData((prev: any) => {
        if (prev[tenantId]) {
          prev[tenantId] = tenantData;
        }
        return prev;
      });
    };

    if (tableData && tableData.length === 0) {
      return (
        <div className='edit-user-clients-table__no-clients'>
          No Clients to be displayed
        </div>
      );
    }
    return (
      <div className='edit-user-clients-table__body'>
        {tableData.map((client: any, index: any) => {
          return (
            <div
              key={index}
              className='edit-user-clients-row'
              onClick={() => handleRowClick(index)}
            >
              <div className='edit-user-clients-row__header'>
                <div>{client.name}</div>
                <div className='edit-user-clients-row__header__icons'>
                  <FontAwesomeIcon
                    style={{ marginRight: '10px' }}
                    icon={faTrash}
                    className='caret'
                    onClick={() => deleteClient(client)}
                  />
                  <FontAwesomeIcon
                    icon={
                      expandedView.includes(index) ? faAngleUp : faAngleDown
                    }
                    className='caret'
                  />
                </div>
              </div>
              <div
                className='edit-user-clients-row__body'
                style={{
                  display: expandedView.includes(index) ? 'block' : 'none',
                }}
              >
                {client.projects.length > 0 ? (
                  <>
                    <div className='edit-user-clients-row__body__auto-access'>
                      <ToggleSmall
                        handleChange={(event: any) => {
                          handleClientAutoAccess(client, event);
                        }}
                        isChecked={
                          multiTenantData[tenantId]?.clientIds?.find(
                            (item: any) => item.id === client.id,
                          )?.auto_access_to_projects ??
                          client.client_user_clients.auto_access_to_projects
                        }
                      />
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        Auto Access to All Projects
                      </span>
                    </div>
                    <div className='edit-user-clients-project__header'>
                      <div>Project Name</div>
                      <div>Division</div>
                      <div>Access to project</div>
                      <div>Access to statistics</div>
                    </div>
                    {client.projects
                      .filter((project: any) => project.archived_at === null)
                      .map((project: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className='edit-user-clients-project__body'
                          >
                            <div>{project.name}</div>
                            <div>{client.divisions[0].name}</div>
                            <div>
                              <ToggleSmall
                                handleChange={(event: any) => {
                                  handleProjectAccess(project.id, event);
                                }}
                                isChecked={
                                  multiTenantData[tenantId]?.projectIds?.find(
                                    (item: any) => item.id === project.id,
                                  )?.hasAccess ??
                                  project.project_user_projects.length > 0
                                }
                              />
                            </div>
                            <div>
                              <ToggleSmall
                                disabled
                                isChecked={
                                  project.project_user_projects.length > 0
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    {client.projects.filter(
                      (project: any) => project.archived_at !== null,
                    ).length > 0 && (
                      <>
                        <div className='edit-user-clients-project__archived'>
                          Archived Projects
                        </div>
                        {client.projects
                          .filter(
                            (project: any) => project.archived_at !== null,
                          )
                          .map((project: any, index: any) => {
                            return (
                              <div
                                key={index}
                                className='edit-user-clients-project__body'
                              >
                                <div>{project.name}</div>
                                <div>{client.divisions[0].name}</div>
                                <div>
                                  <ToggleSmall
                                    handleChange={(event: any) => {
                                      handleProjectAccess(project.id, event);
                                    }}
                                    isChecked={
                                      multiTenantData[
                                        tenantId
                                      ]?.projectIds?.find(
                                        (item: any) => item.id === project.id,
                                      )?.hasAccess ??
                                      project.project_user_projects.length > 0
                                    }
                                  />
                                </div>
                                <div>
                                  <ToggleSmall
                                    disabled
                                    isChecked={
                                      project.project_user_projects.length > 0
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </>
                ) : (
                  <div className='edit-user-clients-row__body__no-projects'>
                    No Projects to Display
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <Pagination
          activePage={page}
          itemsCountPerPage={7}
          totalItemsCount={maxPageLimit}
          pageRangeDisplayed={3}
          onChange={setPage}
        />
      </div>
    );
  };

  return (
    <div>
      {formOpts && formOpts.divisions.length && (
        <React.Fragment>
          <div
            style={{
              margin: '0.5rem',
            }}
          >
            <TenantUserFormUi
              tenantId={tenantId}
              formOpts={formOpts}
              multiTenantData={multiTenantData}
              setMultiTenantData={setMultiTenantData}
            />
          </div>
          <section className='edit-user-clients-table'>
            <div className='edit-user-clients-table__header'>
              <div>Client Name</div>
              <div>Action</div>
            </div>
            <ClientsView />
          </section>
        </React.Fragment>
      )}
    </div>
  );
};

export default TenantUserTab;
