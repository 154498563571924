import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';

import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    // TableFooter,
    Box,
    Typography,
    Checkbox,
    Card,
    Button,
    Grid,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle as faCheckCircleRegular } from '@fortawesome/free-regular-svg-icons';
import {
    faCheckCircle as faCheckCircleSolid,
    faExternalLinkAlt,
    faSort,
    faSortUp,
    faSortDown,
    // faEllipsisV,

} from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import _ from 'lodash';


// import { ProjectContext } from '../../../contexts/ProjectContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import { dateFormat } from "../../../../utils";
import Loading from "../../../Shared/ResourceIndex/Loading";
import { addFindingCount } from '../../../../hooks/inspection';
import BulkCertifyModal from '../../../Projects/Details/BulkCertifyModal';
import SuccessNotification from '../../../Shared/SuccessNotification/SuccessNotification';
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover';
import { findingsNotRequiredForCompliance } from "../../../Projects/Details/Inspections";
import InspectionCert from './InspectionCert';
import AcknowledgementCert from './AcknowledgementCert';
import { RolesContext } from '../../../../contexts/RolesContext';


const CertificationsDue = (props: any) => { // props:Props

    const dashboardStore = useContext(DashboardContext);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isDataLaoding, setIsDataLoading] = React.useState<boolean>(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [certDueList, setCertDueList] = useState<any[]>([]);
    const [selectedCerts, setSelectedCerts] = useState<Array<number>>([]);
    const [filterColumns, setFilterColumns] = useState<any>({});
    const [showCertifyModal, setShowCertifyModal] = useState<boolean>(false);
    const [showInspectionCertifyModal, setShowInspectionCertifyModal] = useState<boolean>(false);
    const [showAcknowledgementCertifyModal, setShowAcknowledgementCertifyModal] = useState<boolean>(false);
    const [inspectionSelectedCerts, setInspectionSelectedCerts] = useState<Array<number>>([]);
    const [acknowledgementSelectedCerts, setAcknowledgementSelectedCerts] = useState<Array<number>>([]);
    const [showSuccess, setSuccessVis] = useState<boolean>(false);
    const searchInput = useRef<any>("");
    const [, setSelectValue] = useState<any>()
    const rolesStore = useContext(RolesContext);

    // icons holder
    const [siteNameIcon, setSiteNameIcon] = useState<any>(faSort);
    const [clientNameIcon, setClientNameIcon] = useState<any>(faSort);
    const [dateOfInspectionIcon, setDateOfInspectionIcon] = useState<any>(faSort);

    let certificationTypes: any = []

    rolesStore.userHasPermission('all', 'Compliance Cert from Widget') && certificationTypes.push(
        {
            id: 1,
            name: "Compliance Certification"
        }
    )

    rolesStore.userHasPermission('all', 'Inspection Cert from Widget') && certificationTypes.push(
        {
            id: 2,
            name: "Inspection Certification",
        }
    )

    rolesStore.userHasPermission('all', 'Acknowledgement Cert from Widget') && certificationTypes.push(
        {
            id: 3,
            name: "Acknowledgement Certification"
        }
    )

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSaveAnimation = useCallback(() => {
        // setSelectedInspections([]);
        window.setTimeout(() => setSuccessVis(false), 2000);

        return setSuccessVis(true);
    }, [showSuccess]);

    const setCertResult = (data: any) => {
        if (data.data) {
            setIsDataLoading(true);
            let tempResultArray: Array<any> = [];

            (data.data.projects.length > 0) && data.data.projects.map((project: any) => {
                project.inspections.map((inspection: any) => {
                    const tempInsp = addFindingCount(inspection);
                    const tempResult = {
                        siteName: project.name,
                        clientName: project.client.name,
                        dateOfInspection: tempInsp.created_date,
                        has_compliance_cert: inspection.inspection_template.has_compliance_cert,
                        findingsNotRequiredForCompliance: findingsNotRequiredForCompliance(inspection.inspection_template, inspection.findings, inspection.findingDetails),
                        ...tempInsp
                    }

                    tempResultArray.push(tempResult);
                });
            });
            if (tempResultArray.length > 0) {
                tempResultArray = tempResultArray.filter((elm) => (elm.has_compliance_cert && elm.findingsNotRequiredForCompliance));
            }
            setCertDueList(tempResultArray);
            setIsDataLoading(false);
        } else {
            setIsDataLoading(true);
        }
    }

    const changeCheckboxSelection = (id: number, currentState: boolean): void => {

        let tempSelectionArr: any = [...selectedCerts];

        if (currentState == false && tempSelectionArr.includes(id)) {
            tempSelectionArr = tempSelectionArr.filter((e: number) => e !== id);
        } else {
            tempSelectionArr.push(id);
        }

        setSelectedCerts(tempSelectionArr);

    }

    const handleSelectAll = () => {
        if (certDueList.length !== selectedCerts.length) {
            const temp_selected_certs = certDueList.map((elm) => {
                return elm.id
            });

            setSelectedCerts(temp_selected_certs);
        } else {
            setSelectedCerts([]);
        }
    }

    const handleSort = (columnName: string) => {

        let tempFilterColumns: any = { ...filterColumns };

        if (Object.keys(tempFilterColumns)[0] !== columnName) {
            tempFilterColumns = {};
        }

        if (!tempFilterColumns[columnName]) {
            tempFilterColumns[columnName] = "desc";
        } else if (tempFilterColumns[columnName] && tempFilterColumns[columnName] === "desc") {
            tempFilterColumns[columnName] = "asc";
        } else {
            if (tempFilterColumns[columnName]) { delete tempFilterColumns[columnName] };
        }

        setFilterColumns(tempFilterColumns);

    }

    const handleSearchChange = _.debounce((e: any) => {
        // setSelectedCerts([]);
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 1) {
            dashboardStore.setinspectionsCertDueQueryParams({ s: e.target.value.trim() });
        }
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 2) {
            dashboardStore.setSearchQueryForInspectionCert({ searchQuery: e.target.value.trim() });
        }
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 3) {
            dashboardStore.setSearchQueryForAcknowledgementCert({ searchQuery: e.target.value.trim() });
        }
    }, 500);

    const updateSiteNameIcon = (order: string) => {
        // handleSaveAnimation();
        if (order == "desc") {
            setSiteNameIcon(faSortDown);
        } else if (order == "asc") {
            setSiteNameIcon(faSortUp);
        } else {
            setSiteNameIcon(faSort);
        }
        setClientNameIcon(faSort);
        setDateOfInspectionIcon(faSort);
    }

    const updateClientNameIcon = (order: string) => {
        setSiteNameIcon(faSort);
        if (order == "desc") {
            setClientNameIcon(faSortDown);
        } else if (order == "asc") {
            setClientNameIcon(faSortUp);
        } else {
            setClientNameIcon(faSort);
        }
        setDateOfInspectionIcon(faSort);
    }

    const updateDateOfInspectionIcon = (order: string) => {
        setSiteNameIcon(faSort)
        setClientNameIcon(faSort);
        if (order == "desc") {
            setDateOfInspectionIcon(faSortDown);
        } else if (order == "asc") {
            setDateOfInspectionIcon(faSortUp);
        } else {
            setDateOfInspectionIcon(faSort);
        }
    }

    const certifyNowButtonHandler = () => {
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 1) {
            return (selectedCerts && selectedCerts.length > 0) ? setShowCertifyModal(true) : false;
        }
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 2) {
            return (inspectionSelectedCerts && inspectionSelectedCerts.length > 0) ? setShowInspectionCertifyModal(true) : false;
        }
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 3) {
            return (acknowledgementSelectedCerts && acknowledgementSelectedCerts.length > 0) ? setShowAcknowledgementCertifyModal(true) : false;
        }
    }

    const selectedCount = () => {
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 1) {
            return (
                (selectedCerts && selectedCerts.length > 0) ?
                    <Typography component={'span'} variant="subtitle2" display="block" gutterBottom > {selectedCerts.length} Item{(selectedCerts.length > 1) ? `'s` : ''} </Typography>
                    :
                    <></>
            )
        }
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 2) {
            return (
                (inspectionSelectedCerts && inspectionSelectedCerts.length > 0) ?
                    <Typography component={'span'} variant="subtitle2" display="block" gutterBottom > {inspectionSelectedCerts.length} Item{(inspectionSelectedCerts.length > 1) ? `'s` : ''} </Typography>
                    :
                    <></>
            )
        }
        if (Number(localStorage.getItem('certificationDueWidgetPreference')) === 3) {
            return (
                (acknowledgementSelectedCerts && acknowledgementSelectedCerts.length > 0) ?
                    <Typography component={'span'} variant="subtitle2" display="block" gutterBottom > {acknowledgementSelectedCerts.length} Item{(acknowledgementSelectedCerts.length > 1) ? `'s` : ''} </Typography>
                    :
                    <></>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    useEffect(() => {
        // setIsDataLoading(dashboardStore.inspectionsCertDue.isLoading)
        setIsDataLoading(true);
        setCertResult(dashboardStore.inspectionsCertDue.data); // we can get query status here by removing .data as well like Loading, Success
    }, [dashboardStore.inspectionsCertDue]);

    useEffect(() => {
        // dashboardStore.setinspectionsCertDueQueryParams({ pageOffset: count.current, sort: filterColumns });
        const temp = [...certDueList];

        let newSortOrderList = [];

        if (Object.keys(filterColumns).includes("siteName")) {
            newSortOrderList = _.orderBy(temp, [(o) => o.siteName.toLowerCase()], [filterColumns.siteName]);
            updateSiteNameIcon(filterColumns.siteName);
        } else if (Object.keys(filterColumns).includes("clientName")) {
            newSortOrderList = _.orderBy(temp, [(o) => o.clientName.toLowerCase()], [filterColumns.clientName]);
            updateClientNameIcon(filterColumns.clientName);
        } else if (Object.keys(filterColumns).includes("dateOfInspection")) {
            newSortOrderList = _.orderBy(temp, [(o) => o.dateOfInspection], [filterColumns.dateOfInspection]);
            updateDateOfInspectionIcon(filterColumns.dateOfInspection);
        } else {
            newSortOrderList = _.orderBy(temp, [(o) => o.id], ["desc"]);
            updateSiteNameIcon("");
        }
        setCertDueList(newSortOrderList);
    }, [filterColumns]);

    useEffect(() => {
        if (dashboardStore.inspectionsCertDueQueryParams && dashboardStore.inspectionsCertDueQueryParams.s) {
            searchInput.current.value = dashboardStore.inspectionsCertDueQueryParams.s;
        }
        
        if(localStorage.getItem('certificationDueWidgetPreference') === null){
            localStorage.setItem('certificationDueWidgetPreference', '1')
        }
    }, []);

    return (
        <section className='certification-due-widget-section'>
            <Box sx={{ flexGrow: 1 }} className={'widget-toolbar'}>
                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <Typography sx={{ fontSize: '22px' }} component={'span'} className={`widget-headline`} variant="h6" gutterBottom >
                            Certifications Due
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {/* <Button aria-describedby={id} variant="contained"
                            onClick={handleClick}
                            className={`widget-popover-btn`} >
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </Button> */}
                        <EllipsisPopover handleCloseFunc={handleClose} id={id} open={open} anchorEl={anchorEl} />
                    </Grid>
                    {/* <Grid item md={5} className={`mobile-hidden`}> <></> </Grid> */}
                    <Grid container style={{ paddingTop: '10px' }} className='certification-due-widget-section__bars'>
                        <select 
                            name="certification-type"
                            onChange={(event) => {
                                setSelectValue(Number(event.target.value))
                                localStorage.setItem('certificationDueWidgetPreference', `${event.target.value}`)
                            }}
                        >
                            {
                                certificationTypes.length ? certificationTypes.map((ele:any, index: any) => {

                                return(
                                        Number(localStorage.getItem('certificationDueWidgetPreference')) === ele.id ? 
                                            <option value={ele.id} key={index} defaultChecked>{ele.name}</option> :
                                            <option value={ele.id} key={index}>{ele.name}</option>
                                                                               
                                )
                                }):
                                <option value="-10" disabled>No Type Found</option>
                            }
                        </select>

                        <input type="text" placeholder="Search" className='widget-search-input' ref={searchInput} onChange={handleSearchChange} />
                    </Grid>
                </Grid>
            </Box>

            <div
                data-rbd-drag-handle-context-id={props.providedProps}
                data-rbd-drag-handle-draggable-id="gibberish"
                style={{
                    // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
                    cursor: "auto"
                }}
            >
                <Card className='widget-container'>
                    <Box sx={{ width: '100%', overflow: 'hidden' }} >
                        <TableContainer sx={{ height: 403 }} >
                            {Number(localStorage.getItem('certificationDueWidgetPreference')) === 3 && rolesStore.userHasPermission('all', 'Acknowledgement Cert from Widget') &&
                                <AcknowledgementCert
                                    showAcknowledgementCertifyModal={showAcknowledgementCertifyModal}
                                    setShowAcknowledgementCertifyModal={setShowAcknowledgementCertifyModal}
                                    acknowledgementSelectedCerts={acknowledgementSelectedCerts}
                                    setAcknowledgementSelectedCerts={setAcknowledgementSelectedCerts}
                                />
                            }

                            {Number(localStorage.getItem('certificationDueWidgetPreference')) === 2 && rolesStore.userHasPermission('all', 'Inspection Cert from Widget') &&
                                <InspectionCert
                                    showInspectionCertifyModal={showInspectionCertifyModal}
                                    setShowInspectionCertifyModal={setShowInspectionCertifyModal}
                                    inspectionSelectedCerts={inspectionSelectedCerts}
                                    setInspectionSelectedCerts={setInspectionSelectedCerts}
                                />
                            }

                            {Number(localStorage.getItem('certificationDueWidgetPreference')) === 1 && rolesStore.userHasPermission('all', 'Compliance Cert from Widget') &&
                                <Table stickyHeader aria-label="sticky table" size='small'>
                                    <TableHead className='widget-head'>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    icon={<FontAwesomeIcon icon={faCheckCircleRegular} size={'lg'} className={'checkboxDeactive'} />}
                                                    checkedIcon={<FontAwesomeIcon icon={faCheckCircleSolid} size={'lg'} className={'checkboxActive'} />}
                                                    checked={certDueList.length === selectedCerts.length && selectedCerts.length !== 0}
                                                    onChange={() => { return handleSelectAll() }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    value={certDueList.length === selectedCerts.length}
                                                />
                                            </TableCell>
                                            <TableCell className='table-fix-th-1'>
                                                <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("siteName") }} >
                                                    Site Name <FontAwesomeIcon icon={siteNameIcon} size={'sm'} className={''} />
                                                </Typography>
                                            </TableCell>
                                            <TableCell className='table-fix-th-2'>
                                                <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("clientName") }} >
                                                    Client Name <FontAwesomeIcon icon={clientNameIcon} size={'sm'} className={''} />
                                                </Typography>
                                            </TableCell>
                                            <TableCell className='table-fix-th'>
                                                <Typography component={'span'} variant="body1" fontWeight={'bold'} gutterBottom onClick={() => { handleSort("dateOfInspection") }} >
                                                    Date Of Inspection <FontAwesomeIcon icon={dateOfInspectionIcon} size={'sm'} className={''} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className='widget-body'>

                                        {
                                            (certDueList.length > 0) &&
                                            certDueList.map((elm) => {

                                                const isChecked = selectedCerts.includes(elm.id);

                                                return (
                                                    <TableRow
                                                        hover
                                                        selected={isChecked}
                                                        key={elm.id}
                                                        className='widget-body-data'
                                                    >
                                                        <TableCell padding="checkbox" sx={{ borderBottom: "none" }}>
                                                            <Checkbox
                                                                icon={<FontAwesomeIcon icon={faCheckCircleRegular} size={'lg'} className={'checkboxDeactive'} />}
                                                                checkedIcon={<FontAwesomeIcon icon={faCheckCircleSolid} size={'lg'} className={'checkboxActive'} />}
                                                                checked={isChecked}
                                                                onChange={() => { return changeCheckboxSelection(elm.id, !isChecked) }}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                value={isChecked}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: "none" }} className='table-fix-td-1'>
                                                            <Typography component={'span'} variant="body1" className='cell-elm'>{elm.siteName}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: "none" }} className='table-fix-td-2'>
                                                            <Typography component={'span'} variant="body1" className='cell-elm'>{elm.clientName}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: "none" }} className='table-fix-td'>
                                                            <Typography component={'span'} variant="body1" className='cell-elm'>{moment(elm.dateOfInspection).format(dateFormat)}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })

                                        }

                                        {
                                            (!isDataLaoding && certDueList.length == 0) &&
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: "none", textAlign: "center" }} className={`table-fix-td`} colSpan={4}>
                                                    <Typography component={'span'} variant="body1" className='cell-elm'>There are no inspection(s) that are eligible to be certified</Typography>
                                                </TableCell>
                                            </TableRow>
                                        }

                                        {
                                            (isDataLaoding) &&
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: "none", textAlign: "center" }} className={`table-fix-td`} colSpan={4}>
                                                    <Loading />
                                                </TableCell>
                                            </TableRow>
                                        }

                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} className={'widget-footer'}>
                        <Grid container spacing={2}>
                            <Grid item xs={8} md={7} className={`left-footer-container`}>
                                <Button variant="contained" color="success" className='certify-now-btn' onClick={certifyNowButtonHandler}>Certify Now</Button>
                                {
                                    selectedCount()
                                }
                            </Grid>
                            <Grid item xs={4} md={5} className='showmore-btn-wrapper'>
                                <Button onClick={props.changeShowMore}> Show {(props.mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} size={'lg'} className={'external-link-icon'} /></Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </div>

            {showCertifyModal && (
                <BulkCertifyModal
                    hideModal={() => setShowCertifyModal(false)}
                    inspections={certDueList.filter(
                        (inspection: { id: any }) =>
                            selectedCerts.includes(inspection.id),
                    )}
                    handleSaveAnimation={handleSaveAnimation}
                    onSubmit={() => dashboardStore.inspectionsCertDue.refetch()}
                    fromWidget={true}
                />
            )}

            <SuccessNotification show={showSuccess} />
        </section>
    )
}

export default CertificationsDue
