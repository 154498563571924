import React, {
  MouseEventHandler,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useQuestion } from '../../../hooks/question';
import { useQuestionConfigValues } from '../../../hooks/questionConfigValue';
import Loading from '../../Shared/ResourceIndex/Loading';
import QuestionDetailForm from './Forms/QuestionDetailForm';
import InspectionQuestionContext from '../../../contexts/InspectionQuestionContext';
import { Question } from '@sw-sw/lib-inspection-templates';
import { Modal } from '@sw-sw/lib-ui';

export interface IEditQuestionProps {
  questionId: number;
  onCancel: MouseEventHandler;
}

const EditQuestion: React.FC<IEditQuestionProps> = ({
  questionId,
  onCancel,
}) => {
  const inspectionQuestionContext = useContext(InspectionQuestionContext);
  const questionQuery = useQuestion(questionId);
  const questionValuesQuery = useQuestionConfigValues(questionId);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUsedTemplate, setIsUsedTemplate] = useState<boolean>(false);
  const [showWarningAndQuestion, setShowWarningAndQuestion] =
    useState<any>(null);

  useEffect(() => {
    inspectionQuestionContext.getIsUsedTemplate(questionId).then((data) => {
      setIsUsedTemplate(data);
    });
  }, [questionId]);

  if (
    questionQuery.isLoading ||
    questionValuesQuery.isLoading ||
    !questionQuery.data
  ) {
    return <Loading />;
  }

  const handleSubmit = async (
    question: Question & {
      id: number;
      force_update: boolean;
      acceptedWarning?: boolean;
      changed_from_base_question_id?: number;
    },
  ) => {
    setLoading(true);

    try {
      if (!isUsedTemplate || question.acceptedWarning) {
        await inspectionQuestionContext.updateQuestion({
          ...question,
        });
        await questionQuery.refetch();
        await questionValuesQuery.refetch().then((e: any) => onCancel(e));
      } else if (question.force_update) setShowWarningAndQuestion(question);
      else {
        await inspectionQuestionContext.deleteQuestion(questionId);
        await inspectionQuestionContext.addQuestion({
          ...question,
          id: undefined,
          createdAt: undefined,
          updatedAt: undefined,
          changed_from_base_question_id: question.changed_from_base_question_id ? question.changed_from_base_question_id : questionId,
        });
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <QuestionDetailForm
        question={questionQuery.data}
        questionValues={questionValuesQuery.data}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        order={questionQuery.data.sort_order}
        isUsedTemplate={isUsedTemplate}
      />
      <Modal
        show={showWarningAndQuestion !== null}
        title='Warning'
        subTitle='Changes made to this question will impact all current and previously submitted inspection forms. If the question type has been changed lose of question answers may also occur on those inspection forms. This action cannot be undone. Are you sure you would like to proceed?'
        handleClose={() => setShowWarningAndQuestion(null)}
        submitBtnText='Ok'
        handleSubmit={() =>
          handleSubmit({
            ...showWarningAndQuestion,
            force_update: true,
            acceptedWarning: true,
          })
        }
      />
    </>
  );
};

export default EditQuestion;
