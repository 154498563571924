import { useState, useEffect, useContext } from 'react';
import { pick, isEqual, kebabCase } from 'lodash';
import userApi from '../../../utils/api/user';
import { Modal, TooltipContextProvider } from '@sw-sw/lib-ui';
import Form1 from '../Forms/UserStepOne';
import { useUserFormData } from '../../../hooks/userFormData';
import AppContext from '../../../contexts/AppContext';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { FormContext, FormContextProvider } from '@sw-sw/lib-form';
import xhrService from '../../../utils/api';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { Redirect } from 'react-router';
import { cloneDeep } from 'lodash';
import Permissions from './Permissions';
import { LoadingIcon } from '@sw-sw/lib-ui';
import TenantUserTab from './TenantUserTab';
import NavTabs from '../../Shared/NavTabs';
import { ActionButtons } from '../../Shared/ActionButtons';
import AddUserModal from '../AddUser/AddUserModal';
import { useHistory } from 'react-router-dom';

export const EditUserPage = () => {
  const [userData, setUserData] = useState<any>(null);
  const [redirect, setRedirect] = useState(false);
  const [userId, setUserId] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [projectList, setProjectList] = useState<any>([]);
  const [showDeleteTenantModal, setShowDeleteTenantModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [initialValue, setInitialValue] = useState<any>({ tags: [] });
  const [loader, setLoader] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [showAddTenantModal, setShowAddTenantModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const [tabs, setTabs] = useState<any>(null);
  const [secondaryActions, setSecondaryActions] = useState<any>([]);
  const [multiTenantData, setMultiTenantData] = useState<any>({});
  const [errMsg, setErrMsg] = useState('');

  const appStore = useContext(AppContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const { getPath } = useContext(AppDivisionContext);
  const formDataQuery = useUserFormData(appDivisionContext.appDivisionId || 0);
  let history = useHistory();

  useEffect(() => {
    const userIdArr = window.location.pathname.split('/');

    setSecondaryActions((prevActions: any) => [
      ...prevActions,
      {
        icon: 'gears',
        label: 'Permissions',
        onClick: () => setShowPermissions(true),
      },
    ]);

    if (
      ['Admin', 'Super Admin', 'Client Manager'].includes(
        appStore.get('user').roleName,
      ) &&
      Number(appStore.get('user').id) !== Number(userIdArr[4])
    ) {
      setSecondaryActions((prevActions: any) => [
        ...prevActions,
        {
          icon: 'trash',
          label: 'Delete User',
          onClick: () => setShowDeleteModal(true),
        },
      ]);
    }

    if (appStore.get('user').roleName === 'Super Admin') {
      setSecondaryActions((prevActions: any) => [
        ...prevActions,
        {
          icon: 'plus',
          label: 'Add Tenant',
          onClick: () => setShowAddTenantModal(true),
        },
      ]);
    }

    return () => setSecondaryActions([]);
  }, []);

  useEffect(() => {
    const userIdPath = window.location.pathname.split('/')[4];

    setUserId(userIdPath);
    userApi.getTenantsAndNotifs(userIdPath).then(async (res) => {
      setUserData(res);
      let tabsData =
        res.tenants.map((tenant: any) => {
          var Tab = () => (
            <FormContextProvider key={tenant.id}>
              <TenantUserTab
                tenantId={tenant.id}
                userId={userIdPath}
                multiTenantData={multiTenantData}
                setMultiTenantData={setMultiTenantData}
              />
            </FormContextProvider>
          );

          if (!Tab) {
            Tab = () => <p>no tab</p>;
          }

          return {
            Component: Tab,
            label: tenant.name,
            to: `/${kebabCase(tenant.name)}`,
            id: tenant.id,
            showTab: () => true,
          };
        }) || [];

      //move current tenant to first tab
      const currentTenant = tabsData.findIndex(
        (tab: any) => tab.id === res.userTenantRoles[0].tenant_id,
      );

      if (currentTenant !== -1) {
        const currentTabData = tabsData.splice(currentTenant, 1);

        tabsData.unshift(currentTabData[0]);
      }
      setTabs(tabsData);

      setInitialValue(extractInitialValue(res));
      setRefetch(false);
    });
  }, [refetch]);

  const extractInitialValue = (user: any) => {
    const keys = [
      'id',
      'first_name',
      'last_name',
      'email',
      'address.phone',
      'address.phone_ext',
      'position',
      'ccrNumber',
      'peNumber',
    ];

    const result: any = {
      ...pick(user, keys),
    };

    return result;
  };

  const CTA = ({ showDeleteTenant }: any) => {
    const formContext = useContext(FormContext);
    const handleBack = () => {
      const obj = cloneDeep(formContext.value);

      delete obj.tags;
      if (isEqual(formContext.initialValue, obj)) {
        setRedirect(true);
      } else {
        setShowAlert(true);
      }
    };

    const handleCreateSubmit = () => {
      setLoader(true);

      (tabs || []).forEach((tab: any) => {
        if (!multiTenantData[tab.id]) {
          multiTenantData[tab.id] = {};
        }
      });
      userApi
        .update(userId, {
          ...formContext.value,
          multiTenantData,
        })
        .then(() => {
          appStore.triggerSuccessNotification();
          setLoader(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    };

    return (
      <div className='cta-outer-wrapper'>
        <div className='cta-inner-wrapper'>
          <button className='primary-outline' onClick={handleBack}>
            Back
          </button>
          <button className='primary' onClick={() => handleCreateSubmit()}>
            Save
          </button>

          {showDeleteTenant && (
            <button
              className='warn reversed'
              onClick={() => {
                setErrMsg('');
                setShowDeleteTenantModal(true);
              }}
            >
              Delete Tenant
            </button>
          )}
          {loader && <LoadingIcon />}
        </div>
      </div>
    );
  };

  return (
    <TooltipContextProvider>
      <FormContextProvider initialValue={initialValue}>
        {!formDataQuery.loading && (
          <section>
            <div
              style={{
                height: '60px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3>Edit User</h3>
              {[
                'Admin',
                'Super Admin',
                'Client Manager',
                'Area Manager',
                'Area / Assistant Manager',
                'Inspector',
              ].includes(appStore.get('user').roleName) && (
                <ActionButtons secondary={secondaryActions} />
              )}
            </div>
            <Form1
              className={'edit-user-form-section'}
              showEditRole={true}
              isEdit={true}
              isProfile={false}
              isMyProfile={false}
              adminTab
            />
            {redirect && <Redirect to={getPath('/admin')} push />}
            {tabs && (
              <>
                <h3>Tenant</h3>
                <NavTabs tabs={tabs} onSelectTab={(id) => setCurrentTab(id)} />
              </>
            )}
            {tabs && <CTA showDeleteTenant={tabs.length > 1} />}

            {showPermissions && (
              <Permissions
                userId={userId}
                hideModal={() => setShowPermissions(false)}
                notifications={userData?.notifications}
                refetch={() => setRefetch(true)}
              />
            )}
            {showAddTenantModal && (
              <AddUserModal
                onSubmit={() => {
                  setShowAddTenantModal(false);
                  setRefetch(true);
                }}
                onClose={() => setShowAddTenantModal(false)}
                isEdit={true}
                assignedTenants={userData?.tenants}
              />
            )}
            <ConfirmationModal
              title={`There are some unsaved changes would you like to proceed?`}
              show={showAlert}
              handleClose={() => setShowAlert(false)}
              handleConfirm={() => {
                setRedirect(true);
              }}
              buttonText='Continue'
            />
            <ConfirmationModal
              title={`Are you sure you want to delete ?`}
              show={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              handleConfirm={async () => {
                const getProjectList = await userApi.getProjectList(userId);

                if (getProjectList.length === 0) {
                  return xhrService
                    .delete(`/api/users/delete/${userId}`)
                    .then(() => {
                      appStore.triggerSuccessNotification();
                      setShowDeleteModal(false);
                      setRedirect(true);
                    });
                } else {
                  setShowWarning(true);
                  setProjectList(getProjectList);
                  setShowDeleteModal(false);
                }
              }}
              buttonText='Delete user'
            />
            <ConfirmationModal
              title={`Are you sure you want to delete tenant for this user ?`}
              show={showDeleteTenantModal}
              handleClose={() => setShowDeleteTenantModal(false)}
              handleConfirm={async () => {
                const getProjectList = await userApi.getProjectListTenantWise(
                  userId,
                  tabs[currentTab].id,
                );

                if (getProjectList.length === 0) {
                  return xhrService
                    .delete(
                      `/api/users/${userId}/deleteTenant/${tabs[currentTab].id}`,
                    )
                    .then(() => {
                      appStore.triggerSuccessNotification();

                      if (
                        userData.userTenantRoles[0].tenant_id ===
                        tabs[currentTab].id
                      ) {
                        setRedirect(true);
                      } else {
                        setRefetch(true);
                        history.push(tabs[0].to.split('/').pop());
                        setCurrentTab(0);
                        setShowDeleteTenantModal(false);
                      }
                    })
                    .catch((err) => {
                      console.log('err', err);
                      setErrMsg(err.response.data.message);
                    });
                } else {
                  setShowWarning(true);
                  setProjectList(getProjectList);
                  setShowDeleteTenantModal(false);
                }
              }}
              buttonText='Confirm'
              subTitle={errMsg}
            />
            <Modal
              show={showWarning}
              title='Warning'
              subTitle={`This user is already assigned as the inspector for following active projects.`}
              submitBtnText='Close'
              handleClose={() => {
                setShowWarning(false);
                setProjectList([]);
              }}
              handleSubmit={() => {
                setShowWarning(false);
                setProjectList([]);
              }}
            >
              <div>
                <ol>
                  {projectList.map((project: any) => (
                    <li>{project.name}</li>
                  ))}
                </ol>
                <p
                  style={{
                    color: 'red',
                    fontStyle: 'italic',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  Assign new inspector to these projects prior deactivating this
                  user
                </p>
              </div>
            </Modal>
          </section>
        )}
      </FormContextProvider>
    </TooltipContextProvider>
  );
};
