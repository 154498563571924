import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Popover, Button } from '@mui/material';
import {
    faEllipsisV
} from '@fortawesome/free-solid-svg-icons';

type OptionsType = {
    icon?: any,
    label: string,
    onClickHandler: Function
}

type Props = {
    options?: Array<OptionsType>,
    popoverOverridingStyles?: object | undefined
}

const EllipsisPopover = (props: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-describedby={id} variant="contained"
                onClick={handleClick}
                className={`widget-popover-btn`} >
                <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: props.popoverOverridingStyles ? props.popoverOverridingStyles : { width: '150px', padding: '3px' },

                }}
            >
                {props.options && props.options.map((elm: OptionsType, index) => (
                    <Typography key={index} sx={{ p: 1 }} className={`widget-popover-items`} onClick={() => { elm.onClickHandler(); handleClose(); }}>
                        <FontAwesomeIcon icon={elm.icon} size={'sm'} /><p>{elm.label}</p>
                    </Typography>
                ))}
            </Popover>
        </>
    )
}



export default EllipsisPopover