import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faCloudShowersHeavy,
} from '@fortawesome/free-solid-svg-icons';
import MonthViewCalendarReport from './MonthViewCalendarReport';
import inspectionApi from '../../../../utils/api/inspection';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import projectApi from '../../../../utils/api/project';
import { Button } from '@sw-sw/lib-ui';
import PrintModal from '../../../Shared/Print/PrintModal';
import { FormSchema } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';

const getInspctionTypeWithColor = (inspectionType: string, color: string) => {
  return (
    <div>
      <FontAwesomeIcon icon={faClipboardList} color={color} />
      &nbsp;
      {inspectionType}
    </div>
  );
};

const getPrintSchema = (filter: boolean) => {
  const schema: FormSchema = {
    is_multiple_months: {
      controlType: UIControlType.checkbox,
      label: 'Select Range of Months',
      style: { flex: '0 1 100%' },
    },
    start_month: {
      controlType: UIControlType.date,
      isMonthYear: true,
      label: filter ? 'Start Month*' : 'Select Month*',
      validation: {
        maxDate: new Date(),
        required: true,
      },
      style: { flex: '0 1 50%' },
    },
    end_month: {
      controlType: UIControlType.date,
      label: 'End Month*',
      isMonthYear: true,
      validation: {
        maxDate: new Date(),
      },
      style: { flex: '0 1 50%', display: filter ? 'block' : 'none' },
    },
  };

  return schema;
};

const CalendarReport = () => {
  const projectContext = useContext(ProjectContext);
  const [currentCalMonth, setCurrentCalMonth] = useState<any>();
  const [inspectionList, setInspectionList] = useState([]);
  const [precipitationData, setPrecipitationData] = useState([]);
  const [printModal, setPrintModal] = useState(false);

  const getStartAndEndDate = (month: any) => {
    if (!month) return [null, null];

    const [year, monthNum] = month.split('-').map(Number);
    const startDate = new Date(Date.UTC(year, monthNum - 1, 1)) // because when converted from normal timezone the date differs by 1 day
      .toISOString()
      .split('T')[0];
    const endDate = new Date(Date.UTC(year, monthNum, 0)) // because when converted from normal timezone the date differs by 1 day
      .toISOString()
      .split('T')[0];

    return [startDate, endDate];
  };

  useEffect(() => {
    const [startDate, endDate] = getStartAndEndDate(currentCalMonth);
    if (!projectContext.project?.id || !startDate || !endDate) return;
    inspectionApi
      .getInspectionMonthWise(projectContext.project?.id, [startDate, endDate])
      .then((data) => {
        setInspectionList(data);
      });

    projectApi
      .getPrecipitationDataProjectWise(projectContext.project?.id, [
        startDate,
        endDate,
      ])
      .then((data) => {
        if (Object.keys(data).length === 0) return;
        setPrecipitationData(data);
      });
  }, [currentCalMonth]);

  return (
    <>
      <div className='calendar-report'>
        <header>
          <h2>Calendar Report</h2>
          <div className='calendar-report__header-tools'>
            <Button
              className='calendar-report__download-btn'
              onClick={() => setPrintModal(true)}
            >
              Print
            </Button>
          </div>
        </header>

        <div className='calendar-report__body'>
          <div className='calendar-report__content'>
            <MonthViewCalendarReport
              currentCalMonth={currentCalMonth}
              setCurrentCalMonth={setCurrentCalMonth}
              inspectionList={inspectionList}
              precipitationData={precipitationData}
            />
          </div>

          <footer className='calendar-report__footer'>
            <ul>
              <section>
                <li>
                  {getInspctionTypeWithColor('Routine', '#4ade86')}
                  {getInspctionTypeWithColor('Post Storm', '#2cb0e4')}
                  {getInspctionTypeWithColor('Post Storm + Routine', '#9546fc')}
                  {getInspctionTypeWithColor('Final', '#fc7575')}
                  {getInspctionTypeWithColor('Other', '#f4a526')}
                </li>
                <li>
                  <FontAwesomeIcon icon={faCloudShowersHeavy} />{' '}
                  <p>Daily Precipitation</p>
                </li>
              </section>
            </ul>
          </footer>
        </div>
      </div>

      {printModal && (
        <PrintModal
          jobName={'calendar'}
          jobData={{
            projectId: projectContext.project?.id,
          }}
          statusUpdateKey={projectContext.project?.id}
          onClose={() => setPrintModal(false)}
          formSchema={(formContextValue: any) =>
            getPrintSchema(formContextValue.is_multiple_months)
          }
          modalTitle='Print Calendar Report'
          validateData={null}
          initialFormData={{}}
        />
      )}
    </>
  );
};

export default CalendarReport;
