import React, { useEffect, useState } from 'react';
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  startOfMonth,
  addDays,
  getDaysInMonth,
} from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faEye,
  faClipboardList,
  faCloudShowersHeavy,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Popover, popoverClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const getInspectypeColor = (inspectionType: string) => {
  let color = '';
  switch (inspectionType) {
    case 'Routine':
      color = '#4ade86';
      break;
    case 'Post Storm':
      color = '#2cb0e4';
      break;
    case 'Post Storm + Routine':
      color = '#9546fc';
      break;
    case 'Final':
      color = '#fc7575';
      break;
    default:
      color = '#f4a526';
      break;
  }
  return color;
};

const MonthViewCalendarReport = (props: any) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    props.setCurrentCalMonth(format(currentMonth, 'yyyy-MM'));
  }, [currentMonth]);
  const changeMonthHandle = (btnType: any) => {
    if (btnType === 'prev') {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === 'next') {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const renderHeader = () => {
    const dateFormat = 'MMMM yyyy';

    return (
      <div className='header row flex-middle'>
        <div className='col col-end flex-gap renderHeader__left'>
          <div
            className='icon'
            onClick={() => {
              changeMonthHandle('prev');
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        </div>

        <div className='col col-center renderHeader__center'>
          <span>{format(currentMonth, dateFormat)}</span>
        </div>

        <div className='col col-start flex-gap renderHeader__right'>
          <div
            className='icon'
            onClick={() => {
              changeMonthHandle('next');
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = 'EEE';
    const days = [];

    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='col col-center' key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>,
      );
    }

    return <div className='days row'>{days}</div>;
  };

  const renderCells = () => {
    const firstDayOfCurrentMonth = format(startOfMonth(currentMonth), 'i'); // 7
    const numberOfDaysInCurrentMonth = getDaysInMonth(currentMonth); // 31

    return (
      <div className='render-cells'>
        {Array.from({ length: Number(firstDayOfCurrentMonth) - 1 }).map(
          (_, index) => (
            <span key={`blank-${index}`} className='render-cell'>
              <span></span>
            </span>
          ),
        )}

        {Array.from({ length: numberOfDaysInCurrentMonth }, (_, index) => (
          <CalendarCell
            key={`date-${index + 1}`}
            date={index + 1}
            currentMonth={currentMonth}
            inspectionList={props.inspectionList}
            precipitationData={props.precipitationData}
          />
        ))}
      </div>
    );
  };

  return (
    <div className='month-view-layout'>
      <div className='calendar'>
        {renderHeader()}
        <div className='calendar__body'>
          {renderDays()}
          {renderCells()}
        </div>
      </div>
    </div>
  );
};

const CalendarCell = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElX, setAnchorElX] = useState(null);

  // Mui Custom CSS for inpection detail popover
  const StyledPopover = styled(Popover)(({}) => ({
    [`&.${popoverClasses.root} .render-cells-popover`]: {
      fontSize: '12px',
      padding: '8px',
      color: '#6D747E',
    },
    [`&.${popoverClasses.root} li`]: {
      listStyle: 'none',
      padding: '0.25rem 0',
      lineHeight: 1,
    },
    [`&.${popoverClasses.root} li p`]: {
      display: 'inline',
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta`]: {
      border: '1px solid #afafaf',
      width: 'fit-content',
      marginTop: '5px',
      padding: '1px',
      borderRadius: '4px',
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta:hover`]: {
      background: 'rgb(228, 228, 228)',
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta a`]: {
      textDecoration: 'none',
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta a span`]: {
      borderRadius: '4px',
      fontSize: '12px',
      padding: '3px',
      color: '#6D747E',
      fontWeight: 900,
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseX = () => {
    setAnchorElX(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openX = Boolean(anchorElX);
  const idX = open ? 'simple-popover' : undefined;

  const { date, currentMonth, inspectionList, precipitationData } = props;

  let cellDate = new Date(
    `${format(startOfMonth(currentMonth), 'M')}/${date}/${format(
      startOfMonth(currentMonth),
      'yyyy',
    )}`,
  );

  let filteredInspection = inspectionList.filter(
    (inspection: any) =>
      new Date(inspection.certificationDate).getDate() === cellDate.getDate(),
  );

  let filteredPrecipitationData;

  if (precipitationData.hasOwnProperty('weather_accumulation')) {
    if (
      precipitationData.weather_accumulation.hasOwnProperty(
        cellDate.toISOString().split('T')[0],
      )
    ) {
      const isRainAboveThreshold =
        precipitationData.weather_accumulation[
          cellDate.toISOString().split('T')[0]
        ].rain_accumulation >= precipitationData.precipitation_threshold;
      const isSnowAboveThreshold =
        precipitationData.weather_accumulation[
          cellDate.toISOString().split('T')[0]
        ].snow_accumulation >= precipitationData.snow_threshold;

      filteredPrecipitationData =
        (isRainAboveThreshold || isSnowAboveThreshold) &&
        precipitationData.weather_accumulation[
          cellDate.toISOString().split('T')[0]
        ];
    }
  }

  const onInspectionIconClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const onPrecipitationIconClick = (event: any) => {
    setAnchorElX(event.currentTarget);
  };

  return (
    <>
      <span
        className='render-cell'
        style={{
          background: 'white',
        }}
        id={date}
      >
        <>
          {filteredInspection.length > 0 && (
            <FontAwesomeIcon
              onClick={(event) => onInspectionIconClick(event)}
              icon={faClipboardList}
              className='render-cell__list-icon'
              style={{
                position: 'absolute',
                bottom: 5,
                left: 5,
                color: getInspectypeColor(filteredInspection[0].inspectionType),
              }}
            />
          )}
          <span>{date}</span>
          {filteredPrecipitationData && (
            <FontAwesomeIcon
              onClick={(event) => onPrecipitationIconClick(event)}
              icon={faCloudShowersHeavy}
              className='render-cell__cloud-icon'
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                color: 'rgb(42, 212, 218)',
              }}
            />
          )}
        </>

        <div>
          {filteredInspection[0] && (
            <StyledPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className='render-cells-popover'>
                <li>
                  <span>Inspection Type:</span>{' '}
                  <p>{filteredInspection[0].inspectionType}</p>
                </li>
                <li>
                  <span>Inspector Name:</span>{' '}
                  <p>{filteredInspection[0].inspectorName}</p>
                </li>
                <section className='render-cells-popover__cta'>
                  <Link
                    to={`/inspection/${filteredInspection[0].id}/questionnaire`}
                  >
                    <span>
                      <FontAwesomeIcon icon={faEye} /> View Inspection
                    </span>
                  </Link>
                </section>
              </div>
            </StyledPopover>
          )}
        </div>

        <div>
          {filteredPrecipitationData && (
            <StyledPopover
              id={idX}
              open={openX}
              anchorEl={anchorElX}
              onClose={handleCloseX}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className='render-cells-popover'>
                <li>
                  <span>Rain:</span>{' '}
                  <p>
                    {filteredPrecipitationData
                      ? Number(
                          filteredPrecipitationData.rain_accumulation,
                        ).toFixed(2)
                      : 0}
                    "
                  </p>
                </li>
                <li>
                  <span>Snow:</span>{' '}
                  <p>
                    {filteredPrecipitationData
                      ? Number(
                          filteredPrecipitationData.snow_accumulation,
                        ).toFixed(2)
                      : 0}
                    "
                  </p>
                </li>
              </div>
            </StyledPopover>
          )}
        </div>
      </span>
    </>
  );
};

export default MonthViewCalendarReport;
