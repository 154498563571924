import React, { useContext, useState, useEffect, useRef }  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  // faEllipsisV,
  faExternalLinkAlt, faClipboardList,
  faCloudShowersHeavy
  } from "@fortawesome/free-solid-svg-icons";
import { AppDivisionContext } from "../../../../contexts/AppDivisionContext";
import { customDashboard } from "../../../../utils/api/dashboard";
import DashboardContext from "../../../../contexts/DashboardContext";
import MonthViewLayout from './MonthViewLayout'
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover'




const Calendar = (props) => {
  const { getPath } = useContext(AppDivisionContext);
  const { setDisplayStatsForCal, setIsLoadingForCal, projectsListForCal, setPrecipitationDataForCal, setSelectedCalProject, selectedCalProject } = useContext(DashboardContext)
  const widgetRef = useRef(0);
  const [ widgetWidth, setWidgetWidth ] = useState(widgetRef.current.clientWidth)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [ currentCalMonth, setCurrentCalMonth ] = useState("")

  // Styles changes based on widget width
  let showMoreResponsiveButton = {
    fontSize: "0.9rem",
    paddingRight: "0.5rem 0rem 0.5rem 0.5rem"
  }

  useEffect(() => {
    function handleResize() {
      setWidgetWidth(widgetRef.current.clientWidth)
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const handleSelect = (event) => {
    setIsLoadingForCal(true)
    setSelectedCalProject(event.target.value)
    Promise.all([
      customDashboard.fetchWithParams(getPath('/calendar/getCalendarData'), { "type": event.target.value}),
      customDashboard.fetchWithParams(getPath('/calendar/fetchPrecipitationForCal'), { "type": event.target.value, "month": currentCalMonth })
    ])
    .then(([calendarResponse, PrecipitationResponse]) => {
      setDisplayStatsForCal(calendarResponse.inspections)
      setPrecipitationDataForCal(PrecipitationResponse)
      setIsLoadingForCal(false)
    })
    .catch((error) => console.log(error))
  }

  const handleClose = () => {
    setAnchorEl(null);
  };





  let oneColumnHeader = {
    // gridTemplateAreas: '"title title title title . bars bars bars bars bars bars ellipsis"'
  }

  
  return(
    <>
      <div className="calendar-widget" ref={widgetRef}>
        <header className="calendar-widget__header"  style={widgetWidth > 700 ? oneColumnHeader : null}>
          <h2 className="calendar-widget__title">Calendar</h2>
          <div className="calendar-widget__bars">
            <select name="finding-type" id="finding-type" placeholder="Select" onChange={(event) => handleSelect(event)} value={selectedCalProject}>
              <option value="-10" hidden>Select Project</option>
              {
                projectsListForCal.length ? projectsListForCal.map((ele, index) => {
                  return(
                    <option value={ele.id} key={index}>{ele.name} -- {ele.client && ele.client.name.toString().toUpperCase()}</option>
                  )
                }):
                <option value="-10" disabled>No Projects Found</option>
              }
            </select>
          </div>
          {/* <div className="calendar-widget__ellipsis-button">
            <span onClick={(event) => setAnchorEl(event.currentTarget)}>
              <FontAwesomeIcon icon={ faEllipsisV }/>
            </span>
          </div> */}
          <EllipsisPopover handleCloseFunc={handleClose} id={id} open={open} anchorEl={anchorEl} />
        </header>
  
        <div
          data-rbd-drag-handle-context-id={props.providedProps}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: "auto"
          }}
        >
          <div className="calendar-widget__body">
            <div className="calendar-widget__content">
              <MonthViewLayout currentCalMonth={currentCalMonth} setCurrentCalMonth={setCurrentCalMonth} />
            </div>

            <footer className="calendar-widget__footer">
                <ul>
                  <section>
                    <li><FontAwesomeIcon icon={ faClipboardList }/> <p>Completed Inspection</p></li>
                    <li><FontAwesomeIcon icon={ faCloudShowersHeavy }/> <p>Daily Precipitation</p></li>
                  </section>
                </ul>
                <span onClick={props.changeShowMore} style={widgetWidth < 450 ? showMoreResponsiveButton : null}>Show {(props.mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} /></span>
              </footer>
          </div>
        </div>
      </div>
    </>
  )
}


export default Calendar