import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Loading from '../../../Shared/ResourceIndex/Loading';
import { positionableUtil } from "@sw-sw/common";
import projectApi from '../../../../utils/api/project';
import moment from 'moment';

const LogContainer = (props: any) => {

    const [legendItemLogData, setLegendItemLogData] = useState<any>([]);
    const [showMoreLessBtnStatus, setShowMoreLessBtnStatus] = useState<boolean>(true);
    const [showMoreLessBtnVisibility, setShowMoreLessBtnVisibility] = useState<boolean>(true);

    /**
     * This function is copied from /common/src/components/Changelog.jsx
     * @param legendItem 
     * @returns image tag with legendItem icon
     */
    const getImg = (legendItem: any) => {
        const { src } = positionableUtil.controlMeasure.getIcon(legendItem)
        return (
            <img
                src={src}
                alt={`icon for ${legendItem.type}: ${legendItem.name}`}
                className="list-icon"
            />
        );
    }

    /**
     * This function is copied from /common/src/components/Changelog.jsx
     * @param type 
     * @returns beautified type
     */
    const getFriendlyChangelogType = (type: string) => {
        switch (type) {
            case 'create':
                return 'added';
            case 'modify':
                return 'edited';
            case 'delete':
                return 'removed';
            default:
                return type;
        }
    }

    const fetchProjectLegendItemLogQuery = async (showFull = false) => {
        if (props.projectId && props.legendItem.shape.id) {
            const projectLegendItemLogQuery = await projectApi.getProjectLegendItemLog(props.projectId, props.legendItem.shape.id, showFull);
            setLegendItemLogData(projectLegendItemLogQuery.records);
            setShowMoreLessBtnVisibility(projectLegendItemLogQuery.showMoreLessBtnVisibility)
        }
    }

    const toggleShowMoreLessClick = () => {
        fetchProjectLegendItemLogQuery(showMoreLessBtnStatus);
        setShowMoreLessBtnStatus(!showMoreLessBtnStatus);
    }

    useEffect(() => {
        fetchProjectLegendItemLogQuery()
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <table className='list-table pure-table changelog-table log-table'>
                    <tbody>
                        <tr className='group-header-row'>
                            <td>{props.legendItem.shape.name.trim()}</td>
                            <td colSpan={4}>{getImg(props.legendItem)}</td>
                        </tr>
                        {
                            (!legendItemLogData || legendItemLogData.length === 0) ?

                                <tr className='log-row last'>
                                    <td colSpan={5}>
                                        <Loading what={`${props.legendItem.shape.name.trim()} Logs`} />
                                    </td>
                                </tr>

                                :

                                legendItemLogData.map((item: any, index: number) => {
                                    return (
                                        <tr className={`log-row ${(legendItemLogData.length < 5 && legendItemLogData.length - 1 === index ) ? 'last' : ''}`} key={index}>
                                            <td>{`${item.name}-${item.number}`}</td>
                                            <td>{props.uploads.filter((e: any) => e.id === item.commentable_id)[0].upload.name}</td>
                                            <td>{getFriendlyChangelogType(item.change_type)}</td>
                                            <td>{(item.first_name.split("")[0] + item.last_name.split("")[0]).toUpperCase()}</td>
                                            <td>
                                                {
                                                    (item.change_type === 'create' && item.created_date) ?
                                                        moment(item.created_date).format('MM-DD-YYYY')
                                                        :
                                                        moment(item.created_at).tz(props.timezone).format('MM-DD-YYYY')
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                        }
                        {
                            (legendItemLogData.length >= 5) &&
                            <tr className={`log-row last`}>
                                <td colSpan={5} className='showMoreLinkWrapper'>
                                    {
                                        (showMoreLessBtnVisibility)? <button type='button' onClick={toggleShowMoreLessClick} style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            color: '#007bff',
                                            cursor: 'pointer',
                                            textDecoration: 'underline'
                                        }}>Show {(showMoreLessBtnStatus)? 'More' : 'Less'}</button> : <></>
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </Grid>
        </Grid>
    )
}

export default LogContainer