import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import userApi from '../utils/api/user';
import { getDownloadUrl } from '../utils/api/upload';
import SWLogo from '../images/SW-Logo-White.svg';
import tenantApi from '../utils/api/tenant';
import PowerdByLogo from '../images/powered-by-SW2-logo.png';

interface AppLogoContextTypes {
    appLogo: string | null;
    setAppLogo: React.Dispatch<React.SetStateAction<string | null>>;
    refetchLogo: (tenantId: number) => void;
    isAllowShowLogo: (tenantId: number) => void;
    isCustomLogo: boolean;
    setIsCustomLogo: React.Dispatch<React.SetStateAction<boolean>>;
    powerdByLogo: string;
}

const defaultLogoContext: AppLogoContextTypes = {
    appLogo: '',
    setAppLogo: () => { },
    refetchLogo: () => { },
    isAllowShowLogo: () => { },
    isCustomLogo: false,
    setIsCustomLogo: () => { },
    powerdByLogo: PowerdByLogo,
};

const AppLogoContext = createContext<AppLogoContextTypes>(defaultLogoContext);

export const AppLogoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [appLogo, setAppLogo] = useState<string | null>(null);
    const [isCustomLogo, setIsCustomLogo] = useState<boolean>(false);
    const [powerdByLogo] = useState<string>(PowerdByLogo);

    const isAllowShowLogo = async (tenantId: number) => {

        return Promise.all([
            tenantApi.features.index(tenantId, 'white_label'),
            tenantApi.features.index(tenantId, 'add_logo')
        ]).then(([white_label, add_logo]) => {
            return ((white_label && white_label[0] && white_label[0].is_enabled) && (add_logo && add_logo[0] && add_logo[0].is_enabled))
        }).catch((e: any) => {
            console.log(e)
            return false;
        });

    }

    const refetchLogo = (tenantId: number) => {
        return tenantApi.whitelist.getCompanyLogo(tenantId).then((uploadedLogo) => {
            if (uploadedLogo && uploadedLogo.success === true) {
                const imgUrl = getDownloadUrl(uploadedLogo.tenantLogo.upload.GUID, null, false, Date.now(), null);
                setAppLogo(imgUrl);
                setIsCustomLogo(true);
            } else {
                setAppLogo(SWLogo);
                setIsCustomLogo(false);
            }
        })
    }

    useEffect(() => {

        const loadInitialLogo = async () => {

            const tenantName = window.location.host.split('.')[0];

            if (!(/login/.test(tenantName))) {
                userApi.getTenantByName(tenantName).then(async (data) => {
                    if (
                        (data && data.tenantLogo && data.tenantLogo.upload) && 
                        (data.tenantFeatures && data.tenantFeatures.length === 2)
                    ) {
                        const imgUrl = getDownloadUrl(data.tenantLogo.upload.GUID, null, false, Date.now(), null);
                        setAppLogo(imgUrl);
                        setIsCustomLogo(true);
                    } else {
                        setAppLogo(SWLogo);
                        setIsCustomLogo(false);
                    }
                }).catch(e => {
                    console.log(e);
                    setAppLogo(SWLogo);
                    setIsCustomLogo(false);
                })
            } else {
                setAppLogo(SWLogo);
                setIsCustomLogo(false);
            }

        }

        loadInitialLogo();

    }, []);

    return (
        <AppLogoContext.Provider value={{ 
            appLogo, 
            setAppLogo, 
            refetchLogo,
            isAllowShowLogo,
            isCustomLogo,
            setIsCustomLogo,
            powerdByLogo,
        }}>
            {children}
        </AppLogoContext.Provider>
    );
};

export const useLogo = () => useContext(AppLogoContext);

export default AppLogoContext;