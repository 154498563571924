import instance from '../xhrService';

const baseUrl = '/api/positionables';
const url = (a, b, c, d) =>
  `${baseUrl}/${a}` +
  (b ? `/${b}` : '') +
  (c ? `/${c}` : '') +
  (d ? `/${d}` : '');

export const positionableApi = {
  index: (tType, tId, iId, pType, pSubType = null) => {
    const params = {};
    if (pSubType) {
      params.type = pSubType;
    }

    return instance
      .get(url(tType, tId, iId, pType), {
        params,
      })
      .then((response) => response.data);
  },
  // update or insert en-masse
  bulkUpdate: (tType, id, data) => {
    return instance
      .put(url(tType, id, 'update'), data)
      .then((response) => response.data);
  },
  bulkDelete(positionableIds, selectedInspId) {
    /** @todo refactor to single xhr */
    return Promise.all(positionableIds.map((id) => this.delete(id, selectedInspId))).then(
      () => null,
    );
  },
  delete: (id) => {
    const intId = parseInt(id);

    return instance.delete(url(intId)).then((response) => response);
  },
  editCreatedDate: (id, date) => {
    return instance.put(`${baseUrl}/${id}`, { updatedDate: date })
    .then((res) => res)
  },
  editRemovedOnDate: (id, date) => {
    return instance.put(`${baseUrl}/changeRemovedOnDate/${id}`, { updatedDate: date })
    .then((res) => res)
  },
  getPositionablesAuthor: (positionableId) => {
    return instance.get(`${baseUrl}/${positionableId}`)
    .then((res) => res)
  }
};

export default positionableApi;
