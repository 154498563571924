import React, { useContext, useEffect, useState } from "react";
import EllipsisPopover from "../../../Shared/MaterialPopover";
import DatePicker from 'react-datepicker';
import { Button } from "@mui/material";
import DailyPrecipitation from "./DailyPrecipitation";
import HourlyPrecipitation from "./HourlyPrecipitation";
import projectApi from "../../../../utils/api/project";
import { ProjectContext } from "../../../../contexts/ProjectContext";
import { useQuery } from "react-query";
import moment from "moment";
import { FormContext, FormContextProvider, FormSchemaFields } from "@sw-sw/lib-form";
import ExcelJs from 'exceljs';
import { FormModal } from "../../../Shared/form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { jsPDF } from "jspdf"
import autoTable from "jspdf-autotable"
import "jspdf-autotable"

const logTypeValues = Object.freeze({
    daily: 'daily',
    hourly: 'hourly'
})

const PrecipitationLog: React.FC<any> = () => {
  const [logType, setLogType] = useState<string>(logTypeValues.daily)
  const [minDate, setMinDate] = useState<any>(null);
  const [maxDate, setMaxDate] = useState<any>(null);
  const [hourlyEnabled, setHourlyEnabled] = useState<boolean>(false)
  const [dailyEnabled, setDailyEnabled] = useState<boolean>(false)
  const projectContext = useContext(ProjectContext);
  const projectId = projectContext && projectContext.project && projectContext.project.id
  const projectName = projectContext && projectContext.project && projectContext.project.name
  const formattedMinDate = minDate && moment(minDate).format('MM/DD/YYYY')
  const formattedMaxDate = maxDate && moment(maxDate).format('MM/DD/YYYY')
  const [isDownloadModal, setIsDownloadModal] = useState(false)
  const formContext = useContext(FormContext);
 
  const hourlyPrecipitationQuery = projectId && useQuery(
    {
      queryKey: ['hourlyPrecipitationLogValue', projectId, formattedMinDate, formattedMaxDate],
      queryFn: () => projectApi.getHourlyPrecipitationData(projectId, {formattedMinDate, formattedMaxDate}),
      enabled: hourlyEnabled
    }
  )
  const dailyPrecipitationQuery = projectId && useQuery(
    {
      queryKey: ['dailyPrecipitationLogValue', projectId, formattedMinDate, formattedMaxDate],
      queryFn: () => projectApi.getDailyPrecipitationData(projectId, {formattedMinDate, formattedMaxDate}),
      enabled: dailyEnabled
    }
  )

  const downloadOptions = [
    {name: 'PDF'},
    {name: 'Excel'},
  ]

  function getSchema() {
    return {
      format: {
        label: 'Format',
        controlType: UIControlType.select,
        placeholder: "Select",
        "aria-label": "State",
        autoComplete: "address-level1",
        options: downloadOptions,
        labelKey: 'name',
        valueKey: 'name',
        style: { flex: '1 1 50%' },
        validation: {
          required: true,
        },
      },
    }
  }

  const handleExcelDownload = () => {
    let rainTotal = 0, snowTotal = 0;
    
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Precipitation Log');

    if(logType === 'daily'){
      worksheet.columns = [
        { header: 'Date', key: 'date', width: 15, style: { numFmt: 'mm/dd/yyyy' } },
        { header: 'Total Rain', key: 'rain_custom_reading', width: 20, style: { alignment: { horizontal: "center"} } },
        { header: 'Total Snow', key: 'snow_custom_reading', width: 20, style: { alignment: { horizontal: "center"} } },
        { header: 'Source', key: 'source', width: 20 },
        { header: 'Notes', key: 'note', width: 20 },
      ]

      dailyPrecipitationQuery && dailyPrecipitationQuery.data.forEach((ele: any) => {
        const { rain_accumulation, snow_accumulation, rain_custom_reading, snow_custom_reading } = ele

        const selectedRainReading = (rain_custom_reading === undefined || rain_custom_reading === null) ? rain_accumulation : rain_custom_reading
        const selectedSnowReading = (snow_custom_reading === undefined || snow_custom_reading === null) ? snow_accumulation : snow_custom_reading
        const displayDate = ele.date ? `${moment(ele.date).format('MM/DD/YYYY')}` : `${moment(ele.justDate).format('MM/DD/YYYY')}`

        rainTotal = rainTotal + selectedRainReading
        snowTotal = snowTotal + selectedSnowReading

        worksheet.addRow([
          displayDate,
          selectedRainReading,
          selectedSnowReading,
          `${ele.source ? ele.source : 'Tomorrow.io/NOAA'}`,
          `${ele.note ? ele.note : ''}`
        ])
      })
    }
    else{
      worksheet.columns = [
        { header: 'Date', key: 'date', width: 15, style: { numFmt: 'mm/dd/yyyy' } },
        { header: 'Total Rain', key: 'rain_custom_reading', width: 20, style: { alignment: { horizontal: "center"} } },
        { header: 'Total Snow', key: 'snow_custom_reading', width: 20, style: { alignment: { horizontal: "center"} } },
        { header: 'Source', key: 'source', width: 20 },
      ]

      hourlyPrecipitationQuery && hourlyPrecipitationQuery.data.forEach((ele: any) => {
        const { rain_accumulation, snow_accumulation, rain_custom_reading, snow_custom_reading } = ele

        const selectedRainReading = (rain_custom_reading === undefined || rain_custom_reading === null) ? rain_accumulation : rain_custom_reading
        const selectedSnowReading = (snow_custom_reading === undefined || snow_custom_reading === null) ? snow_accumulation : snow_custom_reading
        const displayDate = ele.date ? `${moment(ele.date).utc().format('MM/DD/YYYY')}` : `${moment(ele.datetime).utc().format('MM/DD/YYYY')} - ${ele.datetime.toString().split('T')[1].substr(0,8)}`

        rainTotal = rainTotal + selectedRainReading
        snowTotal = snowTotal + selectedSnowReading

        worksheet.addRow([
          displayDate,
          selectedRainReading,
          selectedSnowReading,
          ele.source,
        ])
      })
    }

    const totalRow = worksheet.addRow([
      `${(formattedMinDate && formattedMaxDate) ? `${formattedMinDate} - ${formattedMaxDate}`: ''}`,
      `Rain Total : ${rainTotal.toFixed(2)}`,
      `Snow Total : ${snowTotal.toFixed(2)}`,
      // `User Verified Total - hourly totals not available for date(s)`
    ])

    totalRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E9E9E9' },
    }

    worksheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');

      let prefix = logType === "daily" ? 'PrecipLog_Daily' : 'PrecipLog_Hrly'

      a.href = url;
      a.download = `${projectName}_${prefix}_${formattedMinDate} - ${formattedMaxDate}.xlsx`;
      a.click();
    });
  }

  const handlePdfDownload = () => {
    let prefix = logType === "daily" ? 'PrecipLog_Daily' : 'PrecipLog_Hrly'
    let rainTotal = 0, snowTotal = 0;
    let tableBodyData = []

    if(logType === "daily"){
      tableBodyData = dailyPrecipitationQuery && dailyPrecipitationQuery.data.map((ele:any) => {
        const { rain_accumulation, snow_accumulation, rain_custom_reading, snow_custom_reading } = ele

        const selectedRainReading = (rain_custom_reading === undefined || rain_custom_reading === null) ? rain_accumulation : rain_custom_reading
        const selectedSnowReading = (snow_custom_reading === undefined || snow_custom_reading === null) ? snow_accumulation : snow_custom_reading
        const displayDate = ele.date ? `${moment(ele.date).format('MM/DD/YYYY')}` : `${moment(ele.justDate).format('MM/DD/YYYY')}`

        rainTotal = rainTotal + selectedRainReading
        snowTotal = snowTotal + selectedSnowReading

        return [displayDate, `${selectedRainReading}`, `${selectedSnowReading}`, `${ele.source ? ele.source : 'Tomorrow.io/NOAA'}`, `${ele.note ? ele.note : ''}`]
      })
    }
    else{
      tableBodyData = hourlyPrecipitationQuery && hourlyPrecipitationQuery.data.map((ele:any) => {
        const { rain_accumulation, snow_accumulation, rain_custom_reading, snow_custom_reading } = ele

        const selectedRainReading = (rain_custom_reading === undefined || rain_custom_reading === null) ? rain_accumulation : rain_custom_reading
        const selectedSnowReading = (snow_custom_reading === undefined || snow_custom_reading === null) ? snow_accumulation : snow_custom_reading
        const displayDate = ele.date ? `${moment(ele.date).utc().format('MM/DD/YYYY')}` : `${moment(ele.datetime).utc().format('MM/DD/YYYY')} - ${ele.datetime.toString().split('T')[1].substr(0,8)}`

        return [displayDate, `${selectedRainReading}`, `${selectedSnowReading}`, `${ele.source}`]
      })
    }

    const doc = new jsPDF()

    autoTable(doc, {
      head: logType === "daily" ? [['Date', 'Total Rain', 'Total Snow', 'Source', 'Notes']] : [['Date', 'Total Rain', 'Total Snow', 'Source']],
      body: tableBodyData,
    })

    doc.save(`${projectName}_${prefix}_${formattedMinDate} - ${formattedMaxDate}.pdf`)
  }

  useEffect(() => {
    setHourlyEnabled(false)
  },[hourlyPrecipitationQuery && hourlyPrecipitationQuery.isSuccess])

  useEffect(() => {
    setDailyEnabled(false)
  },[dailyPrecipitationQuery && dailyPrecipitationQuery.isSuccess])

  const onShowResultsClick = () => {
    logType === "daily" ? setDailyEnabled(true) : setHourlyEnabled(true)
  }

  const onDownloadButtonClick = () => {
    if(formattedMinDate && formattedMaxDate){
      if(logType === "daily"){
        if(dailyPrecipitationQuery && dailyPrecipitationQuery.data){
          setIsDownloadModal(true)
        }
        else{
          setIsDownloadModal(true)
          setDailyEnabled(true)
        }
      }

      if(logType === "hourly"){    
        if(hourlyPrecipitationQuery && hourlyPrecipitationQuery.data){
          setIsDownloadModal(true)
        }
        else{
          setIsDownloadModal(true)
          setHourlyEnabled(true)
        }
      }
    }
  }

  return (
    <div className='precipitation-log'>
      <header>
        <h2 className='precipitation-log__header-name'>Precipitation Log</h2>
        <div className='precipitation-log__header-tools'>
          <DatePicker
            placeholderText={'Min Date'}
            selected={minDate}
            maxDate={new Date()}
            onChange={(date: Date) => setMinDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            className='precipitation-log__min-date'
          />
          <DatePicker
            placeholderText={'Max Date'}
            selected={maxDate}
            maxDate={new Date()}
            onChange={(date: Date) => setMaxDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            className='precipitation-log__max-date'
          />
          <Button className='precipitation-log__show-results-btn' onClick={onShowResultsClick}>Show Results</Button>
          <Button className='precipitation-log__download-btn' onClick={onDownloadButtonClick}>Download</Button>
          <EllipsisPopover
            options={ logType === "hourly" ? 
              [
                {
                  label: 'Daily Precipitation',
                  onClickHandler: () => setLogType(logTypeValues.daily)
                }
              ] :
              [
                {
                  label: 'Hourly Precipitation',
                  onClickHandler: () => setLogType(logTypeValues.hourly)
                }
              ]
            }
            popoverOverridingStyles={{ width: '200px', padding: '3px' }}
          />
        </div>
      </header>

      { isDownloadModal && 
        <FormModal
          modalProps={{
            title: 'Print Precipitation Log',
            submitBtnText: "Download",
          }}
          onSubmit={(formData) => { 
            if(formData.format === 'Excel'){
              handleExcelDownload()
              setIsDownloadModal(false)
            }

            if(formData.format === 'PDF'){
              handlePdfDownload()
              setIsDownloadModal(false)
            }

            return Promise.resolve()
          }}
          onCancel={() => setIsDownloadModal(false)}
        >
          <FormSchemaFields
            schema={getSchema()}
            onChange={formContext.set}
            formData={formContext.value}
          />
        </FormModal>
      }

      <main>
        {
          logType === "daily" ?
          <FormContextProvider>
            <DailyPrecipitation
              dailyPrecipitationQuery={dailyPrecipitationQuery}
              formattedMinDate={formattedMinDate}
              formattedMaxDate={formattedMaxDate}
              projectId={projectId}
            />
          </FormContextProvider>
          :
          <FormContextProvider>
            <HourlyPrecipitation 
              hourlyPrecipitationQuery={hourlyPrecipitationQuery}
              formattedMinDate={formattedMinDate}
              formattedMaxDate={formattedMaxDate}
              projectId={projectId}
            />
          </FormContextProvider>
        }
      </main>
    </div>
  );
};

export default PrecipitationLog;
