/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useState, useEffect } from 'react';
import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from '@sw-sw/lib-form';
import { toInteger, transform } from 'lodash';
import AppContext from '../../../contexts/AppContext';
import { ProjectContext } from '../../../contexts/ProjectContext';
import TenantFeatureContext from '../../../contexts/TenantContext';
import clientApi from '../../../utils/api/client';
import divisionApi from '../../../utils/api/division';
import projectApi from '../../../utils/api/project';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import {
  getDocumentType,
  getPrecipitationFields,
  getProjectInformationSchemaLeft,
  getProjectInformationSchemaRight,
} from '../Forms/projectFormSchema';
import DashboardContext from '../../../contexts/DashboardContext';
import { extractInitialData } from '../Forms/projectFormData';
import { TooltipContextProvider } from '@sw-sw/lib-ui';
import Loading from '../../Shared/ResourceIndex/Loading';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { useDivisionClients } from '../../../hooks/client';
import { useCountries, useStates } from '../../../hooks/address';
import uploadApi from '../../../utils/api/upload';
import rolesApi from '../../../utils/api/roles';
import momentTz from 'moment-timezone';
import { env } from '../../../config';
import { ActionButtonsWithScrollFix } from '../../Shared/ActionButtons';
import { submitHandler } from '../../Shared/form';
import { toast } from 'react-toastify';

function ProjectFormUI({
  schemaData,
  handleSubmit,
  disableClient,
  appDivisionId,
  projectId,
  ...props
}) {
  const [projectInformationCollapse, setProjectInformationCollapse] =
    useState(true);
  const [documentTypesCollapse, setDocumentTypesCollapse] = useState(true);
  const [precipitationCollapse, setPrecipitationCollapse] = useState(true);
  const formContext = useContext(FormContext);
  const appContext = useContext(AppContext);
  const projectContext = useContext(ProjectContext);
  const featureContext = useContext(TenantFeatureContext);
  const [clientBasedDocTypes, setClientBasedDocTypes] = useState([]);
  const [divisionInspectionIntervals, setDivisionInspectionIntervals] =
    useState([]);

  function submit() {
    const { docTypes = {}, routine_interval, ...values } = formContext.value;
    const allClientCheckedDocTypeIds = props.clientCheckedDocTypes.map(
      (ele) => ele.id,
    );

    const relevantDoctypes = Object.keys(docTypes)
      .filter((key) => allClientCheckedDocTypeIds.includes(Number(key)))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: docTypes[key],
        });
      }, {});

    values.client &&
      values.client.map((ele) => {
        delete ele.users;

        return ele;
      });

    return handleSubmit({
      ...values,
      routine_interval: Number(routine_interval),
      docTypes: transform(
        relevantDoctypes,
        (acc, val, key) => {
          if (val) acc.push(toInteger(key));
        },
        [],
      ),
    });
  }

  const formData = formContext.value;
  const formSectionProps = {
    formData,
    onChange: formContext.set,
  };
  const clientData =
    formData.client && formData.client.length > 0 ? formData.client[0] : false;

  useEffect(() => {
    async function fetchInspectionTemplates() {
      if (!formContext.value.clientId) {
        setClientBasedDocTypes(schemaData.docTypes);
      } else {
        const clientDocType = await clientApi.getClientsDocTypes.index(
          formContext.value.clientId,
        );
        const filteredClientDocType = clientDocType.filter(
          (e) =>
            e.name !== 'Fixed Details Project' &&
            e.name !== 'Fixed Details' &&
            e.name !== 'Active Site Maps' &&
            e.is_checked,
        );
        setClientBasedDocTypes(filteredClientDocType);
      }
    }

    fetchInspectionTemplates();

    props.getSelectedValue(formContext.value.clientId);
  }, [formContext.value.clientId]);

  useEffect(() => {
    const fetchIntervalsAndUpdateIntervalId = async () => {
      let data = await divisionApi.inspectionIntervals.fetchWithDefaultTrue(
        appDivisionId,
      );

      if (data.length !== 1) {
        data = data.filter((ele) => ele.default === false);
      }

      if (data.length === 1 && projectId) {
        let defaultIntervalId = data[0].id;

        let currentIntervalId = projectContext.project.inspection_interval_id;

        if (defaultIntervalId !== currentIntervalId) {
          await projectApi.updateInspectionIntervalId(
            projectId,
            defaultIntervalId,
          );
        }
      }

      setDivisionInspectionIntervals(data);
    };

    fetchIntervalsAndUpdateIntervalId();
  }, [appDivisionId]);

  useEffect(() => {
    if (!String(formContext.value.longitude).split('').includes('.')) {
      if (!isNaN(formContext.value.longitude)) {
        formContext.set(
          'longitude',
          '-' + Math.abs(formContext.value.longitude).toString(),
        );
      }
      if (formContext.value.longitude === '') {
        formContext.set('longitude', '');
      }
    }
  }, [formContext.value.longitude]);

  return (
    <>
      <section
        className='project-form'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <section className='container create-project-label'>
          <label>Update Project</label>
        </section>
        <ActionButtonsWithScrollFix
          primary='Save'
          spacer={false}
          onClick={(e) => submitHandler(formContext, e, submit)}
        />
      </section>
      <section
        className='project-form'
        style={{ marginTop: '1rem', width: '6rem' }}
      >
        <section className='container'>
          <FormSchemaFields
            {...formSectionProps}
            schema={{
              external_project: {
                label: 'EVP: ',
                controlType: UIControlType.toggleButton,
                toggleSize: 'sm',
                style: {
                  display: featureContext.tenantHasFeature(
                    'External Verification Projects',
                  )
                    ? 'flex'
                    : 'none',
                },
              },
            }}
          />
        </section>
      </section>
      <div id='projectInformation'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() =>
            setProjectInformationCollapse(!projectInformationCollapse)
          }
        >
          <h3>Project Information</h3>
          <i
            className={
              projectInformationCollapse
                ? 'fa fa-chevron-down'
                : 'fa fa-chevron-up'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        {projectInformationCollapse && (
          <section
            className='project-form'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <section className='container'>
              <FormSchemaFields
                {...formSectionProps}
                schema={getProjectInformationSchemaLeft(
                  schemaData.clients,
                  schemaData.inspectionTemplates,
                  schemaData.states,
                  schemaData.tz,
                  disableClient,
                  formData.inspectionTemplateId,
                  formData.inspection_compliance_required,
                  appContext.get('constants.optionalComplianceTemplates'),
                  appContext.get('constants.ccrTemplates'),
                  formData.external_project,
                  formData.clientId,
                  featureContext.tenantHasFeature('Enable Enhanced Findings'),
                )}
              />
            </section>
            <section className='container'>
              <FormSchemaFields
                {...formSectionProps}
                schema={getProjectInformationSchemaRight(
                  schemaData.users,
                  schemaData.primaryContactUsers,
                  schemaData.projectTypes,
                  divisionInspectionIntervals,
                  schemaData.docGroups,
                )}
              />
            </section>
          </section>
        )}
      </div>

      <div id='documentTypes'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => setDocumentTypesCollapse(!documentTypesCollapse)}
        >
          <h3>Document Type</h3>
          <i
            className={
              documentTypesCollapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        {documentTypesCollapse && (
          <section className='project-form'>
            <section className='container'>
              <FormSchemaFields
                {...formSectionProps}
                schema={getDocumentType(clientBasedDocTypes)}
              />
            </section>
          </section>
        )}
      </div>

      <div
        id='precipitation'
        style={{
          marginBottom: '10rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            cursor: 'pointer',
          }}
          onClick={() => setPrecipitationCollapse(!precipitationCollapse)}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Precipitation</h3>
            <FormSchemaFields
              {...formSectionProps}
              schema={{
                precipitation_flag: {
                  controlType: UIControlType.toggleButton,
                  checked: false,
                  toggleSize: 'sm',
                  style: {
                    marginTop: '10px',
                    marginLeft: '40px',
                  },
                  disabled:
                    schemaData.precipitation_edit_lock ||
                    !featureContext.tenantHasFeature(
                      'Precipitation Tracking',
                    ) ||
                    (clientData &&
                      !clientData.precipitation_client_project_flag),
                },
              }}
            />
          </div>
          <i
            className={
              precipitationCollapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'
            }
            aria-hidden='true'
          />
        </div>
        <hr></hr>
        {precipitationCollapse && (
          <>
            <section className='project-form'>
              <section className='container'>
                <FormSchemaFields
                  {...formSectionProps}
                  schema={getPrecipitationFields(
                    formData.precipitation_edit_lock,
                    clientData,
                    featureContext.tenantHasFeature('Precipitation Tracking'),
                  )}
                />
              </section>
            </section>

            {(!featureContext.tenantHasFeature('Precipitation Tracking') ||
              (clientData &&
                !clientData.precipitation_client_project_flag)) && (
              <section className='container precipitation-error-msg-wrapper'>
                <p>
                  “Precipitation Tracking has been turned off, to access please
                  contact your company's administrator.”
                </p>
              </section>
            )}
          </>
        )}
      </div>
    </>
  );
}

function ProjectForm({ initialData, ...props }) {
  const appContext = useContext(AppContext);
  const { appDivisionId } = useContext(AppDivisionContext);
  const projectContext = useContext(ProjectContext);

  // form data
  const clientsQuery = useDivisionClients(appDivisionId);
  const statesQuery =
    env.REACT_APP_REGION === 'UK' ? useCountries() : useStates();
  const [docTypes, setDocTypes] = useState([]);
  const [docGroups, setDocGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [primaryContactUsers, setPrimaryContactUsers] = useState([]);

  const [tz] = useState(appContext.get('constants.tz'));
  const [inspectionTemplates, setTemplates] = useState([]);

  // form states
  const [loaded, setLoadedState] = useState(false);
  const [clientId, setClientId] = useState();
  const [checkedDocs, setCheckedDocs] = useState();
  const [clientCheckedDocTypes, setclientCheckedDocTypes] = useState();
  const [defaultChecked, setDefaultChecked] = useState({});
  const [projectTypes, setProjectTypes] = useState();

  function getSelectedValue(value) {
    setClientId(value);
  }

  const projectId = projectContext.project && projectContext.project.id;

  useEffect(() => {
    async function fetchClientDocTypes() {
      if (projectId && clientId) {
        const projectDocs = await projectApi.getDocs(projectId); //fetching current doctypes addes to Project_doc_types
        const clientDocTypes = await clientApi.getClientsDocTypes.forProjects(
          clientId,
        ); // fetching all checked form Client_doc_types
        const allClientDocTypeIds = clientDocTypes.map((ele) => ele.id);
        const clientFilteredDocs = projectDocs.documentTypes.filter((ele) =>
          allClientDocTypeIds.includes(ele.id),
        ); //Project_doc_types - Client_doc_types

        setclientCheckedDocTypes(clientDocTypes);
        setCheckedDocs(clientFilteredDocs);
      } else if (clientId) {
        const clientDocTypes = await clientApi.getClientsDocTypes.forProjects(
          clientId,
        ); // fetching all checked form Client_doc_types

        setclientCheckedDocTypes(clientDocTypes);
        setCheckedDocs(clientDocTypes);
      }
    }

    fetchClientDocTypes();
  }, [clientId, projectContext.projectDocs]);

  useEffect(() => {
    checkedDocs &&
      checkedDocs.forEach((doc) => {
        let temp = defaultChecked;

        temp[doc.id] = true;
        setDefaultChecked(temp);
      });
  }, [checkedDocs]);

  useEffect(() => {
    Promise.all([
      clientId
        ? clientApi.getClientsDocTypes.forProjects(clientId)
        : uploadApi.docTypes(),
      divisionApi.regulations.index(appDivisionId),
      divisionApi.inspectionTemplates.index(appDivisionId),
      rolesApi.inspectorRole(appDivisionId),
      rolesApi.primaryContactRole(projectId),
      projectApi.getProjectTypes(),
      projectApi.get(projectId),
    ])
      .then(
        ([
          docTypesRes,
          docGroupsRes,
          inspTemplRes,
          usersRes,
          primaryContactUsersRes,
          projTypes,
          proj,
        ]) => {
          setDocTypes(docTypesRes.filter((ele) => ele.name !== "Active Site Maps"));
          setDocGroups(docGroupsRes);
          setTemplates(inspTemplRes);
          setUsers(usersRes.data.map((user) => user.user));
          setPrimaryContactUsers(
            primaryContactUsersRes.data.map((user) => user.user),
          );
          setProjectTypes(projTypes);
          projectContext.setProject(proj);
          if (
            initialData &&
            initialData.template &&
            inspectionTemplates.filter(
              ({ id }) => id === initialData.template.id,
            ).length === 0
          ) {
            setTemplates([initialData.template, ...inspectionTemplates]);
          }

          setLoadedState(true);
        },
      )
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log('Error: mounting', e);
      });
  }, []);

  if (!loaded || clientsQuery.isLoading || statesQuery.isLoading) {
    return <Loading />;
  }

  const timezone = momentTz.tz.guess(true);

  return (
    <FormContextProvider
      initialValue={{
        ...initialData,
        clientId: clientId ? clientId : initialData.clientId,
        docTypes: defaultChecked,
        timezone:
          env.REACT_APP_REGION === 'UK'
            ? 'GMT'
            : initialData.timezone && tz.includes(initialData.timezone)
            ? initialData.timezone
            : tz.includes(timezone)
            ? timezone
            : tz[0],
      }}
    >
      <TooltipContextProvider>
        <ProjectFormUI
          {...props}
          schemaData={{
            docTypes,
            states: statesQuery.data,
            clients: clientsQuery.data,
            docGroups,
            inspectionTemplates,
            users,
            primaryContactUsers,
            projectTypes,
            tz,
          }}
          getSelectedValue={getSelectedValue}
          clientCheckedDocTypes={clientCheckedDocTypes}
          appDivisionId={appDivisionId}
          projectId={projectId}
        />
      </TooltipContextProvider>
    </FormContextProvider>
  );
}

const ProjectSettings = () => {
  const [client, setClient] = useState();
  const projectContext = useContext(ProjectContext);
  const { getTeamManagementData, fetchStatsFuncForID } =
    useContext(DashboardContext);
  const appContext = useContext(AppContext);

  const { project, inspections } = projectContext;

  useEffect(() => {
    appContext.loadData(
      () =>
        clientApi.index([project.client_id]).then((c) => {
          projectContext.setDocumentDate(c[0].document_date);
          setClient(c);
        }),
      'project details',
    );
  }, []);

  const updateProject = (proj) => {
    projectContext.setProject({
      ...project,
      ...proj,
    });
    projectContext.loadDocs(proj.id);
    projectContext.inspectionsQuery.refetch();
  };

  async function handleEdit(formData) {
    const toastId = toast('Updating project...');

    projectApi
      .update(project.id, formData)
      .then((data) => {
        updateProject(data);
        getTeamManagementData.refetch();
        fetchStatsFuncForID.refetch();
        toast.update(toastId, {
          render: 'Project updated successfully',
          type: 'success',
        });
      })
      .catch((e) => {
        toast.update(toastId, {
          render: e.message,
          type: 'error',
        });
      });
  }

  return (
    <>
      <ProjectForm
        handleSubmit={handleEdit}
        initialData={extractInitialData({
          ...project,
          inspections,
          client,
        })}
        hideStartDate={inspections.length > 0}
      />
    </>
  );
};

export default ProjectSettings;
