import React, { useContext, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContextProvider } from '@sw-sw/lib-form';
import { faEllipsisV, faExternalLinkAlt, faSort, faCalendarAlt, faList, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
import CalendarView from "./CalendarView"
import classNames from "classnames";
import DashboardContext from "../../../../contexts/DashboardContext";
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover'
import ProjectList from "./ProjectList";
import userApi from '../../../../utils/api/user';
import AppContext from '../../../../contexts/AppContext';
import ExternalProjectsInspectionSubmitModal from "../../../Inspections/Listing/ExternalProjectsInspectionSubmitModal";
import xhrService from "../../../../utils/xhrService";
import inspectionApi from "../../../../utils/api/inspection";
import mapApi from "../../../../utils/api/map";
import DirectionModal from "./DirectionModal";

function getCtaRoute(inspectionStatus, dueDate, projectId) {
  if (inspectionStatus === "scheduled" && moment().format("MM-DD-YYYY") === dueDate) {
    return `/projects/${projectId}/start-inspection`;
  }

  return `/projects/${projectId}/inspections`
}


function getCtaText(dueDate, inspectionStatus) {
  const formattedTodayDate = new Date()
  const formattedDueDate =  new Date(dueDate.replace(/-/g,'/'))

  if (formattedTodayDate.setHours(0,0,0,0) === formattedDueDate.getTime() && inspectionStatus === "scheduled") {
    return "Start Inspection";
  }

  if (formattedTodayDate.setHours(0,0,0,0) === formattedDueDate.getTime() && inspectionStatus === "new") {
    return "Finish";
  }
  
  if (formattedTodayDate > formattedDueDate) {
    return "Past Due";
  }

  return "View Project";
}

/* "My Projects" widget was formerly titled as "Inspections Due".
* In case, looking for naming convention to make sense,
* think of "Inspections Due" as the main widget with "My Project/Project List" as a part of it.
*/
const InspectionsDue = ({ providedProps, changeShowMore, mdSize }) => {
  const [search, setSearch] = useState('')
  const [clientIsAscending, setClientIsAscending] = useState(true)
  const [siteIsAscending, setSiteIsAscending] = useState(true)
  const { displayStatsForID, isLoadingForID, allProjectsForID, setDisplayStatsForID, allProjectsForMP, setDisplayStatsForMP, fetchStatsFuncForID, getTeamManagementData } = useContext(DashboardContext)
  const widgetRef = useRef(0);
  const [widgetWidth, setWidgetWidth] = useState(widgetRef.current.clientWidth)
  const appContext = useContext(AppContext);
  const [showExternalProjectInspectionModal, setShowExternalProjectInspectionModal] = useState(false);
  const [modalInspection, setModalInspection] = useState({});
  const [inspectionTypes, setInspectionTypes] = useState({});
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [openDirectionModal, setOpenDirectionModal] = useState(false)

  const user = appContext.get('user');
  
  let viewName = 'Inspections Due'

  let viewIcon = faCalendarAlt;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (localStorage.getItem('myProjectsWidgetPreference') === 'InspectionsDue') {
    viewIcon = faCalendarAlt
    viewName = 'Insp. Calendar'
  }

  if (localStorage.getItem('myProjectsWidgetPreference') === 'Insp. Calendar') {
    viewIcon = faStickyNote
    viewName = 'Inspections Due'
  }

  useEffect(() => {
    function handleResize() {
      setWidgetWidth(widgetRef.current.clientWidth)
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeView = () => {
    setSelectedProjects([])
    if(localStorage.getItem('myProjectsWidgetPreference') === 'InspectionsDue'){
      localStorage.setItem('myProjectsWidgetPreference', 'Insp. Calendar')
    }
    else{
      localStorage.setItem('myProjectsWidgetPreference', 'InspectionsDue')
    }
    setAnchorEl(null);
  }

  const handleProjectListView = () => {
    setSelectedProjects([])
    localStorage.setItem('myProjectsWidgetPreference', 'ProjectList')
    setAnchorEl(null);
  }

  const handleButtonColors = (dueDate, inspectionStatus) => {
    if(getCtaText(dueDate, inspectionStatus) === "View Project")
      return "list-view-show-project-color"
     
    if(getCtaText(dueDate, inspectionStatus) === "Past Due")
      return "list-view-past-due-color"

    if(getCtaText(dueDate, inspectionStatus) === "Finish")
      return "list-view-finish-project-color"

    return "list-view-start-inspection-color"
  }

  const handleEPVInspShowModal = (ele, startEvpInspection=false) => {

    if(startEvpInspection){
      inspectionApi.create(ele.projectId, 'Routine').then((i) => {

        const modalData = {
          inspectionId: i.id,
          siteName: i.project.name,
          ...i
        }

        getTeamManagementData.refetch();
        fetchStatsFuncForID.refetch();
        setModalInspection(modalData);
        setShowExternalProjectInspectionModal(true);
      });
    }else{
      setModalInspection(ele);
      setShowExternalProjectInspectionModal(true);
    }

  }

  const handleEPVInspSubmit = async (formData) => {
    appContext.loadData(async () =>
      await xhrService.put(`/api/inspections/${modalInspection.inspectionId}/update-evp-inspection`, {
        ...formData
      }).then(()=>{
        setShowExternalProjectInspectionModal(false);
        setModalInspection({});
        fetchStatsFuncForID.refetch();
      })
    );
  }

  const getInspectionTypes = async () => {
    const dataTypes = await xhrService.get(`/api/inspection-types`);
    const mappedData = dataTypes.data.map((e)=>({type:e}));
    setInspectionTypes(mappedData);
  }

  //SEARCH
  useEffect(() => {
    setSelectedProjects([])
    if(localStorage.getItem('myProjectsWidgetPreference') === 'InspectionsDue' || localStorage.getItem('myProjectsWidgetPreference') === 'Insp. Calendar'){
      const updatedList = allProjectsForID && [...allProjectsForID].filter((el) => {
        return el.siteName.toLowerCase().includes(search.toLowerCase())
      })
  
      setDisplayStatsForID(updatedList)
    }
    else{
      const updatedList = [...allProjectsForMP].filter((el) => {
        return el.project.name.toLowerCase().includes(search.toLowerCase()) || el.project.client.name.toLowerCase().includes(search.toLowerCase())
      })
  
      setDisplayStatsForMP(updatedList)
    }
  }, [search])

  useEffect(() => {
    getInspectionTypes();
    if(localStorage.getItem('myProjectsWidgetPreference') === null){
      localStorage.setItem('myProjectsWidgetPreference', 'InspectionsDue')
    }
  },[]);

  //SORT
  const sortBySiteName = () => {
    const updatedList = displayStatsForID.sort((a, b) => {
      let A = a.siteName.toString().toLowerCase();

      let B = b.siteName.toString().toLowerCase();

      if (siteIsAscending === true) {
        setSiteIsAscending(!siteIsAscending);

        return A > B ? -1 : 1; // descending
      } else {
        setSiteIsAscending(!siteIsAscending);

        return A > B ? 1 : -1; //ascending
      }
    });

    setDisplayStatsForID(updatedList);
  };

  const sortByClientName = () => {
    const updatedList = displayStatsForID.sort((a, b) => {
      let A = a.clientName.toString().toLowerCase();

      let B = b.clientName.toString().toLowerCase();

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending);

        return A > B ? -1 : 1; // descending
      } else {
        setClientIsAscending(!clientIsAscending);

        return A > B ? 1 : -1; //ascending
      }
    });

    setDisplayStatsForID(updatedList);
  };

  const sortByDueDate = () => {
    const updatedList = displayStatsForID.sort((a, b) => {
      let A = new Date(a.dueDate);

      let B = new Date(b.dueDate);

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending);

        return A > B ? -1 : 1; // descending
      } else {
        setClientIsAscending(!clientIsAscending);

        return A > B ? 1 : -1; //ascending
      }
    });

    setDisplayStatsForID(updatedList);
  };

  let oneColumnHeader = {
    // gridTemplateAreas: '"title title title . bars bars bars bars bars bars bars ellipsis"'
  };


  const handleRedirect = (ele) => {
    userApi.redirectTenant(ele.division.tenant_id).then((res) => {
      if (res) {
        const startUrl = process.env.REACT_APP_CLIENT_BASE_URL;

        if (startUrl) {
          const url = startUrl.split('//');

          if (url) {
            window.location.href =
              url[0] +
              `//${res.tenantName}.${url[1]}/identity-auth?token=${
                res.token
              }&to=/divisions/${ele.division.id}${getCtaRoute(
                ele.inspectionStatus,
                ele.dueDate,
                ele.projectId,
              )}`;
          }
        }
      }
    });

  };

  const handleCheckboxChange = (project) => {
    setSelectedProjects(prevSelectedProjects =>
      prevSelectedProjects.find(selectedProject=>selectedProject.id === project.id)
        ? prevSelectedProjects.filter(selectedProject => selectedProject.id !== project.id)
        : [...prevSelectedProjects, project]
    );

  };

  const GetDirection = async (direction) => {
    const addresses = selectedProjects.map((project) => {
      return project.address ? project.address : project;
    });

    if (!addresses.length) {
      return;
    }

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

        addresses.unshift({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        let waypoints = addresses
          .map((add) => `${add.latitude},${add.longitude}`)
          .join('|');

        const startLat = position.coords.latitude;
        const startLong = position.coords.longitude;
        const params = {
          origin: `${startLat},${startLong}`,
          waypoints: waypoints,
        };

        mapApi.directions(params).then((response) => {
          if (response) {
            const optimizedWaypoints = response.data;

            setOpenDirectionModal(false);

            if(optimizedWaypoints.length === 1){
              alert('No route found');
              
              return;
            }

            let optimizedLocations = optimizedWaypoints
              .map((index) => addresses[index])
              .slice(1);

            if (direction === 2) {
              optimizedLocations = optimizedLocations.reverse();
            }

            const optimizedWaypointString = optimizedLocations
              .slice(0, optimizedLocations.length - 1)
              .map((loc) => `${loc.latitude},${loc.longitude}`)
              .join('|');

            const destAddress1 =
              optimizedLocations[optimizedLocations.length - 1];
            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLong}&destination=${destAddress1.latitude},${destAddress1.longitude}&waypoints=${optimizedWaypointString}`;

            const popup = window.open(googleMapsUrl, '_blank');
            
            if (!popup) {
              alert("Pop-up blocked! Redirecting to Google Maps in the same tab.");
              window.location.href = googleMapsUrl; // Fallback to same tab if pop-up is blocked
            }
          }
        });
      }
       catch (err) {
        if (err.code === err.PERMISSION_DENIED) {
          alert(
            'You have denied location access. Please enable location services to proceed.',
          );
          alert(
            'To enable location, go to your browser settings or device settings.',
          );
        } else if (err.code === err.POSITION_UNAVAILABLE) {
          alert(
            "Location information is unavailable. Please check your device's connection.",
          );
        } else if (err.code === err.TIMEOUT) {
          alert('Location request timed out. Please try again.');
        } else {
          alert('An unknown error occurred while requesting location.');
        }
      }

  };
  

  return (
    <>
      <div className="inspections-due" ref={widgetRef}>
        <header className="inspections-due__header" style={widgetWidth > 700 ? oneColumnHeader : null}>
          <h2 className="inspections-due__title">My Projects</h2>
          <div className="inspections-due__bars">
            {/* <section style={{ display: isCalendar ? "none" : "flex" }}>{appContext.state.user.name}</section> */}
            <input
            //  style={{ flexBasis: isCalendar ? "100%" : "60%" }}
             onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
          </div>
          <div className="inspections-due__direction">
          {selectedProjects.length > 0 && (
                <button className="primary" onClick={()=>setOpenDirectionModal(true)} >Directions</button>
              )}
           </div>
          <div className="inspections-due__ellipsis-button">
            <span
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div>
          <EllipsisPopover
            handleCloseFunc={handleClose}
            id={id} open={open}
            anchorEl={anchorEl}
            thirdOption={{ sx: { p: 1 }, icon: faList, optionName: 'Project List', handleOnClickFunc: handleProjectListView }}
            forthOption={{ sx: { p: 1 }, icon: viewIcon, optionName: viewName, handleOnClickFunc: handleChangeView }}
          />
        </header>

        <div
          data-rbd-drag-handle-context-id={providedProps}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: "auto"
          }}
        >
          <div className="inspections-due__body">
            <div className="inspections-due__content">

            { localStorage.getItem('myProjectsWidgetPreference') === 'InspectionsDue' ?
              // ------------------------ Inspection Due List View ------------------------------------------------------------------- 
              (<section className="inspection-due__list-view">
                <div className="inspections-due__table-head">
                  <h3 onClick={sortBySiteName}>Site Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                  <h3 onClick={sortByClientName}>Client Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                  <h3 onClick={sortByDueDate}>Due Date <span><FontAwesomeIcon icon={faSort} /></span> </h3>
                  <h3>Action</h3>
                </div>

                {!isLoadingForID ?
                  (displayStatsForID && displayStatsForID.map((ele, index) => {
                    const { siteName, clientName, dueDate, projectId, inspectionStatus } = ele;

                    const formattedTodayDate = new Date();
                    const formattedDueDate =  new Date(dueDate.replace(/-/g,'/'));
                    let startEvpInspection = false;

                    if (formattedTodayDate.setHours(0,0,0,0) === formattedDueDate.getTime() && inspectionStatus === "scheduled") {
                      startEvpInspection = true;
                    }

                    return (
                      <div key={index} className="inspections-due__table-body">
                         <input
                          type="checkbox"
                          defaultChecked={selectedProjects.find(selectedProject=>selectedProject.id === ele.projectId && selectedProject.inspectionId===ele.inspectionId)}
                          onChange={() => handleCheckboxChange({id:ele.projectId,latitude:ele.latitude,longitude:ele.longitude,inspectionId:ele.inspectionId})}
                          disabled={
                            selectedProjects.find(selectedProject=>selectedProject.id === ele.projectId && selectedProject.inspectionId!==ele.inspectionId) ||
                            (selectedProjects.length ===10 && !selectedProjects.find(selectedProject=>selectedProject.id === ele.projectId))
                          }
                        />
                        <li>{siteName}</li>
                        <li>{clientName}</li>
                        <li>{dueDate}</li>

                          {ele.division &&
                          ele.division.tenant_id === user.tenantId ? (
                        <li>

                          {
                            (ele.external_project && (inspectionStatus == "new" || startEvpInspection))? 
                            <span className={`list-view-submit-btn`} onClick={()=>handleEPVInspShowModal(ele, startEvpInspection)}>Submit</span>
                            :
                            <Link to={getCtaRoute(inspectionStatus, dueDate, projectId)}>
                              <span className={classNames(handleButtonColors(dueDate, inspectionStatus))}>{getCtaText(dueDate, inspectionStatus)}</span>
                            </Link>
                          }
                          
                        </li>
                          ) : (
                            <li>
                              <span
                                className={classNames(
                                  handleButtonColors(dueDate, inspectionStatus),
                                )}
                                onClick={() => handleRedirect(ele)}
                              >
                                {getCtaText(dueDate, inspectionStatus)}
                              </span>
                            </li>
                          )}
                        </div>
                      );
                    })
                  ) : (
                  <Loading what='data' />
                )}
              </section>)
              :
              localStorage.getItem('myProjectsWidgetPreference') === 'ProjectList' ?
              // ------------------------ Project List View -------------------------------------------------------------------
              (<section className="inspection-due__calendar-view">
                <ProjectList setSelectedProjects={setSelectedProjects} selectedProjects={selectedProjects}/>
              </section>)
              :
              // ------------------------ Inspection Due Calendar View -------------------------------------------------------------------
              (<section className="inspection-due__calendar-view">
                <CalendarView dueProjects={displayStatsForID} getCtaRoute={getCtaRoute} getCtaText={getCtaText} />
              </section>)
            }

            </div>

            {
                showExternalProjectInspectionModal ?
                    <ExternalProjectsInspectionSubmitModal
                        handleClose={() => setShowExternalProjectInspectionModal(false)}
                        handleSubmit={handleEPVInspSubmit}
                        modalInspection={modalInspection}
                        title="Submit EVP Inspection"
                        inspectionTypes={inspectionTypes}
                        project_name={modalInspection.siteName}
                    />
                    :
                    <></>
            }

            <footer className="inspections-due__footer">
              <span></span>
              <span onClick={changeShowMore}>Show {(mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} /></span>
              {selectedProjects.length? <span>  Selected {selectedProjects.length} projects {selectedProjects.length===10 && '(max)'} </span>: <span></span> }
            </footer>
          </div>
        </div>
      </div>
      {openDirectionModal &&   
        <FormContextProvider>
        <DirectionModal Submit={(direction)=>GetDirection(direction)} onClose={()=>setOpenDirectionModal(false)} />
       </FormContextProvider>
        }
    </>
  )
}


export default InspectionsDue
