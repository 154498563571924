/*eslint no-unused-vars:0 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { fabric } from "fabric";
import { isEmpty } from "lodash";

import PolygonTool from "./Tools/Polygon";
import SelectTool from "./Tools/Select";
import QuadTool from "./Tools/Quad";
import LineTool from "./Tools/Line";
import CircleTool from "./Tools/Circle";
import EllipseTool from "./Tools/Ellipse";
import TextTool from "./Tools/Text";

const Context = React.createContext();

/**
 * Implement a generic shape/icon editor on a given {fabric.Canvas}
 *
 * Implements "tools" to handle interactions on <canvas>
 * Manages tool selection & de-selection
 */
class IconEditorContext extends Component {
  static propTypes = {
    /** @type fabric.Canvas */
    canvas: PropTypes.object,
    onSelectionUpdated: PropTypes.func,
  };

  state = {
    selectedTool: null,
    tools: {}, // { [toolName: string]: new (...args: any[]) => IconEditorTool }
  };

  get contextValue() {
    return {
      allTools: this.state.tools,
      currentTool: this.state.selectedTool,
      selectTool: this.selectTool,
      deleteObject: this.deleteCanvasObject,
      selectedObject: this.getSelectedObject(),
    };
  }

  get tools() {
    return this.state.tools;
  }

  componentDidMount() {
    if (this.props.canvas) {
      this.init(this.props.canvas);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.canvas && this.props.canvas) {
      this.init(this.props.canvas);
    }
  }

  /**
   * Initialize icon editor on a featureable editor canvas
   *
   * Initialize tools
   * Bind interaction handlers
   *.
   * @param {fabric.Canvas} cv canvas from <FeatureableEditorContext />
   */
  init(cv) {
    const tools = this.initializeTools(cv);

    this.setState(
      {
        tools,
        selectedTool: Object.keys(tools)[0],
      },
      () => {
        this.bindCanvasInteractions(cv);
      },
    );
  }

  /**
   * @param {fabric.Canvas}
   */
  initializeTools(canvas) {
    return {
      select: new SelectTool(canvas),
      line: new LineTool(canvas),
      rect: new QuadTool(canvas, "Rectangle"),
      square: new QuadTool(canvas, "Square", true),
      polygon: new PolygonTool(canvas),
      circle: new CircleTool(canvas, "Circle", true),
      oval: new EllipseTool(canvas, "Oval"),
      text: new TextTool(canvas, "Text"),
    };
  }

  /**
   * @param {fabric.Canvas}
   */
  bindCanvasInteractions(canvas) {
    canvas.on("mouse:down", e => {
      this.tools[this.state.selectedTool].handleMouseDown(e);
    });

    canvas.on("mouse:up", e => {
      this.tools[this.state.selectedTool].handleMouseUp(e);
    });

    canvas.on("mouse:move", e => {
      this.tools[this.state.selectedTool].handleMouseMove(e);
    });

    /**
     * Custom event when a key of the featureable editor form has been changed
     *
     * Invokes a function on the active tool, depending on active object
     *
     * Triggered by FeatureableEditorContext
     */
    canvas.on("featureable-form:updated", ({ field, value }) => {
      const activeObject = this.props.canvas.getActiveObject();

      if (activeObject) {
        this.tools[this.state.selectedTool].handleUpdateActiveObject(
          field,
          value,
        );
      } else {
        this.tools[this.state.selectedTool].updateTheme(field, value);
      }
    });

    /**
     * Custom event when tool has the "final" shape
     *
     * Triggered by {IconEditorTool}
     */
    canvas.on("tool:complete", ({ shape, applyTheme, autoSelect }) => {
      if (shape) {
        canvas.add(shape);

        if (autoSelect) {
          return this.selectTool("select", () => {
            canvas.setActiveObject(shape);
            canvas.renderAll();
          });
        }

        canvas.renderAll();
      }
    });

    /** update active object data for context consumers */
    canvas.on("selection:created", () => this.forceUpdate());
    canvas.on("selection:updated", () => {
      if (this.props.onSelectionUpdated) {
        this.props.onSelectionUpdated();
      }

      this.forceUpdate();
    });
    canvas.on("selection:cleared", () => this.forceUpdate());
    // canvas.on('object:modified', () => this.forceUpdate());

    return canvas;
  }

  getSelectedObject() {
    return this.props.canvas ? this.props.canvas.getActiveObject() : undefined;
  }

  /**
   * 'put down' the current tool and 'pick up' the specified tool
   *
   * @param {string} name
   */
  selectTool = (name, cb) => {
    const tools = this.state.tools;

    if (isEmpty(tools)) {
      return;
    }

    let n = name;

    if (!name || !Object.keys(tools).includes(name)) {
      n = "select";
    }

    if (this.state.selectedTool !== n) {
      const { selectedTool, tools } = this.state;

      tools[selectedTool].handleDeselected();

      this.setState(
        {
          selectedTool: n,
        },
        () => {
          if (cb) {
            cb();
          }
        },
      );

      tools[n].handleSelected();
    }
  };

  /**
   *
   * @param {fabric.Object} object
   * @param {string} prop keyof fabric.object
   * @param {any} value
   */
  deleteCanvasObject = (object, prop, value) => {
    this.props.canvas.remove(object);
    this.forceUpdate();
  };

  render(props) {
    return (
      <Context.Provider value={this.contextValue}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export { Context };
export default IconEditorContext;
