import React, { useContext, useEffect } from 'react';

import { mapUtil } from '@sw-sw/common';
import { Context as InteractionContext } from '../InteractionContext';
import { getUtil } from '../../../../utils/positionable';
import { Context as PositionableInstanceDataContext } from '../../Positionable/InstanceDataContext';
import { Context as DataSourceContext } from '../../DataSourceContext';
import { MapEditorRouteContext } from "../../../CustomRoutes/MapEditor";

const { isModifyInteraction, getModify } = mapUtil;

/**
 * Modify features
 *
 * @todo respond to changes in {features} array
 */
function ModifyFeatures({ features }) {
  const interactionContext = useContext(InteractionContext);
  const dataContext = useContext(PositionableInstanceDataContext);
  const dataSource = useContext(DataSourceContext);
  const mapEditorRouteContext = useContext(MapEditorRouteContext);

  useEffect(() => {
    const modify = getModify(features);

    modify.on('modifystart', (event) => {
      /**
       * Attaches function to disacrd changes
       * Only on existing (saved) models
       * New (unsaved) models are deleted on discard
       */
      features
        .filter((feature) => feature.get('positionables_id'))
        .forEach((feature) => {
          const originalFeature = feature.clone();

          feature.set('handleRevert', () => {
            feature.setGeometry(originalFeature.getGeometry());
          });
        });
    });

    modify.on('modifyend', (event) => {
      event.features.getArray().forEach((feature) => {
        const positionableType = feature.get('positionable_type');
        const sourceModel = dataSource.getById(
          feature.get('positionable_source_id'),
          positionableType,
        );
        const { targetType, targetId, startInspectionId, endInspectionId } =
          dataSource.getDataTypeArguments(positionableType);

        feature.set(
          'positionable_config',
          getUtil(positionableType).getInstanceConfig(sourceModel, feature),
          true,
        );

        dataContext.setPendingUpdate(
          feature,
          positionableType,
          targetType,
          targetId,
          startInspectionId,
          endInspectionId,
        );
      });
    });

    interactionContext.removeInteractions(isModifyInteraction);
    interactionContext.addInteraction(modify);

    return () => {
      interactionContext.removeInteractions(isModifyInteraction);
    };
  }, [features]);

  return <div className='map-interaction-modify' />;
}

export default ModifyFeatures;
